import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../Help/helper';

import MoonLoader from 'react-spinners/MoonLoader';


const Quotations = () => {
  const [quotations, setQuotations] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const q = query(collection(db, 'quotations'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const quotationsData = [];
      querySnapshot.forEach((doc) => {
        quotationsData.push({ ...doc.data(), id: doc.id });
      });
      setQuotations(quotationsData);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Job Card No',
        accessor: 'jobCardNo',
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
      },
      {
        Header: 'Date Raised',
        accessor: 'dateCreated',
        Cell: ({ value }) => new Date(value?.seconds * 1000).toLocaleDateString(),
      },
      {
        Header: 'Total Sale Amount',
        accessor: 'totalSale',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedBy',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <button
            type="button"
            onClick={() => navigate(`/finance/quotations/${row.original.id}`)}
            className="rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-400"
          >
            View
          </button>
        ),
      },
    ],
    [navigate]
  );

  const data = useMemo(() => {
    return quotations.filter((quotation) =>
      Object.values(quotation).some((item) =>
        String(item).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [quotations, searchInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    prepareRow,
  } = useTable({ columns, data }, useFilters, useSortBy, usePagination);

  return (
    <>
    

      <div className="bg-white shadow rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="p-2 w-1/4 text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search..."
          />
        </div>

        <div className="mt-2 mb-2">
          <button
            type="button"
            onClick={() => navigate(`/finance/quotationpage`)}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            New Quotation
          </button>
        </div>

        <table {...getTableProps()} className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="text-sm font-medium text-gray-700">
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id} // Add key here
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-6 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {isLoading ? (
            <div className="fixed inset-0 flex items-center justify-center">
              <MoonLoader size={100} />
            </div>
          ) : (
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id} className="text-sm text-gray-500">
                    {row.cells.map((cell) => (
                      <td key={cell.column.id} {...cell.getCellProps()} className="px-4 py-1 whitespace-nowrap">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>

        <div className="py-2 flex items-center justify-between">
          <div>
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'<<'}
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'<'}
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'>'}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'>>'}
            </button>
          </div>
          <span>
            Page{' '}
            <strong>
              {pageOptions.indexOf(page) + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
      </div>
    </>
  );
};

export default Quotations;
