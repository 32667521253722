import React from 'react';

const ArticleList = ({ articles, onEdit, onView, onDelete }) => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-2">Articles</h1>
      <ul className="mt-4 bg-white">
        {articles && articles.length > 0 ? (
          articles.map((article) => (
            <li key={article.id} className="border p-4 mb-4">
              <button
                onClick={() => onView(article)}
                className="text-lg font-bold text-blue-500"
              >
                {article.title}
              </button>
              <div className="mt-2">
                <button onClick={() => onEdit(article)} className="text-blue-500 mr-4">
                  Edit
                </button>
                <button onClick={() => onDelete(article.id)} className="text-red-500">
                  Delete
                </button>
              </div>
            </li>
          ))
        ) : (
          <p>No articles available.</p>
        )}
      </ul>
    </div>
  );
};

export default ArticleList;
