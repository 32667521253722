import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';


const EditCustomerModal = ({ showModal, closeModal, handleSubmit, formState, handleInputChange, agents }) => {

  if (!formState) {
    return null; 
  }
  
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          {/* <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" /> */}

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Edit Customer
            </Dialog.Title>

            <div className="mt-2">
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input 
                      id="firstName"
                      name="firstName" 
                      required 
                      onChange={handleInputChange} 
                      value={formState.firstName || ''} 
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />

                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input 
                      id="lastName"
                      name="lastName" 
                      required 
                      onChange={handleInputChange} 
                      value={formState.lastName || ''} 
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />

                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input 
                      id="email"
                      name="email" 
                      required 
                      onChange={handleInputChange} 
                      value={formState.email || ''} 
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />

                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                      Address
                    </label>
                    <input 
                      id="address"
                      name="address" 
                      required 
                      onChange={handleInputChange} 
                      value={formState.address || ''} 
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />

                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                      Phone Number
                    </label>
                    <input 
                      id="phoneNumber"
                      name="phoneNumber" 
                      required 
                      onChange={handleInputChange} 
                      value={formState.phoneNumber || ''} 
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />

                    <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">
                      Date of Birth
                    </label>
                    <input 
                      id="dateOfBirth"
                      type="date" 
                      name="dateOfBirth" 
                      value={formState.dateOfBirth ? formState.dateOfBirth.split('T')[0] : ''} 
                      onChange={handleInputChange}
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    />
                    
                    <label htmlFor="customerType" className="block text-sm font-medium text-gray-700 mb-1">
                      Customer Type
                    </label>
                    <select
                      id="customerType"
                      name="customerType"
                      value={formState.customerType || ''}
                      onChange={handleInputChange}
                      className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                    >
                      <option value="Private">Private</option>
                      <option value="Corporate">Corporate</option>
                    </select>
              
                <button
                  type="submit"
                  className="mt-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-6"
                >
                  Save Changes
                </button>

                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditCustomerModal;
