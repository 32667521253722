import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, where, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import TaskDetailsModal from './Modals/TaskDetailsModal';
import moment from 'moment';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      const q = query(
        collection(db, "tasks"),
        where("status", "in", ["new", "in-progress"])
      );
      const querySnapshot = await getDocs(q);
      const fetchedTasks = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTasks(fetchedTasks);
    };
    fetchTasks();
  }, []);

  const deleteTask = async (taskId) => {
    try {
      await deleteDoc(doc(db, "tasks", taskId));
      setTasks(tasks.filter(task => task.id !== taskId));
      toast.success("Task deleted successfully");
    } catch (error) {
      console.error("Error deleting task: ", error);
      toast.error("Error deleting task");
    }
  };

  const handleDetailsClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-base font-semibold leading-7 text-gray-900">List of Open Tasks</h2>
        <ul className="space-y-3 mt-3">
          {tasks.map(task => (
            <li key={task.id} className="border border-gray-200 p-3 rounded-lg">
              <h3 className="text-md font-semibold">{task.taskTitle} - {task?.jobCardNo}</h3>
              <p>Start Date: {moment(task.startDate.toDate()).format('LLL')}</p>
              <p>Deadline: {moment(task.deadline.toDate()).format('LLL')}</p>
              <p>Assigned To: {task.assignedToName}</p>
              <p>Assigned By: {task.assignedBy}</p>
              <p>Status: {task.status}</p>
              <div className="flex justify-items-center mt-2">
                <button 
                  onClick={() => deleteTask(task.id)} 
                  className="rounded bg-red-600 px-2 ml-4 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800">
                  Delete
                </button>

                <button 
                  onClick={() => handleDetailsClick(task)} 
                  className="rounded bg-blue-500 px-2 ml-4 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-800">
                  View Details
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ToastContainer />

      {isModalOpen && selectedTask && 
        <TaskDetailsModal 
          isOpen={isModalOpen} 
          task={selectedTask} 
          onClose={() => setIsModalOpen(false)} 
        />
      }
    </>
  );
};

export default TaskList;
