import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import BreadCrumb from '../BreadCrumb';
import moment from 'moment';

const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Analytics on Job Cards  Engineer Tasks', to: '/vehicles/mechanicperformance', component: Link, current: true },
];

const EmployeePerformanceMetrics = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'ascending' });

  useEffect(() => {
    const fetchTasks = async () => {
      const q = query(collection(db, "tasks"));
      const querySnapshot = await getDocs(q);
      const tasksWithAssignedByDetails = await Promise.all(querySnapshot.docs.map(async doc => {
        const data = doc.data();
        const completedAt = data.completedAt ? new Date(data.completedAt.seconds * 1000) : null;
        const createdAt = new Date(data.createdAt.seconds * 1000);
        const deadline = new Date(data.deadline.seconds * 1000);
        const startDate = new Date(data.startDate.seconds * 1000);
        const verificationAt = data.verifiedAt ? new Date(data.verifiedAt.seconds * 1000) : null;

        let assignedByName = data.assignedBy;

        if (assignedByName) {
          const employeeQuery = query(collection(db, "employees"), where("email", "==", assignedByName));
          const employeeSnapshot = await getDocs(employeeQuery);
          const employeeData = employeeSnapshot.docs[0]?.data();
          if (employeeData) {
            assignedByName = `${employeeData.firstName} ${employeeData.lastName}`;
          }
        }

        return {
          id: doc.id,
          ...data,
          completedAt,
          createdAt,
          startDate,
          deadline,
          verificationAt,
          assignedByName,
          turnaroundTime: completedAt ? (completedAt - createdAt) / 3600000 : null, // hours
          isOnTime: completedAt && deadline ? completedAt <= deadline : false
        };
      }));
      setTasks(tasksWithAssignedByDetails);
    };

    fetchTasks();
  }, []);

  const sortedTasks = () => {
    if (!sortConfig) return tasks;
    return [...tasks].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const currentTasks = sortedTasks().slice((currentPage - 1) * tasksPerPage, currentPage * tasksPerPage);
  const totalPages = Math.ceil(tasks.length / tasksPerPage);

  const handleOpenModal = (task) => {
    setSelectedTask(task);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTask(null);
  };

  const handleSortChange = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      <BreadCrumb pages={pages} />

      <div className="p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Mechanic Task Report</h2>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              {['Task Title', 'Job Card', 'Mechanic', 'Date Assigned', 'Status', 'Duration(hrs)', 'Details'].map((header, index) => (
                <th
                  key={index}
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSortChange(header.toLowerCase().replace(/\s/g, ''))}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentTasks.map((task) => (
              <tr key={task.id} className="hover:bg-gray-50">
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{task.taskTitle}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                  <Link to={`/vehicles/jobdetail/${task.jobCardNo}`} className="text-blue-500 hover:text-blue-800">{task.jobCardNo}</Link>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{task.assignedToName}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{moment(task.createdAt).format('DD-MMM-YYYY')}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{task.status}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{task.turnaroundTime ? task.turnaroundTime.toFixed(2) : 'N/A'}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                  <button onClick={() => handleOpenModal(task)} className="text-blue-500 hover:text-blue-800">
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showModal && selectedTask && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-2xl max-w-xl w-full mx-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-gray-800">Task Details</h3>
                <button onClick={handleCloseModal} className="text-gray-400 hover:text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="space-y-4">
                <p><strong className="font-semibold">Title:</strong> {selectedTask.taskTitle}</p>
                <p><strong className="font-semibold">Mechanic:</strong> {selectedTask.assignedToName}</p>
                <p><strong className="font-semibold">Assigned By:</strong> {selectedTask.assignedByName}</p>
                <p><strong className="font-semibold">Date Assigned:</strong> {moment(selectedTask.createdAt).format('LLLL')}</p>
                <p><strong className="font-semibold">Status:</strong> {selectedTask.status}</p>
                <p><strong className="font-semibold">Deadline:</strong> {moment(selectedTask.deadline).format('LLLL')}</p>
                {selectedTask.completedAt && <p><strong className="font-semibold">Completed At:</strong> {moment(selectedTask.completedAt).format('LLLL')}</p>}
                {selectedTask.verificationAt && <p><strong className="font-semibold">Verified At:</strong> {moment(selectedTask.verificationAt).format('LLLL')}</p>}
                <p><strong className="font-semibold">On Time:</strong> {selectedTask.isOnTime ? 'Yes' : 'No'}</p>
                <p><strong className="font-semibold">Verification Comments:</strong> {selectedTask.verificationComments || 'N/A'}</p>
                <table className="min-w-full bg-gray-100 border border-gray-200 rounded-lg">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Description</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedTask.subtasks.map((subtask, index) => (
                      <tr key={index} className="bg-white">
                        <td className="px-4 py-2 text-sm text-gray-700">{subtask.description}</td>

                        <td className="px-4 py-2 text-sm text-gray-700">{subtask.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button onClick={handleCloseModal} className="mt-6 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
                Close
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button key={i} onClick={() => setCurrentPage(i + 1)} className={`px-4 py-2 mx-1 ${i + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} border border-blue-500 rounded`}>
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployeePerformanceMetrics;


