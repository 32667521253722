import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

const TaskCompletionReport = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const querySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = querySnapshot.docs.map(doc => doc.data());
      setTasks(tasksData);
    };

    fetchTasks();
  }, []);

  const calculateTimeDifference = (start, end) => {
    if (!start || !end) return '';
    const duration = moment.duration(moment(end.toDate()).diff(moment(start.toDate())));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours} hour(s) and ${minutes} minute(s)`;
  };

  const checkOnTime = (deadline, completedAt) => {
    if (!deadline || !completedAt) return '';
    const deadlineTime = moment(deadline.toDate());
    const completedTime = moment(completedAt.toDate());
    const difference = completedTime.diff(deadlineTime, 'hours', true);
    return difference <= 0 ? `Early by ${Math.abs(difference).toFixed(2)} hours` : `Late by ${difference.toFixed(2)} hours`;
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-lg font-semibold text-gray-900">Task Completion Report</h2>
      <div className="bg-white p-4 rounded-lg shadow">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Task Title
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Start Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Deadline
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Completed At
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Time Taken to Complete
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                On Time Status
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={index}>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{task.taskTitle}</td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{moment(task.startDate.toDate()).format('LLL')}</td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{moment(task.deadline.toDate()).format('LLL')}</td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{task.completedAt ? moment(task.completedAt.toDate()).format('LLL') : 'N/A'}</td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{task.completedAt ? calculateTimeDifference(task.startDate, task.completedAt) : 'N/A'}</td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{task.completedAt ? checkOnTime(task.deadline, task.completedAt) : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskCompletionReport;
