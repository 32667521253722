import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

const initialFormData = {
  coolingSystem: '',
  heatingSystem: '',
  defogDefrostSystem: '',
  ventControlSystem: '',
  compressor: '',
  condenser: '',
  dischargeSuctionHoses: '',
  junctionValve: '',
  drierFilter: '',
  evaporator: '',
  blower: '',
  diagnosticCodes: '',
  // ... rest of your form fields
};

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <FormContext.Provider value={{ formData, setFormData, currentStep, setCurrentStep }}>
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
