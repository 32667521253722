import React from 'react';

const PrintFriendlyHeader = ({ data }) => {
    return (
      <div className="print-header">
        <h1>Vehicle Inspection Report</h1>
        <p><strong>Inspection Date:</strong> {data.inspectionDate.toDate().toLocaleDateString()}</p>
        <p><strong>Vehicle:</strong> {data.vehicleMake} {data.vehicleModel}</p>
        <p><strong>Registration Number:</strong> {data.vehicleRegistrationNumber}</p>
      </div>
    );
  };
  
  export default PrintFriendlyHeader;