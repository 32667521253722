// TotalsDisplay.js
import { formatCurrency } from "../../Help/helper";


export const TotalsDisplay = ({ totals, includeTax, setIncludeTax, finalizeQuotation }) => {
   

  return (
    <>


<div className="sticky top-4 p-4 bg-gray-50 rounded shadow">
                                  <div className="flex items-center justify-between mb-4">
                                    
                                    <button onClick={finalizeQuotation} className="bg-blue-600 hover:bg-green-700 text-sm text-white py-2 px-2 rounded transition duration-200">
                                      Finalize Quotation
                                    </button>
                                  </div>
                                  <div className="mb-4">
                                    <label className="flex items-center space-x-3 mb-2">
                                      <input
                                        type="checkbox"
                                        checked={includeTax}
                                        onChange={(e) => setIncludeTax(e.target.checked)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                      />
                                      <span className="text-sm text-gray-700">Include 7.5% Tax</span>
                                    </label>
                                    <div className="text-sm">
                                      <p>SUBTOTAL: {formatCurrency(totals.subtotal)}</p>
                                      <p>VAT (7.5%): {includeTax ? formatCurrency(totals.taxAmount) : "₦0.00"}</p>
                                      <p className="font-bold text-xl">TOTAL: {formatCurrency(totals.totalSale)}</p>
                                    </div>
                                  </div>
                                 </div>
    
           
    </>
  )

  };