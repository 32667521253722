import React, { useEffect, useReducer, useState } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

// Reducer function to manage trend data
const trendReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TREND':
            return {
                ...state,
                labels: action.payload.labels,
                datasets: [{ ...state.datasets[0], data: action.payload.data }]
            };
        default:
            return state;
    }
};

const ServiceDemandTrends = () => {
    const initialState = {
        labels: [],
        datasets: [{
            label: 'Number of Services Requested Over Time',
            data: [],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const [trendData, dispatch] = useReducer(trendReducer, initialState);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const fetchData = async () => {
            const snapshot = await getDocs(query(collection(db, "jobs")));
            const monthMap = {};

            snapshot.forEach(doc => {
                const data = doc.data();
                const date = data.createdAt.toDate();
                if (date.getFullYear() === selectedYear) {
                    const month = date.getMonth();
                    if (!monthMap[month]) monthMap[month] = 0;
                    ++monthMap[month];
                }
            });

            const months = Object.keys(monthMap).map(month => new Date(0, month).toLocaleString('default', { month: 'short' }));
            const services = Object.values(monthMap);

            dispatch({
                type: 'SET_TREND',
                payload: {
                    labels: months,
                    data: services
                }
            });
        };

        fetchData();
    }, [selectedYear]);

    return (
        <div>
            <h2>Service Demand Trends</h2>
            <select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))} className="mb-4 p-2 border rounded">
                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
            </select>
            <Line data={trendData} />
        </div>
    );
};

export default ServiceDemandTrends;
