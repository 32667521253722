  import React from "react";


   export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };
  

 export const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(date).toLocaleString(undefined, options);
  };

  // Function to generate a random alphanumeric string
  export const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
// Usage: const uniqueInvoiceNumber = generateRandomString();

  
// Define a default UI for filtering
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export const generateProcurementNumber = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36 for compactness
  const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string
  return `${timestamp}-${randomString}`.toUpperCase(); // Combine and format
};

export const convertTimeStampToDate = (timestamp, locale = 'en-US', options = {
  year: 'numeric', 
  month: 'short', 
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}) => {
  if (!timestamp || !timestamp.toDate) {
    return "N/A"; // Or any placeholder you prefer
  }

  const date = timestamp.toDate();
  return new Intl.DateTimeFormat(locale, options).format(date);
}




// Utility functions for serialization and deserialization
export const serialize = (nodes) => {
  return nodes.map((node) => Node.string(node)).join('\n');
};

export const deserialize = (html) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  return Array.from(document.body.childNodes).map((child) => ({
    type: 'paragraph',
    children: [{ text: child.textContent }],
  }));
};
