// PaymentModal.js
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { formatCurrency } from '../Help/helper';
import { collection, writeBatch, doc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';

const PaymentModal = ({ isOpen, onRequestClose, invoice, updateInvoiceState }) => {
  const [amountReceived, setAmountReceived] = useState('');
  const [modeOfPayment, setModeOfPayment] = useState('');
  const [dateReceived, setDateReceived] = useState('');

  const paymentModes = [
    'Bank Transfer - Stanbic Opex Account',
    'Bank Transfer - Stanbic Revenue Account',
    'Bank Transfer - Stanbic - CAPEX Account',
    'Cash',
    'POS',
  ];

// Get the approval date as a Date object and format it for the min attribute
const approvalDate = invoice.approvalDate.toDate();
const approvalDateString = approvalDate.toISOString().split('T')[0];


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!invoice || !invoice.id) {
      toast.error('Invoice Details Not Found');
      return null;
    }

    const selectedDate = new Date(dateReceived);
    // Normalize time components
    selectedDate.setHours(0, 0, 0, 0);
    approvalDate.setHours(0, 0, 0, 0);

    if (selectedDate < approvalDate) {
      toast.error('Date received cannot be before the invoice approval date.');
      return;
    }

    const invoicesRef = doc(db, 'newInvoices', invoice.id);
    const newTransactionRef = doc(collection(db, 'transactions'));

    const paymentTimestamp = dateReceived
      ? Timestamp.fromDate(selectedDate)
      : Timestamp.now();
    const batch = writeBatch(db);

    const newAmountReceived = Number(amountReceived);
    const updatedReceivable = invoice.receivable - newAmountReceived;
    let newInvoiceStatus = invoice.invoiceStatus;
    if (updatedReceivable === 0) {
      newInvoiceStatus = 'Fully Paid';
    } else if (updatedReceivable < invoice.receivable) {
      newInvoiceStatus = 'Part Payment';
    }

    // Create a new transaction document
    const transactionData = {
      type: 'Income',
      amount: Number(amountReceived),
      transactionDate: new Date().toISOString().split('T')[0],
      category: 'JobCard',
      invoiceNo: invoice.invoiceNumber,
      jobCardNo: invoice.jobCardNo,
      description: `Payment from ${invoice.customerName} for ${invoice.invoiceNumber}`,
      dateReceived: paymentTimestamp,
      modeOfPayment,
    };

    batch.set(newTransactionRef, transactionData);

    // Update the invoices document
    const paymentData = {
      amountReceived: newAmountReceived,
      dateOfPayment: paymentTimestamp,
      modeOfPayment,
    };

    batch.update(invoicesRef, {
      receivable: updatedReceivable,
      paymentsReceived: arrayUnion(paymentData),
      invoiceStatus: newInvoiceStatus,
    });

    // Commit the batch
    await batch.commit();

    const updatedInvoice = {
      ...invoice,
      receivable: updatedReceivable,
      invoiceStatus: newInvoiceStatus,
      paymentsReceived: [...invoice.paymentsReceived, paymentData],
    };

    updateInvoiceState(updatedInvoice);

    setAmountReceived('');
    setModeOfPayment('');
    setDateReceived('');
    onRequestClose();

    toast.success('Payment Successfully Posted');
  };

  return (
    <>
      <ToastContainer />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onRequestClose}
        >
          {/* Modal overlay */}
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Updated modal width to max-w-4xl */}
              <Dialog.Panel className="w-full max-w-4xl p-6 my-8 bg-white shadow-xl rounded-2xl">
                <div className="flex flex-col md:flex-row md:justify-between">
                  {/* Left column: Invoice details */}
                  <div className="md:w-2/3 md:pr-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-4"
                    >
                      Invoice Details
                    </Dialog.Title>
                    <Dialog.Description className="text-sm text-gray-500 mb-4">
                      <p>
                        Invoice Number:{' '}
                        <span className="font-semibold text-gray-700">
                          {invoice.invoiceNumber}
                        </span>
                      </p>
                      <p>
                        Approval Date:{' '}
                        {invoice.approvalDate.toDate().toLocaleDateString()}
                      </p>
                      <p>
                        Receivable:{' '}
                        <span className="text-red-600 text-xl">
                          {formatCurrency(invoice.receivable)}
                        </span>
                      </p>
                    </Dialog.Description>

                    {/* Invoice items table */}
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Description
                            </th>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Quantity
                            </th>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {invoice.cartItems.map((item, index) => (
                            <tr key={index}>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
                                {item.description}
                              </td>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                                {item.quantity}
                              </td>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                                {formatCurrency(item.price)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Previous Payments */}
                    {invoice.paymentsReceived && invoice.paymentsReceived.length > 0 && (
                      <div className="my-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                          Previous Payments Received
                        </h3>
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Amount Received
                                </th>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Date of Payment
                                </th>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Mode of Payment
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {invoice.paymentsReceived.map((payment, index) => (
                                <tr key={index}>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {formatCurrency(payment.amountReceived)}
                                  </td>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {payment.dateOfPayment
                                      .toDate()
                                      .toLocaleDateString()}
                                  </td>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {payment.modeOfPayment}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Right column: Payment form */}
                  <div className="md:w-1/3 md:pl-4 mt-6 md:mt-0">
                    <h2 className="mb-4 text-lg font-medium text-gray-900">
                      Post Payment From Customer
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <input
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        type="number"
                        placeholder="Amount Received"
                        value={amountReceived}
                        onChange={(e) => setAmountReceived(e.target.value)}
                        required
                      />
                      <select
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        value={modeOfPayment}
                        onChange={(e) => setModeOfPayment(e.target.value)}
                        required
                      >
                        <option value="">Select Mode of Payment</option>
                        {paymentModes.map((mode, index) => (
                          <option key={index} value={mode}>
                            {mode}
                          </option>
                        ))}
                      </select>

                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Date Payment was Received
                      </label>
                      <input
                        type="date"
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        value={dateReceived}
                        onChange={(e) => setDateReceived(e.target.value)}
                        min={approvalDateString} 
                        required
                      />

                      <button
                        type="submit"
                        className="w-full p-2 bg-blue-600 text-white mt-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Post Payment
                      </button>
                    </form>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="w-full p-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={onRequestClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PaymentModal;
