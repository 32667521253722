import React from 'react';
import { Link } from 'react-router-dom';
import AppointmentCalendar from '../Tasks/AppointmentsCalendar';
import BreadCrumb from '../BreadCrumb';


const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
    { name: 'Scheduled Appointments', to: '/vehicles/appointments', component: Link, current: true },  
  ];

const JobAppointments = () => {


  return (
    <>
    <BreadCrumb pages={pages} />

    < AppointmentCalendar />

    </>
  )
}

export default JobAppointments