import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {storage} from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ImageUpload from './helpers/ImageUpload';
import Spinner from './helpers/Spinner';

const InteriorAmenities = ({onBack, onNext, formData,onFormDataChange, vehicle }) => {
  const { handleSubmit, register,setValue } = useForm({defaultValues: formData,});
  const [isUploading, setIsUploading] = useState(false);
  const vehicleRegistrationNumber = vehicle.registrationNumber ? vehicle.registrationNumber : vehicle.VIN;
   const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    isSuccess: null,
    message: '',
  });

  
  useEffect(() => {
    Object.entries(formData).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [formData, setValue]);

 
  const handleImageCapture = async (imageSrc) => {
    
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    const file = new File([blob], `interioramenities_image_${uploadedImages.length}.png`, { type: 'image/png' });

    setIsUploading(true);
    setUploadStatus({
      isUploading: true,
      isSuccess: null,
      message: 'Uploading image...',
    });
    const imageRef = ref(storage, `inspection/${vehicleRegistrationNumber}/interioramenities_image_${uploadedImages.length}.png`);

    uploadBytes(imageRef, file).then((snapshot) => {
      return getDownloadURL(snapshot.ref);
    }).then((downloadURL) => {
      setUploadedImages(prevImages => [...prevImages, downloadURL]);
      setIsUploading(false);
      setUploadStatus({
        isUploading: false,
        isSuccess: true,
        message: 'Image uploaded successfully!',
      });
    }).catch((error) => {
      console.error("Upload error: ", error);
      setIsUploading(false);
      alert("An error occurred during upload: " + error.message);
    });
  };


  const onSubmit = async (formData) => {
    const updatedFormData = {
      ...formData,
      interioramenitiesImageUrls: uploadedImages.length > 0 ? uploadedImages : [],
    };
    onFormDataChange(updatedFormData);
    onNext();  
  };


  const handleInputChange = (event) => {
    onFormDataChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };


      

  const components = [
    'clock',
    'tiltSteeringWheel',
    'steeringColumnLock',
    'warningChimes',
    'instrumentCluster/WarningLights',
    'wipers',
    'washers',
    'manual/AutoRearviewMirrors',
    'autoDimmingRearviewMirrors',
    'blindSpotInformationSystem',
    'rearViewCamera',
    'brakeAssist',
    'interiorOverheadLighting',
    'upholster',
    'doorCovers',
    'dashBoard',
    'rearviewSunScreen',
    'sideSunScreens',
    'floorMats',
    'seatControl(manual/automatic)',
    'airbags',
    'safetyBelts',
    'soundSystemAndSpeakers',
    'alarm/TheftDeterrentSystem',
    'navigationSystem',
    'centralLockSystem',
    'windowControls',  
    
  ];

  const options = ['Ok', 'Defective', 'Service'];

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 grid grid-cols-4 gap-4 bg-white shadow-md rounded-lg p-6">
    <h2 className="col-span-4 font-bold text-2xl mb-4">Interior & Amenities</h2>
    {components.map((component, index) => (
      <div key={component} className={`flex flex-col items-start p-2 rounded-md ${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
        <label className="block text-indigo-500 text-sm font-bold mb-2 capitalize">
          {component.replace(/([A-Z])/g, ' $1')}
        </label>
        <div className="flex space-x-2 text-sm">
          {options.map((value) => (
            <label key={value} className="inline-flex items-center">
              <input
                type="radio"
                name={component}
                value={value}
                checked={formData[component] === value.toString()}
                onChange={handleInputChange}
                className="form-radio h-4 w-4 text-blue-500"
              />
              <span className="ml-2">{value}</span>
            </label>
          ))}
        </div>
      </div>
    ))}

<ImageUpload onCapture={handleImageCapture} />
{isUploading && <Spinner />} 
<div>
  {uploadedImages.length > 0 ? (
    uploadedImages.map((url, index) => (
      <p key={index}>
        Image {index + 1}:{' '}
        <a href={url} target="_blank" rel="noopener noreferrer">
          View
        </a>
      </p>
    ))
  ) : (
    <p className="text-gray-500 text-sm">No images uploaded (optional).</p>
  )}
</div>




  <div className="col-span-4">
    <label className="block text-indigo-500 text-sm font-bold mb-1">Diagnostic Codes Logged</label>
    <textarea
     {...register("interiorDiagnosticCodes")}
      name="interiorDiagnosticCodes"
      value={formData.interiorDiagnosticCodes || ''}
      onChange={handleInputChange}
      className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-indigo-500 focus:ring-indigo-500"
      rows="2"
    />
  </div>




    <div className="col-span-4 flex justify-between">
      <button
        type="button"
        onClick={onBack}
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm 
        text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-150 ease-in-out"
      >
        Back
      </button>

      <button
        type="submit"
        disabled={isUploading} 
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm 
        text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
      >
        Next
      </button>
    </div>
  </form>
     {uploadStatus.isUploading && (
      <p>Uploading image, please wait...</p>
    )}
    {uploadStatus.isSuccess && (
      <p className="text-green-500">{uploadStatus.message}</p>
    )}
    {uploadStatus.isSuccess === false && (
      <p className="text-red-500">{uploadStatus.message}</p>
    )}
    </>
  
  );
};

export default InteriorAmenities;
