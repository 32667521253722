import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs, where, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import PartsAndServices from './UpdatePartials/PartsAndServices';
import TechDetails from './UpdatePartials/TechDetails';
import JobHeader from './UpdatePartials/JobHeader';
import StatusBar from './UpdatePartials/StatusBar';
import AssignTask from './UpdatePartials/AssignTask';
import JobCardInvoice from './UpdatePartials/JobCardInvoice';
import BreadCrumb from '../BreadCrumb';
import { MoonLoader } from 'react-spinners';

const UpdateJob = () => {
  const { jobId } = useParams(); 
  const [jobCard, setJobCard] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [activeComponent, setActiveComponent] = useState('techdetails');
  const [loading, setLoading] = useState(true); 

  const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
    { name: 'Manage Vehicles', to: '/vehicles/manage', component: Link, current: false },
    { name: 'Update Job Card', to: `/vehicles/updateJob/${jobCard?.jobCardNo}`, component: Link, current: true },
  ];

  useEffect(() => {
    const fetchJobCard = async () => {
      if (jobId) {
        try {
          const jobDoc = await getDoc(doc(db, 'jobs', jobId));
          if (jobDoc.exists()) {
            setJobCard(jobDoc.data());
          } else {
            toast.error("Job card not found");
          }
        } catch (error) {
          toast.error("Failed to fetch job card");
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchEmployees = async () => {
      try {
        const q = query(collection(db, "employees"), where("role", "==", "Mechanic"));
        const querySnapshot = await getDocs(q);
        setEmployees(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        toast.error("Failed to fetch employees");
      }
    };

    fetchJobCard();
    fetchEmployees();
  }, [jobId]);

  const updateJobCard = async (updatedData) => {
    try {
      await updateDoc(doc(db, 'jobs', jobId), updatedData);
      setJobCard(updatedData); 
      toast.success("Job card updated successfully");
    } catch (error) {
      toast.error("Failed to update job card");
    }
  };

  const handleAddPart = async (newPart) => {
    const updatedParts = [...(jobCard.partsRequired || []), newPart];

    const updatedJobCard = {
      ...jobCard,
      partsRequired: updatedParts
    };

    await updateJobCard(updatedJobCard); 
  };

  const handleAddService = async (newService) => {
    const updatedServices = [...(jobCard.servicesRequired || []), newService];

    const updatedJobCard = {
      ...jobCard,
      servicesRequired: updatedServices
    };

    await updateJobCard(updatedJobCard); 
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'techdetails': 
        return <TechDetails jobCard={jobCard} updateJobCard={updateJobCard} />;
      case 'partsAndServices': 
        return <PartsAndServices jobCard={jobCard} handleAddPart={handleAddPart} handleAddService={handleAddService} />;
      case 'assignTask': 
        return <AssignTask jobCard={jobCard} employees={employees} />;
      case 'invoiceDetails': 
        return <JobCardInvoice jobCard={jobCard} employees={employees} />;
      default:
        return <TechDetails jobCard={jobCard} />;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <MoonLoader size={50} color="#4A90E2" />
      </div>
    );
  }

  if (!jobCard) {
    return <div>Job card not found.</div>;
  }

  return (
    <>
      <BreadCrumb pages={pages} />
      <ToastContainer />
      <div>
        <div className="p-2 bg-white rounded-lg shadow-lg max-w-6xl mx-auto">
          <JobHeader jobCard={jobCard} />
          <StatusBar jobCard={jobCard} />

          <nav className="mb-1">
            <ul className="flex space-x-4 justify-center border-b-2 border-gray-200 pb-1 text-sm">
              <li>
                <button
                  onClick={() => setActiveComponent('techdetails')}
                  className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'techdetails' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Diagnosis Report
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('partsAndServices')}
                  className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'partsAndServices' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Parts & Services Required
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('assignTask')}
                  className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'assignTask' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Assign Task
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('invoiceDetails')}
                  className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'invoiceDetails' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Invoices
                </button>
              </li>
            </ul>
          </nav>

          <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
            {renderComponent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateJob;
