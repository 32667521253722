import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

const JobDetailsModal = ({ isOpen, onClose, job }) => {




  // const markedItemComments = job?.markedItemComments || {};
  
  
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:max-w-4xl sm:w-full">
              <div className="bg-gray-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-2xl font-semibold text-gray-900">
                      Job Details
                    </Dialog.Title>
                    <div className="mt-4">
                      {job && (
                        <div className="space-y-6">
                          {/* Basic Information */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Basic Information</h4>
                            <p className="text-sm text-gray-600"><strong>Job Card No:</strong> {job.jobCardNo}</p>
                            <p className="text-sm text-gray-600"><strong>Owner:</strong> {job.customerName}</p>
                            <p className="text-sm text-gray-600"><strong>Email:</strong> {job.customerEmail}</p>
                            <p className="text-sm text-gray-600"><strong>Vehicle:</strong> {job.vehicleMake} {job.vehicleModel} ({job.vehicleYear})</p>
                            <p className="text-sm text-gray-600"><strong>Registration:</strong> {job.registrationNumber}</p>
                            <p className="text-sm text-gray-600"><strong>Entry Mileage:</strong> {job.entryMileage || 'N/A'}</p>
                            <p className="text-sm text-gray-600"><strong>Exit Mileage:</strong> {job.exitMileage || 'N/A'}</p>
                            <p className="text-sm text-gray-600"><strong>Status:</strong> {job.status}</p>
                          </div>

                          {/* Owner Request */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Owner Request</h4>
                            <p className="text-sm text-gray-600">{job.ownerRequest}</p>
                          </div>

                          {/* Technician Observation */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Technician Observation</h4>
                            <p className="text-sm text-gray-600">{job.technicianObservation}</p>
                          </div>

                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Work Required</h4>
                            <p className="text-sm text-gray-600">{job.workRequired}</p>
                          </div>

                          {/* Parts Required */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Parts Required</h4>
                            {Array.isArray(job.partsRequired) && job.partsRequired.length > 0 ? (
                              <ul className="list-disc list-inside text-sm text-gray-600">
                                {job.partsRequired.map((part, index) => (
                                  <li key={index}>
                                    {part.partName} - Quantity: {part.quantity} ({part.type})
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-sm text-gray-600">No parts required</p>
                            )}
                          </div>

                          {/* Services Required */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Services Required</h4>
                            {Array.isArray(job.servicesRequired) && job.servicesRequired.length > 0 ? (
                              <ul className="list-disc list-inside text-sm text-gray-600">
                                {job.servicesRequired.map((service, index) => (
                                  <li key={index}>
                                    {service.serviceType} - Quantity: {service.serviceQuantity}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-sm text-gray-600">No services required</p>
                            )}
                          </div>

                          {/* Inspection Checklist */}
                          <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Inspection Checklist</h4>
                            <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                              <div>
                                <strong>Exterior Items:</strong>
                                <ul className="list-disc list-inside">
                                  {Object.entries(job.inspectionChecklist?.exteriorItems || {}).map(([key, value]) => (
                                    <li key={key}>{key}: {value}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <strong>Interior Items:</strong>
                                <ul className="list-disc list-inside">
                                  {Object.entries(job.inspectionChecklist?.interiorItems || {}).map(([key, value]) => (
                                    <li key={key}>{key}: {value}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <strong>Under Hood Items:</strong>
                                <ul className="list-disc list-inside">
                                  {Object.entries(job.inspectionChecklist?.underHoodItems || {}).map(([key, value]) => (
                                    <li key={key}>{key}: {value}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <strong>Trunk Items:</strong>
                                <ul className="list-disc list-inside">
                                  {Object.entries(job.inspectionChecklist?.trunkItems || {}).map(([key, value]) => (
                                    <li key={key}>{key}: {value}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>

                           {/* Marked Item Comments */}
                           <div className="bg-white shadow sm:rounded-lg p-4">
                            <h4 className="text-lg font-semibold text-gray-800">Inspection Comments</h4>
                            {job.markedItemComments && Object.keys(job.markedItemComments).length > 0 ? (
                              <ul className="list-disc list-inside text-sm text-gray-600">
                                {Object.entries(job.markedItemComments).map(([key, comment]) => (
                                  <li key={key}>
                                    <strong>{key}:</strong> {comment}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-sm text-gray-600">Nothing to Report</p>
                            )}
                          </div>
                         
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default JobDetailsModal;
