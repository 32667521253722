import React, { useState, useEffect} from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { toast, ToastContainer } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import { useAuth } from '../Auth/Auth';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { JobDetails } from './Subs/JobDetails';

const pages = [
 
    { name: 'Procurement', to: '/procurement', component: Link, current: false },
    { name: 'Job Card Pricing', to: '/procurement/jobcardpricing', component: Link, current: false },
    { name: 'Job Card Cost', to: '/procurement/jobcardpricing/jobcost', component: Link, current: true },
  ];


const ProcQuotationPage = () => {

  const location = useLocation();
  const job = location.state?.job;
  const [parts, setParts] = useState(job?.partsRequired || []);
  const [services, setServices] = useState(job?.servicesRequired.map(service => ({
    ...service, bestCostPrice: parseInt(service.serviceCost, 10) 
  })) || []);
  const  {user}  = useAuth(); 
  const navigate = useNavigate();
  const currentUser = user?.email;
 
 
  
  useEffect(() => {
    if (job && job.servicesRequired) {
      setServices(job.servicesRequired.map(service => ({
        ...service,
        bestCostPrice: parseInt(service.serviceCost, 10)
      })));
    }
  }, [job]); 

    
  const handlePriceChange = (index, value, type, priceType) => {
    console.log(`Updating ${priceType} for ${type} at index ${index} to value: `, value);

    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      const updatedItems = type === 'part' ? [...parts] : [...services];
      if (priceType === 'best') {
        updatedItems[index].bestCostPrice = numericValue;
      } else if (priceType === 'worst') {
        updatedItems[index].worstCostPrice = numericValue;
      }
      type === 'part' ? setParts(updatedItems) : setServices(updatedItems);
    } else {
      // Handle invalid input, e.g., reset to previous value or set to a default like 0
      console.error('Invalid number input for price');
    }
  };
  
  const handlePartQuantityChange = (index, value) => {
    const updatedParts = [...parts];
    updatedParts[index].quantity = Number(value);
    setParts(updatedParts);
};

const handleServiceQuantityChange = (index, value) => {
    const updatedServices = [...services];
    updatedServices[index].serviceQuantity = Number(value);
    setServices(updatedServices);
};
  

const handleSaveQuotation = async () => {
  const isValidPrice = (price) => !isNaN(parseFloat(price)) && isFinite(price);

const isAllPartsValid = parts.every(part => isValidPrice(part.bestCostPrice) && isValidPrice(part.worstCostPrice));
const isAllServicesValid = services.every(service => isValidPrice(service.bestCostPrice) && isValidPrice(service.worstCostPrice));

if (!isAllPartsValid || !isAllServicesValid) {
  toast.error("Please fill all the cost price fields with valid numbers before saving.");
  return;
}


  try {
    const jobRef = doc(db, 'jobs', job.id);
    await updateDoc(jobRef, {
      partsRequired: parts.map(part => ({
        ...part,
        bestCostPrice: Number(part.bestCostPrice),
        worstCostPrice: Number(part.worstCostPrice)
      })),
      servicesRequired: services.map(service => ({
        ...service,
        bestCostPrice: Number(service.bestCostPrice),
        worstCostPrice: Number(service.worstCostPrice)
      })),
      procurementPricing: "Set",
      pricingProvidedBy : currentUser
    });
    toast.success("Cost prices saved successfully!");
    setTimeout(() => navigate('/procurement/jobcardpricing'), 2000);
  } catch (error) {
    console.error("Error saving cost prices: ", error);
    toast.error("Failed to save cost prices.");
  }
};



  const handleRemoveItem = (index, type) => {
    if (type === 'part') {
        setParts(parts.filter((_, idx) => idx !== index));
    } else {
        setServices(services.filter((_, idx) => idx !== index));
    }
};

  return (
    <>

< BreadCrumb pages={pages} />
     
    <div className="container mx-auto p-4 bg-white shadow-md rounded">   
            <header className="mb-1">
                <h1 className="text-2xl font-bold text-gray-800 mb-2">Add Cost Price to Job Card</h1>      

                  <div className="bg-white rounded shadow p-4">
                          <JobDetails job={job} />
                          </div>   
         </header>


               <main className="flex flex-wrap mt-4">

                         <section className="w-full lg:w-4/5 lg:pr-4">
                         
                         <div className="overflow-x-auto">
                              <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Type
                                      </th>
                                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Description
                                      </th>
                                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Quantity / Duration
                                      </th>
                                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Best Cost Price
                                      </th>
                                      <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Worst Cost Price
                                      </th>
                                      <th scope="col" className="relative px-2 py-3">
                                        <span className="sr-only">Delete</span>
                                      </th>
                                    </tr>
                                  </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                {parts.map((part, index) => (
                                    <tr key={index}>
                                      <td className="px-2 py-4 whitespace-nowrap">Part</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="text-sm text-gray-900">{part.partName}</div>
                                        </td>
                                    
                                      <td className="px-4 py-4 whitespace-nowrap">
                                        <input
                                          type="number"
                                          value={part.quantity}
                                          onChange={(e) => handlePartQuantityChange(index, e.target.value)}
                                          className="block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                        />
                                      </td>
                                      <td className="px-2 py-2 whitespace-nowrap">
                                        <input 
                                          type="number"
                                          required
                                          value={part.bestCostPrice}
                                         onChange={(e) => handlePriceChange(index, e.target.value, 'part', 'best')}
                                          placeholder="Best Cost Price"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </td>
                                      <td className="px-2 py-2 whitespace-nowrap">
                                        <input 
                                          type="number"
                                          value={part.worstCostPrice}
                                          onChange={(e) => handlePriceChange(index, e.target.value, 'part', 'worst')}
                                          placeholder="Worst Cost Price"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                      </td>
                                      </tr>
                                        ))}
                                        {services.map((service, index) => (
                                          <tr key={`service-${index}`}>
                                            <td className="px-2 py-4 whitespace-nowrap">Service</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                              <div className="text-sm text-gray-900">{service.serviceType}</div>
                                            </td>
                                            <td className="px-4 py-4 whitespace-nowrap">
                                              <input
                                                type="number"
                                                value={service.serviceQuantity}
                                                onChange={(e) => handleServiceQuantityChange(index, e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />
                                            </td>
                                            <td className="px-2 py-2 whitespace-nowrap">
                                            <input 
                                                type="number"
                                                required
                                                value={service.bestCostPrice}
                                                onChange={(e) => handlePriceChange(index, e.target.value, 'service', 'best')}
                                                placeholder="Best Cost Price"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />
                                            </td>
                                            <td className="px-2 py-2 whitespace-nowrap">
                                              <input 
                                                type="number"
                                                value={service.worstCostPrice}
                                                onChange={(e) => handlePriceChange(index, e.target.value, 'service', 'worst')}
                                                placeholder="Worst Cost Price"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button onClick={() => handleRemoveItem(index)} className="text-red-600 hover:text-red-900">
                                          <XCircleIcon className="h-6 w-6" />
                                        </button>
                                      </td>
                                          </tr>
                                        ))}                                     
                 
                                      </tbody>                                                            
                             
                             </table>

                             <button
                                  type="button"
                                  onClick={handleSaveQuotation}
                                  className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Save Cost
                                </button>
                           </div>                                                                                  
                                                                
                                
                                      
                          </section>                                
                      </main>             
       
                  <ToastContainer />                       

    </div>
    </>
  );
};

export default ProcQuotationPage;
