import React from "react";

const InspectionTable = ({ inspectionData }) => {
    const defectiveComponents = [];
  const serviceComponents = [];

  Object.entries(inspectionData).forEach(([component, status]) => {
    if (status === "Defective") {
      defectiveComponents.push(component.replace(/([A-Z])/g, ' $1').trim());
    } else if (status === "Service") {
      serviceComponents.push(component.replace(/([A-Z])/g, ' $1').trim());
    }
  });

  return (
   
    <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
            <tr className="bg-gray-200">
            <th className="border border-gray-300 px-4 py-2 text-gray-600 font-bold">
                Defective Components
            </th>
            <th className="border border-gray-300 px-4 py-2 text-gray-600 font-bold">
                Components Needing Service
            </th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td className="border border-gray-300 px-4 py-2 text-sm">
                {defectiveComponents.join(', ')}
            </td>
            <td className="border border-gray-300 px-4 py-2 text-sm">
                {serviceComponents.join(', ')}
            </td>
            </tr>
        </tbody>
        </table>



  );
};

  export default InspectionTable;
  
 
  