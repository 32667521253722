// Inventory.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  where,
  query,
  increment,
  arrayUnion,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import MoonLoader from 'react-spinners/MoonLoader';
import AllocationForm from './AllocationForm';
import InventoryForm from './InventoryForm';
import InventoryList from './InventoryList';

const Inventory = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [jobCards, setJobCards] = useState([]);
  const [showAllocationModal, setShowAllocationModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [allocation, setAllocation] = useState({
    itemId: '',
    quantity: 0,
    purpose: '',
    employeeId: '',
    jobCardId: '',
  });
  const [editItem, setEditItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { role, loading } = useAuth();
  const navigate = useNavigate();

  // Fetch Inventory Items
  const fetchInventoryItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'inventory'));
    setInventoryItems(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  // Fetch Employees
  const fetchEmployees = async () => {
    const querySnapshot = await getDocs(collection(db, 'employees'));
    setEmployees(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  // Fetch Job Cards
  const fetchJobCards = async () => {
    const q = query(
      collection(db, 'jobs'),
      where('approvalStatus', '==', 'Yes'),
      where('status', '!=', 'Closed')
    );
    const querySnapshot = await getDocs(q);
    setJobCards(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    fetchInventoryItems();
    fetchEmployees();
    fetchJobCards();
  }, []);

  // Automatic Inventory Update from Procurements
  useEffect(() => {
    const q = query(collection(db, 'procurements'), where('status', '==', 'Collected'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          const procurementData = change.doc.data();

          if (procurementData.cartItems && Array.isArray(procurementData.cartItems)) {
            procurementData.cartItems.forEach(async (item) => {
              // Check if item already exists in inventory
              const inventoryQuery = query(
                collection(db, 'inventory'),
                where('partName', '==', item.description)
              );
              const inventorySnapshot = await getDocs(inventoryQuery);
              if (!inventorySnapshot.empty) {
                // Update existing inventory item
                const inventoryDoc = inventorySnapshot.docs[0];
                await updateDoc(inventoryDoc.ref, {
                  quantity: increment(item.quantity),
                });
              } else {
                // Add new inventory item
                await addDoc(collection(db, 'inventory'), {
                  type: 'part',
                  partName: item.description,
                  costPrice: item.price,
                  quantity: item.quantity,
                  acquiredAt: procurementData.dateOfPurchase || new Date(),
                  registeredBy: procurementData.createdBy || 'System',
                  procurementId: change.doc.id,
                  supplier: procurementData.supplier,
                  purchasePrice: item.price,
                });
              }
              fetchInventoryItems();
            });
          } else {
            console.warn('No cartItems in procurementData:', procurementData);
          }
        }
      });
    });
    return () => unsubscribe();
  }, []);

  const openAllocationModal = (itemId) => {
    setSelectedItemId(itemId);
    setShowAllocationModal(true);
  };

  const handleAllocationChange = useCallback((e) => {
    const { name, value } = e.target;
    setAllocation((prevAllocation) => ({
      ...prevAllocation,
      [name]: value,
    }));
  }, []);

  const handleAllocationSubmit = async (e) => {
    e.preventDefault();

    const selectedItem = inventoryItems.find((item) => item.id === allocation.itemId);
    const allocatedQuantity = parseInt(allocation.quantity, 10);

    if (allocatedQuantity > selectedItem.quantity) {
      toast.error('Allocated quantity exceeds available stock.');
      return;
    }

    try {
      // Deduct allocated quantity from inventory
      const itemRef = doc(db, 'inventory', allocation.itemId);
      await updateDoc(itemRef, {
        quantity: selectedItem.quantity - allocatedQuantity,
      });

      // Record allocation
      await addDoc(collection(db, 'allocations'), {
        ...allocation,
        allocatedAt: new Date(),
      });

      // If purpose is 'Job Card', update the job's tracker
      if (allocation.purpose === 'Job Card') {
        const jobRef = doc(db, 'jobs', allocation.jobCardId);
        await updateDoc(jobRef, {
          tracker: arrayUnion({
            date: new Date(),
            state: 'Parts Obtained',
          }),
        });
      }

      toast.success('Item allocated successfully.');

      // Reset allocation form
      setAllocation({
        itemId: '',
        quantity: 0,
        purpose: '',
        employeeId: '',
        jobCardId: '',
      });

      // Refresh inventory items
      fetchInventoryItems();
    } catch (error) {
      console.error('Error allocating item:', error);
      toast.error('Error allocating item.');
    }
    setShowAllocationModal(false);
    setSelectedItemId(null);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setIsEditing(true);
  };

  const handleDelete = async (itemId) => {
    try {
      await deleteDoc(doc(db, 'inventory', itemId));
      toast.success('Inventory Item Deleted');
      fetchInventoryItems();
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      toast.error('Error deleting inventory item.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <MoonLoader size={50} />
      </div>
    );
  }

  if (role !== 'Finance' && role !== 'Admin') {
    return navigate('/unauthorized', { replace: true });
  }

  return (
    <div className="container mx-auto p-6 bg-white shadow rounded-lg">
      <ToastContainer />
      <h2 className="text-2xl font-semibold mb-6">Inventory Management</h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Left Column - Forms */}
        <div className="mb-8 md:col-span-4">
          <InventoryForm
            fetchInventoryItems={fetchInventoryItems}
            editItem={editItem}
            setEditItem={setEditItem}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
          />
        </div>

        {/* Right Column - Inventory List */}
        <div className="md:col-span-8">
          <InventoryList
            inventoryItems={inventoryItems}
            openAllocationModal={openAllocationModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>

      {/* AllocationForm Modal */}
      {showAllocationModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={() => setShowAllocationModal(false)}
          ></div>
          <div className="bg-white p-6 rounded shadow-lg z-10 w-full max-w-lg relative">
            <button
              onClick={() => setShowAllocationModal(false)}
              className="text-gray-500 hover:text-gray-700 absolute top-2 right-2 text-2xl font-bold"
            >
              &times;
            </button>
            <AllocationForm
              handleAllocationSubmit={handleAllocationSubmit}
              handleAllocationChange={handleAllocationChange}
              allocation={allocation}
              inventoryItems={inventoryItems}
              employees={employees}
              jobCards={jobCards}
              selectedItemId={selectedItemId}
              fetchInventoryItems={fetchInventoryItems}
              setShowAllocationModal={setShowAllocationModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Inventory;
