import React, { useEffect, useState } from 'react';
import { birthdaysOfTheWeek } from "./birthdaysThisWeek";
import moment from 'moment';

const BirthdayList = () => {
  const [birthdays, setBirthdays] = useState([]);

  useEffect(() => {
    birthdaysOfTheWeek().then(data => {
      const updatedBirthdays = data.map(customer => {
        const dateInCurrentYear = moment(customer.dateOfBirth).year(moment().year()).format('dddd, Do MMM YYYY');
        return { ...customer, dateOfBirth: dateInCurrentYear };
      });
      setBirthdays(updatedBirthdays);
    });
  }, []);

  return (
    <div className="overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">Name</th>
            <th scope="col" className="px-6 py-3">Birthday</th>
           
          </tr>
        </thead>
        <tbody>
          {birthdays.map((customer, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700">
              <td className="px-6 py-2">{customer.name}</td>
              <td className="px-6 py-2">{customer.dateOfBirth}</td>
              </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BirthdayList;
