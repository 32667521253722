// PayableModal.js
import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { formatCurrency } from '../../Help/helper';
import { collection, writeBatch, doc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast, ToastContainer } from 'react-toastify';

const PayableModal = ({ isOpen, onClose, payable, updatePayableState }) => {
  const [amountPaid, setAmountPaid] = useState('');
  const [modeOfPayment, setModeOfPayment] = useState('');
  const [datePaid, setDatePaid] = useState('');

  const paymentModes = [
    'Bank Transfer - Stanbic Opex Account',
    'Bank Transfer - Stanbic Revenue Account',
    'Bank Transfer - Stanbic - CAPEX Account',
    'Cash',
    'POS',
  ];

  if (!payable) {
    return null; // Or render a loading indicator or appropriate message
  }


 // Safely parse approvalDate
 let approvalDate = null;

 if (payable.statusUpdateDate) {
   if (payable.statusUpdateDate.toDate && typeof payable.statusUpdateDate.toDate === 'function') {
     // Firestore Timestamp
     approvalDate = payable.statusUpdateDate.toDate();
   } else if (typeof payable.statusUpdateDate === 'string' || payable.statusUpdateDate instanceof String) {
     const parsedDate = new Date(payable.statusUpdateDate);
     if (!isNaN(parsedDate.getTime())) {
       approvalDate = parsedDate;
     }
   } else if (payable.statusUpdateDate instanceof Date) {
     approvalDate = payable.statusUpdateDate;
   }
 }

 // If approvalDate is invalid, default to current date
 if (!approvalDate || isNaN(approvalDate.getTime())) {
   approvalDate = new Date();
 }

 const approvalDateString = approvalDate.toISOString().split('T')[0];

 

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!payable || !payable.id) {
      toast.error('Procurement Details Not Found');
      return;
    }

    const selectedDate = new Date(datePaid);
    // Normalize time components
    selectedDate.setHours(0, 0, 0, 0);
    approvalDate.setHours(0, 0, 0, 0);

    if (selectedDate < approvalDate) {
      toast.error('Date paid cannot be before the procurement approval date.');
      return;
    }

    const newAmountPaid = Number(amountPaid);

    if (newAmountPaid <= 0) {
      toast.error('Amount paid must be greater than zero.');
      return;
    }

    const amountDue =
      payable.amountDue !== undefined
        ? payable.amountDue
        : payable.grandTotal - (payable.totalAmountPaid || 0);

    if (newAmountPaid > amountDue) {
      toast.error('Amount paid cannot exceed the amount due.');
      return;
    }

    const procurementsRef = doc(db, 'procurements', payable.id);
    const newTransactionRef = doc(collection(db, 'transactions'));

    const paymentTimestamp = datePaid
      ? Timestamp.fromDate(selectedDate)
      : Timestamp.now();
    const batch = writeBatch(db);

    const totalAmountPaid = (payable.totalAmountPaid || 0) + newAmountPaid;
    const updatedAmountDue = amountDue - newAmountPaid;

    let newPaymentStatus = payable.paymentStatus;
    if (updatedAmountDue === 0) {
      newPaymentStatus = 'Fully Paid';
    } else if (updatedAmountDue > 0 && updatedAmountDue < payable.grandTotal) {
      newPaymentStatus = 'Partially Paid';
    }

    // Create a new transaction document
    const transactionData = {
      type: 'Expenditure',
      amount: newAmountPaid,
      transactionDate: new Date().toISOString().split('T')[0],
      category: 'Procurement',
      procurementNumber: payable.procurementNumber,
      description: `Payment to ${payable.supplier} for ${payable.procurementNumber}`,
      datePaid: paymentTimestamp,
      modeOfPayment,
    };

    batch.set(newTransactionRef, transactionData);

    // Update the procurements document
    const paymentData = {
      amountPaid: newAmountPaid,
      dateOfPayment: paymentTimestamp,
      modeOfPayment,
    };

    batch.update(procurementsRef, {
      totalAmountPaid: totalAmountPaid,
      amountDue: updatedAmountDue,
      paymentsMade: arrayUnion(paymentData),
      paymentStatus: newPaymentStatus,
    });

    // Commit the batch
    await batch.commit();

    const updatedPayable = {
      ...payable,
      totalAmountPaid: totalAmountPaid,
      amountDue: updatedAmountDue,
      paymentStatus: newPaymentStatus,
      paymentsMade: [...(payable.paymentsMade || []), paymentData],
    };

    if (updatePayableState) {
      updatePayableState(updatedPayable);
    }

    setAmountPaid('');
    setModeOfPayment('');
    setDatePaid('');
    onClose();

    toast.success('Payment Successfully Posted');
  };

  return (
    <>
      <ToastContainer />
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}
        >
          {/* Modal overlay */}
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Updated modal width to max-w-4xl */}
              <Dialog.Panel className="w-full max-w-4xl p-6 my-8 bg-white shadow-xl rounded-2xl">
                <div className="flex flex-col md:flex-row md:justify-between">
                  {/* Left column: Payable details */}
                  <div className="md:w-2/3 md:pr-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-4"
                    >
                      Payable Details
                    </Dialog.Title>
                    <Dialog.Description className="text-sm text-gray-500 mb-4">
                      <p>
                        Procurement Number:{' '}
                        <span className="font-semibold text-gray-700">
                          {payable.procurementNumber}
                        </span>
                      </p>
                      <p>
                        Supplier:{' '}
                        <span className="font-semibold text-gray-700">
                          {payable.supplier}
                        </span>
                      </p>
                      <p>
                        Approval Date:{' '}
                        {approvalDate.toLocaleDateString()}
                      </p>
                      <p>
                        Total Amount:{' '}
                        <span className="text-gray-700 text-xl">
                          {formatCurrency(payable.grandTotal)}
                        </span>
                      </p>
                      <p>
                        Amount Due:{' '}
                        <span className="text-red-600 text-xl">
                          {formatCurrency(
                            payable.amountDue !== undefined
                              ? payable.amountDue
                              : payable.grandTotal -
                                (payable.totalAmountPaid || 0)
                          )}
                        </span>
                      </p>
                    </Dialog.Description>

                    {/* Payable items table */}
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Description
                            </th>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Quantity
                            </th>
                            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {payable.cartItems.map((item, index) => (
                            <tr key={index}>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
                                {item.description}
                              </td>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                                {item.quantity}
                              </td>
                              <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                                {formatCurrency(item.price)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Previous Payments */}
                    {payable.paymentsMade && payable.paymentsMade.length > 0 && (
                      <div className="my-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                          Previous Payments Made
                        </h3>
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Amount Paid
                                </th>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Date of Payment
                                </th>
                                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Mode of Payment
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {payable.paymentsMade.map((payment, index) => (
                                <tr key={index}>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {formatCurrency(payment.amountPaid)}
                                  </td>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {payment.dateOfPayment
                                      .toDate()
                                      .toLocaleDateString()}
                                  </td>
                                  <td className="px-2 py-2 whitespace-nowrap text-sm">
                                    {payment.modeOfPayment}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Right column: Payment form */}
                  <div className="md:w-1/3 md:pl-4 mt-6 md:mt-0">
                    <h2 className="mb-4 text-lg font-medium text-gray-900">
                      Record Payment to Vendor
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <input
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        type="number"
                        placeholder="Amount Paid"
                        value={amountPaid}
                        onChange={(e) => setAmountPaid(e.target.value)}
                        required
                      />
                      <select
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        value={modeOfPayment}
                        onChange={(e) => setModeOfPayment(e.target.value)}
                        required
                      >
                        <option value="">Select Mode of Payment</option>
                        {paymentModes.map((mode, index) => (
                          <option key={index} value={mode}>
                            {mode}
                          </option>
                        ))}
                      </select>

                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Date Payment was Made
                      </label>
                      <input
                        type="date"
                        className="w-full p-2 mb-3 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        value={datePaid}
                        onChange={(e) => setDatePaid(e.target.value)}
                        min={approvalDateString}
                        required
                      />

                      <button
                        type="submit"
                        className="w-full p-2 bg-blue-600 text-white mt-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Record Payment
                      </button>
                    </form>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="w-full p-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PayableModal;
