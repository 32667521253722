// InventoryForm.js

import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import { toast } from 'react-toastify';
import { YearSelect } from '../Vehicles/YearSelect';
import Select from 'react-select';
import formattedVehicleManufacturers from '../Vehicles/vehicleManufacturers';
import {generateProcurementNumber} from '../Help/helper';

const InventoryForm = ({
  fetchInventoryItems,
  editItem,
  setEditItem,
  setIsEditing,
  isEditing,
}) => {
  const [newItem, setNewItem] = useState({
    type: '',
    partName: '',
    partNumber: '',
    make: '',
    model: '',
    year: '',
    description: '',
    costPrice: 0,
    quantity: 0,
    supplier: '',
    procurementId: '',
   
  });
  const [editItemId, setEditItemId] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const { user } = useAuth();

  // Fetch suppliers data from Firestore
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const suppliersCollection = collection(db, 'suppliers');
        const suppliersSnapshot = await getDocs(suppliersCollection);
        const suppliersList = suppliersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSuppliers(suppliersList);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
        toast.error('Error fetching suppliers.');
      }
    };

    fetchSuppliers();
  }, []);


  useEffect(() => {
    if (editItem) {
      setNewItem({ ...editItem });
      setIsEditing(true);
      setEditItemId(editItem.id);
      if (editItem.make) {
        setSelectedMake({ label: editItem.make, value: editItem.make });
      }
    }
  }, [editItem, setIsEditing]);

  const handleInputChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const handleYearChange = (event) => {
    setNewItem({
      ...newItem,
      year: event.target.value,
    });
  };

  const handleMakeChange = (selectedOption) => {
    setSelectedMake(selectedOption);
    setNewItem({ ...newItem, make: selectedOption.label });
  };

  const handleSupplierChange = (selectedSupplier) => {
    setNewItem({ ...newItem, supplier: selectedSupplier.companyName });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = user.uid;
    const procurementNumber = generateProcurementNumber(); 
    const itemToSave = {
      ...newItem,
      costPrice: parseFloat(newItem.costPrice),
      procurementId: procurementNumber, 
      quantity: parseInt(newItem.quantity, 10),
      acquiredAt: new Date(),
      registeredBy: userId,
    };

    if (newItem.type === 'part' && (!newItem.make || !newItem.model || !newItem.year)) {
      toast.error('Please fill in make, model, and year for parts');
      return;
    }
    if (newItem.type === 'generic' && !newItem.description) {
      toast.error('Please fill in the description for generic items');
      return;
    }

    try {
      if (isEditing) {
        await updateDoc(doc(db, 'inventory', editItemId), itemToSave);
        setIsEditing(false);
        setEditItemId(null);
        setEditItem(null);
        toast.success('Item updated successfully');
      } else {
        await addDoc(collection(db, 'inventory'), itemToSave);
        toast.success('Item added successfully');
      }
      setNewItem({
        type: '',
        partName: '',
        partNumber: '',
        make: '',
        model: '',
        year: '',
        description: '',
        costPrice: 0,
        quantity: 0,
        supplier: '',
        procurementId: '',
      
      });
      setSelectedMake(null);
      fetchInventoryItems();
    } catch (error) {
      console.error('Error managing inventory item:', error);
      toast.error('Error managing inventory item.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8">
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div>
          <label
            className="block text-gray-700 font-semibold mb-1 text-sm"
            htmlFor="type"
          >
            Type of Item
          </label>
          <select
            name="type"
            value={newItem.type}
            onChange={handleInputChange}
            required
            className="block w-full p-2 border text-sm border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select Type</option>
            <option value="part">Vehicle Part</option>
            <option value="generic">Generic Item</option>
          </select>
        </div>

        {/* Conditional rendering based on type */}
        {newItem.type === 'part' && (
          <>
            <div>
              <label
                className="block text-gray-700 font-semibold mb-1 text-sm"
                htmlFor="partName"
              >
                Part Name or Description
              </label>
              <input
                name="partName"
                value={newItem.partName}
                onChange={handleInputChange}
                placeholder="Part Name"
                className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div>
              <label
                className="block text-gray-700 font-semibold mb-1 text-sm"
                htmlFor="partNumber"
              >
                Part Number
              </label>
              <input
                name="partNumber"
                value={newItem.partNumber}
                onChange={handleInputChange}
                placeholder="Part Number"
                className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div>
              <label
                htmlFor="vehicleManufacturers"
                className="block text-sm font-medium text-gray-700"
              >
                Vehicle Manufacturer
              </label>
              <Select
                options={formattedVehicleManufacturers}
                value={selectedMake}
                onChange={handleMakeChange}
                className="mt-1"
              />
            </div>

            <div>
              <label
                className="block text-gray-700 font-semibold mb-1 text-sm"
                htmlFor="model"
              >
                Vehicle Model
              </label>
              <input
                name="model"
                value={newItem.model}
                onChange={handleInputChange}
                placeholder="Model"
                className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div>
              <label
                className="block text-gray-700 font-semibold mb-1 text-sm"
                htmlFor="year"
              >
                Vehicle Year
              </label>
              <YearSelect onChange={handleYearChange} value={newItem.year} />
            </div>
          </>
        )}

        {newItem.type === 'generic' && (
          <div>
            <label
              className="block text-gray-700 font-semibold mb-1 text-sm"
              htmlFor="description"
            >
              Description
            </label>
            <input
              name="description"
              value={newItem.description}
              onChange={handleInputChange}
              placeholder="Item Description"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        )}

        {/* Additional Fields */}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm" htmlFor="supplier">
            Supplier
          </label>
          <select
            name="supplier"
            value={newItem.supplier}
            onChange={(e) => handleSupplierChange(suppliers.find(s => s.companyName === e.target.value))}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          >
            <option value="">Select Supplier</option>
            {suppliers.map((supplier) => (
              <option key={supplier.id} value={supplier.companyName}>
                {supplier.companyName}
              </option>
            ))}
          </select>
        </div>
     


        <div className="grid grid-cols-2 gap-4">
          <div>
            <label
              className="block text-sm font-medium leading-6 text-gray-700"
              htmlFor="costPrice"
            >
              Cost Price
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">₦</span>
              </div>
              <input
                type="number"
                name="costPrice"
                value={newItem.costPrice}
                onChange={handleInputChange}
                id="price"
                className="block w-full rounded-md border p-2 pl-7 pr-12 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  NGN
                </span>
              </div>
            </div>
          </div>

          <div>
            <label
              className="block text-sm font-medium leading-6 text-gray-700"
              htmlFor="quantity"
            >
              Quantity
            </label>
            <input
              name="quantity"
              type="number"
              value={newItem.quantity}
              onChange={handleInputChange}
              placeholder="Quantity"
              className="block w-full rounded-md border p-2 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out w-full"
      >
        {isEditing ? 'Update Item' : 'Add Item'}
      </button>
    </form>
  );
};

export default InventoryForm;
