import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 

export const generateJobCardNo = (customerNumber, jobTypes) => {
  // Ensure jobTypes is an array, or default to an empty array if not provided
  const jobTypeArray = Array.isArray(jobTypes) ? jobTypes : [jobTypes];
  const jobTypeAbbr = jobTypeArray.join('-').toUpperCase(); // e.g., "GR-BP"

  // Add a timestamp or date component to ensure uniqueness
  const dateComponent = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD format

  // Use a random number for added uniqueness, and combine with date and customer info
  const uniquePrefix = Math.floor(1000 + Math.random() * 9000); // Random prefix

  // Return a more unique jobCardNo with date, customerNumber, and jobType abbreviation
  return `${uniquePrefix}-${customerNumber}-${jobTypeAbbr}-${dateComponent}`;
};



 export const checkJobCardNoExists = async (jobCardNo) => {
  const q = query(collection(db, 'jobs'), where('jobCardNo', '==', jobCardNo));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;  // Return true if the job card number exists
};