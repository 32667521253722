import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { EditCartItems } from './partials/EditCartItems';
import { formatCurrency } from '../Help/helper';
import { useAuth } from '../Auth/Auth';
import { toast, ToastContainer } from 'react-toastify';
import PrintableQuotation from './partials/PrintableQuotation';
import './Utils/QuotationModalPrint.css';
import BreadCrumb from '../BreadCrumb';



const EditQuotation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [includeTax, setIncludeTax] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showNewItemForm, setShowNewItemForm] = useState(false);
    const [totals, setTotals] = useState({
        subtotal: 0,
        taxAmount: 0,
        totalSale: 0,
        totalCost: 0
    });

  const [quotation, setQuotation] = useState({
    customerName: '',
    jobCardNo: '',
    comments: '',
    cartItems: [], // Ensure this is part of your quotation structure
    
  });
  const [newItem, setNewItem] = useState({
    type: '',
    description: '',
    quantity: 0,
    costPrice: 0,
    salePrice: 0
  });
  const  {user}  = useAuth(); 
  const currentUser = user.email;
  
  const pages = [
    { name: 'Finance', to: '/finance', component: Link, current: false },
    { name: 'Quotations Admin', to: '/finance/quotations', component: Link, current: false }, 
    { name: 'Edit Quotation Details', to: '/finance/edit-quotation/{id}', component: Link, current: true },   
  ];



  useEffect(() => {
    const fetchQuotation = async () => {
      const docRef = doc(db, 'quotations', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setQuotation(docSnap.data());
      } else {
        console.log('No such quotation!');
      }
    };

    fetchQuotation();
  }, [id]);

  useEffect(() => {
    const subtotal = quotation.cartItems.reduce((acc, item) => acc + item.salePrice * item.quantity, 0);
    const totalCost = quotation.cartItems.reduce((acc, item) => acc + item.costPrice * item.quantity, 0);
    const taxRate = includeTax ? 0.075 : 0;
    const taxAmount = subtotal * taxRate;
    const totalSale = subtotal + taxAmount;

    setTotals({
        subtotal,
        taxAmount,
        totalSale,
        totalCost
    });
}, [quotation.cartItems, includeTax]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotation(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const finalizeQuotation = () => {
    handleUpdate();
  };
  

  const handleSave = async () => {
    // Perform Firestore update operations here
    try {
      const updatedQuotationData = {
        ...quotation,
        subtotal: totals.subtotal,
        totalCost: totals.totalCost,
        taxAmount: totals.taxAmount,
        totalSale: totals.totalSale,
        modifiedBy: currentUser || 'unknown',
        comments: quotation.comments || '',
        updatedAt: new Date()
      };
      await updateDoc(doc(db, 'quotations', id), updatedQuotationData);
      toast.success('Quotation updated successfully');
      setIsSaved(true); 
    } catch (error) {
      console.error('Error updating quotation: ', error);
      toast.error('Error updating quotation.');
    }
  };
  
 
 
 

  const handleQuantityChange = (index, newQuantity) => {
    const updatedCartItems = quotation.cartItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, quantity: parseInt(newQuantity, 10) };
      }
      return item;
    });
  
    setQuotation({ ...quotation, cartItems: updatedCartItems });
  };

  const handleCostPriceChange = (index, newCostPrice) => {
    const updatedCartItems = quotation.cartItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, costPrice: parseFloat(newCostPrice) };
      }
      return item;
    });
  
    setQuotation({ ...quotation, cartItems: updatedCartItems });
  };

  const handleSalePriceChange = (index, newSalePrice) => {
    const updatedCartItems = quotation.cartItems.map((item, idx) => {
        if (idx === index) {
            return { ...item, salePrice: parseFloat(newSalePrice) };
        }
        return item;
    });

    setQuotation({ ...quotation, cartItems: updatedCartItems });
};


  const handleTypeChange = (index, newType) => {
    const updatedCartItems = quotation.cartItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, type: newType };
      }
      return item;
    });
  
    setQuotation({ ...quotation, cartItems: updatedCartItems });
  };

  const handleRemoveFromCart = (index) => {
    const updatedCartItems = quotation.cartItems.filter((_, idx) => idx !== index);
    setQuotation({ ...quotation, cartItems: updatedCartItems });
  };

  const handleAddToCart = (item) => {
    if (item.description && item.quantity && item.costPrice && item.salePrice) {
      setQuotation(prevQuotation => ({
        ...prevQuotation,
        cartItems: [...prevQuotation.cartItems, item]
      }));
      setShowNewItemForm(false); // Hide the form
      setNewItem({ type: '', description: '', quantity: 0, costPrice: 0, salePrice: 0 }); // Reset form
    } else {
      toast.error("Please fill all fields correctly.");
    }
  };
  
  

  const handleUpdate = async () => {
    const updatedQuotationData = {
      ...quotation,
      subtotal: totals.subtotal,
      totalCost: totals.totalCost,
      taxAmount: totals.taxAmount,
      totalSale: totals.totalSale,
      modifiedBy: currentUser || 'unknown',
      comments: quotation.comments || '',
      updatedAt: new Date()
    };
  
    try {
      await updateDoc(doc(db, 'quotations', id), updatedQuotationData);
      toast.success('Quotation updated successfully');
      setTimeout(() => {
        navigate('/finance/quotations');
      }, 1000); 
    } catch (error) {
      console.error('Error updating quotation: ', error);
      toast.error('Error updating quotation.');
    }
  };
  
  
 
  
  
  const handleCommentChange = (e) => {
    setQuotation({ ...quotation, comments: e.target.value });
  };

 

  const handleDescriptionChange = (index, newDescription) => {
    
    const updatedCartItems = quotation.cartItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, description: newDescription };
      }
      return item;
    });
  
    setQuotation({ ...quotation, cartItems: updatedCartItems });
  };
  
  const handlePrintPreview = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!quotation) return null; // Display loading or placeholder

  console.log("Updated cartItems:", quotation.cartItems);


  return (
    <>
    <BreadCrumb pages={pages} />
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Edit Quotation</h2>
      <button
        type="button"
        className="mb-4 text-sm bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded"
        onClick={() => setShowNewItemForm(!showNewItemForm)}
      >
        {showNewItemForm ? 'Cancel Adding' : 'Add New Item'}
      </button>

     

      <form onSubmit={handleUpdate}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label htmlFor="customerName" className="block text-sm font-medium text-gray-700">Customer Name</label>
            <input
              type="text"
              name="customerName"
              id="customerName"
              value={quotation.customerName}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="jobCardNo" className="block text-sm font-medium text-gray-700">Job Card No</label>
            <input
              type="text"
              name="jobCardNo"
              id="jobCardNo"
              value={quotation.jobCardNo}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          {/* Include other fields as needed */}
        </div>
  

                 



        <EditCartItems
         cartItems={quotation.cartItems}
         handleQuantityChange={handleQuantityChange}
         handleCostPriceChange={handleCostPriceChange}
         handleSalePriceChange={handleSalePriceChange}
         handleTypeChange={handleTypeChange}
         handleRemoveFromCart={handleRemoveFromCart}
         handleDescriptionChange={handleDescriptionChange}
         showNewItemForm={showNewItemForm}
         setShowNewItemForm={setShowNewItemForm}
         newItem={newItem}
         setNewItem={setNewItem}
         handleAddToCart={handleAddToCart}
        />
        
  



        <div className="mt-4">
            <label htmlFor="comments" className="block text-sm font-medium text-gray-700">Comments</label>
            <textarea
                id="comments"
                name="comments"
                rows="4"
                value={quotation.comments}
                onChange={handleCommentChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Add any comments about this edit..."
            ></textarea>
            </div>
       
      </form>

     
      <div className="flex">
      <div className="sticky flex-1 top-4 p-4 bg-gray-50 rounded shadow">
        <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Totals</h2>
            <button onClick={finalizeQuotation} className="bg-blue-600 hover:bg-green-700 text-white text-sm py-2 px-4 rounded transition duration-200">
                Finalize Quotation
            </button>
        </div>
        <div className="mb-4">
            <label className="flex items-center space-x-3 mb-2">
                <input
                    type="checkbox"
                    checked={includeTax}
                    onChange={(e) => setIncludeTax(e.target.checked)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="text-sm text-gray-700">Include 7.5% Tax</span>
            </label>
            <div className="text-sm print:hidden">
                <p>SUBTOTAL: {formatCurrency(totals.subtotal)}</p>
                <p>VAT (7.5%): {includeTax ? formatCurrency(totals.taxAmount) : "₦0.00"}</p>
                <p className="font-bold text-xl">TOTAL: {formatCurrency(totals.totalSale)}</p>
            </div>
        </div>
    </div>

    {/* Margin Analysis */}
    <div className="p-4 bg-gray-50 rounded shadow flex-1 ml-4 print:hidden">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Margin Analysis</h2>
        <div className="text-sm">
            <p>Total Sale Amount: {formatCurrency(totals.totalSale)}</p>
            <p>Total Cost Amount: {formatCurrency(totals.totalCost)}</p>
            <p>Margin: {formatCurrency(totals.totalSale - totals.totalCost)}</p>
        </div>
    </div>

 
</div>

<div className="flex justify-between mt-4">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded print:hidden"
          onClick={handleSave}
        >
          Save Update
        </button>

        <button onClick={handlePrintPreview}   disabled={!isSaved}  
       className={`bg-green-600 hover:bg-green-700 text-white font-bold print:hidden
       py-2 px-4 rounded ${!isSaved && 'opacity-50 cursor-not-allowed'}`} >
        Print Preview
      </button>


       
      </div>


      {isModalOpen && <PrintableQuotation quotation={quotation} totals={totals} onClose={handleCloseModal} />}




<ToastContainer />
    </div>
    </>
  );
  
};

export default EditQuotation;
