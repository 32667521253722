import React, { useState } from 'react';

// StarRating.js
const StarRating = ({ count, value, onChange, size }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const getColor = index => {
    if (hoverRating >= index) {
      return 'text-yellow-500'; // Color when hovered
    } else if (!hoverRating && value >= index) {
      return 'text-yellow-500'; // Color when selected
    }
    return 'text-gray-400'; // Default color
  };

  const handleClick = (ratingValue, event) => {
    if (onChange) {
      onChange(ratingValue * 20, event); // Pass the rating value * 20 to match the scale
    }
  };

  const starRating = Array.from({ length: count }, (_, i) => i + 1).map(index => (
    <button
      key={index}
      className={`hover:text-yellow-500 ${getColor(index)} ${size}`}
      onClick={(e) => handleClick(index, e)}
      onMouseEnter={() => setHoverRating(index)}
      onMouseLeave={() => setHoverRating(0)}
    >
      ★
    </button>
  ));

  return <div className="flex">{starRating}</div>;
};

export default StarRating;
