import { db } from '../../firebase';
import { collection, getDocs, doc, updateDoc, query, orderBy } from 'firebase/firestore';
import { generateCustomerNumber } from '../Customers/generateCustomerNumber';


export function generateGIN(customerNumber, sequentialNumber) {
  return `${customerNumber}-${String(sequentialNumber).padStart(3, '0')}`;
}




// generateVehicleGIN.js
export const generateVehicleGIN = (customerNumber, vehicleCount) => {
  const vehicleId = String(vehicleCount).padStart(3, '0');
  return `${customerNumber}-${vehicleId}`;
};


// generateVisitGIN.js
export const generateVisitGIN = (vehicleGIN, visitCount) => {
  const visitId = String(visitCount).padStart(1, '0');
  return `${vehicleGIN}-${visitId}`;
};

  // Function to update GINs for all vehicles that don't have one yet
  export async function updateVehicleGINs() {
    const vehiclesRef = collection(db, "vehicles");
    const customersRef = collection(db, "customers");
  
    // Fetch all vehicles and customers
    const vehiclesSnapshot = await getDocs(vehiclesRef);
    const customersSnapshot = await getDocs(customersRef);
  
    // Create a map to store customer data by customerId
    let customerVehicleCountMap = {};
  
    // Populate customerVehicleCountMap based on customerId
    customersSnapshot.forEach(docSnap => {
      const customer = docSnap.data();
      customerVehicleCountMap[docSnap.id] = {
        customerNumber: customer.customerNumber,
        count: 0, // Start with zero vehicles
      };
    });
  
    console.log('Fetched all customers and vehicles. Starting GIN update...');
  
    // Process each vehicle and assign a new GIN
    for (const docSnap of vehiclesSnapshot.docs) {
      const vehicle = docSnap.data();
      const customerId = vehicle.customerId; // The customerId field in the vehicle document
  
      const customer = customerVehicleCountMap[customerId];
  
      if (customer) {
        // Increment the vehicle count for this customer
        customer.count += 1;
  
        // Generate the new GIN for the vehicle
        const newGIN = `${customer.customerNumber}-${String(customer.count).padStart(3, '0')}`;
  
        try {
          // Update the GIN field for the vehicle
          await updateDoc(doc(db, "vehicles", docSnap.id), { GIN: newGIN });
          console.log(`Updated vehicle ${vehicle.registrationNumber} with new GIN: ${newGIN}`);
        } catch (error) {
          console.error(`Error updating vehicle ${vehicle.registrationNumber}: `, error);
        }
      } else {
        console.warn(`Customer not found for vehicle ${vehicle.registrationNumber}`);
      }
    }
  
    console.log('Vehicle GIN update completed.');
  }
  






export async function updateEmployees () {
  const employeesRef = collection(db, "employees");
  const snapshot = await getDocs(employeesRef);
  let countUpdated = 0; // To keep track of how many were actually updated
  
  snapshot.forEach(async (docSnap) => {
    const employee = docSnap.data();

    // Check if the keys already exist and skip if they do
    if ("annualLeaveDays" in employee && "casualLeaveDays" in employee && "designatedManagerId" in employee && "startDate" in employee) {
      console.log(`Skipping update for ${employee.email}, already updated.`);
      return; // Skip this iteration
    }

    const newData = {
      annualLeaveDays: employee.annualLeaveDays ?? 0, // Use existing value if present, otherwise default
      casualLeaveDays: employee.casualLeaveDays ?? 0, // Use existing value if present, otherwise default
      designatedManagerId: employee.designatedManagerId ?? '', // Empty if not set
      startDate: employee.startDate ?? null, // Null if not set
    };
    
    await updateDoc(doc(db, "employees", docSnap.id), newData);
    countUpdated++;
  });
  
  console.log(`${countUpdated} employees updated with initial leave data.`);
};



export async function updateCustomersCreatedAt() {
  const customersRef = collection(db, "customers");
  const snapshot = await getDocs(customersRef);
  let countUpdated = 0; // To keep track of how many records were updated
  
  snapshot.forEach(async (docSnap) => {
    const customer = docSnap.data();

    // Check if 'createdAt' key already exists to avoid overwriting it
    if ("createdAt" in customer) {
      console.log(`Skipping update for ${customer.email}, already has createdAt.`);
      return; // Skip this iteration if 'createdAt' exists
    }

    // Define the default date for 'createdAt' as 1st January 2024
    const newData = {
      createdAt: new Date('2024-01-01')
    };
    
    await updateDoc(doc(db, "customers", docSnap.id), newData);
    countUpdated++;
  });

  console.log(`${countUpdated} customers updated with default createdAt field.`);
};

// Call the function to execute the updates
// updateCustomersCreatedAt().catch(console.error);


export async function updateCustomerNumbers() {
  const customersRef = collection(db, "customers");
  const sortedCustomers = query(customersRef, orderBy("createdAt", "asc"));
  const snapshot = await getDocs(sortedCustomers);

  let todayCustomers = {};
  console.log('Fetching customers...');

  snapshot.forEach((docSnap, index) => {
    const customer = docSnap.data();
    const customerDate = customer.createdAt ? customer.createdAt.toDate() : new Date('2024-01-01');
    const dateKey = customerDate.toISOString().slice(0, 10); // Using YYYY-MM-DD as the key

    if (!todayCustomers[dateKey]) {
      todayCustomers[dateKey] = [];
    }

    todayCustomers[dateKey].push(docSnap);
  });

  console.log(`Fetched ${snapshot.size} customers. Starting update...`);

  for (const dateKey in todayCustomers) {
    const customerList = todayCustomers[dateKey];
    console.log(`Processing customers for date: ${dateKey}. Total: ${customerList.length}`);

    for (let i = 0; i < customerList.length; i++) {
      const docSnap = customerList[i];
      const customer = docSnap.data();

      const newCustomerNumber = generateCustomerNumber(
        customer.firstName, 
        customer.lastName, 
        customer.customerType || 'Private',  // Default to 'Private' if not set
        i + 1
      );

      try {
        await updateDoc(doc(db, "customers", docSnap.id), { customerNumber: newCustomerNumber });
        console.log(`Updated customer ${customer.firstName} ${customer.lastName} with new number: ${newCustomerNumber}`);
      } catch (error) {
        console.error(`Error updating customer ${customer.firstName} ${customer.lastName}: `, error);
      }
    }
  }

  console.log('Customer number update completed.');
}
