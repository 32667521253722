import React from 'react';

const currentYear = 2024;

const years = Array.from({length: currentYear - 2000 + 1}, (_, i) => 2000 + i);


export function YearSelect({ onChange, value }) {
  return (
      <select 
          name="year"
          onChange={onChange} 
          value={value}
          className="block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
          <option value="">Select Year</option>
          {years.map(year => (
              <option key={year} value={year}>
                  {year}
              </option>  
          ))}
      </select>
  );
}

  