import React, { useState, useEffect } from 'react';
import { updateDoc, doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { getAuth } from 'firebase/auth';

const ManagerLeaveApproval = ({ pendingRequests }) => {
  const [comments, setComments] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [loading, setLoading] = useState(false); // State to manage the loader
  const navigate = useNavigate(); // To handle redirection
  const auth = getAuth();
  const user = auth.currentUser; // Get the current logged-in manager

  useEffect(() => {
    const fetchEmployeeInfo = async () => {
      const newEmployeeInfo = {};

      for (const request of pendingRequests) {
        // Fetch employee info
        const employeeDoc = await getDoc(doc(db, 'employees', request.employeeId));
        if (employeeDoc.exists()) {
          const employeeData = employeeDoc.data();
          newEmployeeInfo[request.employeeId] = {
            name: `${employeeData.firstName} ${employeeData.lastName}`,
            annualLeaveRemaining: employeeData.annualLeaveRemaining,
            casualLeaveRemaining: employeeData.casualLeaveRemaining,
            annualLeaveTaken: employeeData.annualLeaveTaken,
            casualLeaveTaken: employeeData.casualLeaveTaken,
          };
        }

        // Fetch temporary designate info
        const designateDoc = await getDoc(doc(db, 'employees', request.tempDesignateId));
        if (designateDoc.exists()) {
          const designateData = designateDoc.data();
          newEmployeeInfo[request.employeeId] = {
            ...newEmployeeInfo[request.employeeId],
            tempDesignateName: `${designateData.firstName} ${designateData.lastName}`,
          };
        }
      }

      setEmployeeInfo(newEmployeeInfo);
    };

    fetchEmployeeInfo();
  }, [pendingRequests]);

  const handleApprove = async (id) => {
    setLoading(true); // Show loader
    try {
      // Fetch the leave request details
      const requestDocRef = doc(db, 'leaveRequests', id);
      const requestDocSnap = await getDoc(requestDocRef);

      if (requestDocSnap.exists()) {
        const requestData = requestDocSnap.data();

        // Calculate the number of approved leave days (excluding weekends)
        const approvedDays = requestData.leaveDates.length;

        // Fetch the employee's current leave balances
        const employeeDocRef = doc(db, 'employees', requestData.employeeId);
        const employeeDocSnap = await getDoc(employeeDocRef);

        if (employeeDocSnap.exists()) {
          const employeeData = employeeDocSnap.data();

          let updatedLeaveRemaining, updatedLeaveTaken;

          if (requestData.leaveType === 'Annual Leave') {
            updatedLeaveRemaining = employeeData.annualLeaveRemaining - approvedDays;
            updatedLeaveTaken = employeeData.annualLeaveTaken + approvedDays;

            // Update the employee's annual leave balance
            await updateDoc(employeeDocRef, {
              annualLeaveRemaining: updatedLeaveRemaining,
              annualLeaveTaken: updatedLeaveTaken,
            });
          } else if (requestData.leaveType === 'Casual Leave') {
            updatedLeaveRemaining = employeeData.casualLeaveRemaining - approvedDays;
            updatedLeaveTaken = employeeData.casualLeaveTaken + approvedDays;

            // Update the employee's casual leave balance
            await updateDoc(employeeDocRef, {
              casualLeaveRemaining: updatedLeaveRemaining,
              casualLeaveTaken: updatedLeaveTaken,
            });
          }

          // Update the leave request status to 'Approved', including manager ID and approval date
          await updateDoc(requestDocRef, {
            status: 'Approved',
            managerComment: comments[id] || '',
            approvedBy: user.uid,
            approvalDate: Timestamp.now(),
          });

          toast.success('Leave request approved successfully');
          setTimeout(() => {
            navigate('/profile'); // Redirect to the profile page
          }, 500); // Add a slight delay to ensure the toast message is seen before redirecting
        }
      }
    } catch (error) {
      toast.error('Failed to approve leave request: ' + error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleReject = async (id) => {
    setLoading(true); // Show loader
    try {
      const requestDocRef = doc(db, 'leaveRequests', id);
      await updateDoc(requestDocRef, {
        status: 'Rejected',
        managerComment: comments[id] || '',
        rejectedBy: user.uid,
        rejectionDate: Timestamp.now(),
      });
      toast.success('Leave request rejected successfully');
      setTimeout(() => {
        navigate('/profile'); // Redirect to the profile page
      }, 500); // Add a slight delay to ensure the toast message is seen before redirecting
    } catch (error) {
      toast.error('Failed to reject leave request: ' + error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCommentChange = (id, value) => {
    setComments((prev) => ({ ...prev, [id]: value }));
  };

  if (pendingRequests.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <p>No pending leave requests</p>
      </div>
    );
  }

  return (
    <div className="p-2 bg-white rounded-md shadow-md">
      <h2 className="text-lg mb-2 font-medium text-gray-900">
        Pending Leave Requests for Approval
      </h2>

      {loading && (
        <div className="flex justify-center items-center mb-4">
          <MoonLoader size={30} color={"#4A90E2"} />
        </div>
      )}

      <ul className="space-y-2">
        {pendingRequests.map((request) => (
          <li key={request.id} className="p-2 bg-gray-100 rounded-md">
            <p>
              <strong>Employee:</strong>{' '}
              {employeeInfo[request.employeeId]?.name || 'Loading...'}
            </p>
            <p>
              <strong>Type:</strong> {request.leaveType}
            </p>
            <p>
              <strong>Dates:</strong>
            </p>
            <ul className="list-disc ml-6">
              {request.leaveDates &&
                request.leaveDates.map((date, index) => (
                  <li key={`${request.id}-${index}`}>
                    {new Date(date.seconds * 1000).toLocaleDateString()}
                  </li>
                ))}
            </ul>
            <p>
              <strong>Temporary Designate:</strong>{' '}
              {employeeInfo[request.employeeId]?.tempDesignateName || 'Loading...'}
            </p>
            {request.leaveType === 'Annual Leave' ? (
              <>
                <p>
                  <strong>Annual Leave Remaining:</strong>{' '}
                  {employeeInfo[request.employeeId]?.annualLeaveRemaining ||
                    'Loading...'}
                </p>
                <p>
                  <strong>Annual Leave Taken:</strong>{' '}
                  {employeeInfo[request.employeeId]?.annualLeaveTaken ||
                    'Loading...'}
                </p>
              </>
            ) : request.leaveType === 'Casual Leave' ? (
              <>
                <p>
                  <strong>Casual Leave Remaining:</strong>{' '}
                  {employeeInfo[request.employeeId]?.casualLeaveRemaining ||
                    'Loading...'}
                </p>
                <p>
                  <strong>Casual Leave Taken:</strong>{' '}
                  {employeeInfo[request.employeeId]?.casualLeaveTaken ||
                    'Loading...'}
                </p>
              </>
            ) : null}
            <textarea
              value={comments[request.id] || ''}
              onChange={(e) => handleCommentChange(request.id, e.target.value)}
              placeholder="Add your comments"
              className="mt-2 w-full p-2 border border-gray-300 rounded-md"
            />
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => handleApprove(request.id)}
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                disabled={loading} // Disable button during loading
              >
                Approve
              </button>
              <button
                onClick={() => handleReject(request.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                disabled={loading} // Disable button during loading
              >
                Reject
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagerLeaveApproval;
