import React from 'react';

const statusStages = [
  'Quotation Raised',
  'Approved',
  'Repair Assigned',
  'Repair Concluded',
  'Delivered'
];

export default function StatusBar({ jobCard }) {
  let currentStatusIndex = 0;

  // Check approval status
  if (jobCard?.approvalStatus === 'Yes') {
    currentStatusIndex = 1; // Move to "Approved" stage
  }

  // Check the tracker array to find the latest state
  if (jobCard?.tracker && jobCard?.tracker.length > 0) {
    // Check if there is a "Task Completed" state in the tracker
    const taskCompletedIndex = jobCard.tracker.findIndex(
      (tracker) => tracker.State === 'Task Completed'
    );

    if (taskCompletedIndex !== -1) {
      // Set index to "Repair Concluded" stage if "Task Completed" is found
      currentStatusIndex = Math.max(currentStatusIndex, 3); // "Repair Concluded" is at index 3
    } else {
      // Check if there is a "Task Assigned" state in the tracker
      const taskAssignedIndex = jobCard.tracker.findIndex(
        (tracker) => tracker.State === 'Task Assigned'
      );

      if (taskAssignedIndex !== -1) {
        // Set index to the "Repair Assigned" stage if "Task Assigned" is found
        currentStatusIndex = Math.max(currentStatusIndex, 2); // "Repair Assigned" is at index 2
      }
    }

    // Find the last state in the tracker and check if it's further along
    const lastTrackerState = jobCard.tracker[jobCard.tracker.length - 1].State;
    const trackerIndex = statusStages.findIndex(stage => stage === lastTrackerState);

    // Update the current status index if the tracker state is further along
    if (trackerIndex > currentStatusIndex) {
      currentStatusIndex = trackerIndex;
    }
  }

  // Check if both deliveryDate and exitMileage exist, then update status to "Delivered"
  if (jobCard?.deliveryDate && jobCard?.exitMileage) {
    currentStatusIndex = Math.max(currentStatusIndex, 4); // "Delivered" is at index 4
  }

  const progressPercentage = ((currentStatusIndex + 1) / statusStages.length) * 100;

  return (
    <div className="shadow p-4 mb-2">
      <h4 className="text-sm font-semibold text-gray-900">Job Card Status: {statusStages[currentStatusIndex]}</h4>
      <div aria-hidden="true" className="mt-4">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            style={{ width: `${progressPercentage}%` }}
            className="h-2 rounded-full bg-indigo-600 transition-all duration-300"
          />
        </div>
        <div className="mt-4 grid grid-cols-5 text-sm font-medium text-gray-600">
          {statusStages.map((stage, index) => (
            <div
              key={stage}
              className={`text-center ${index <= currentStatusIndex ? 'text-indigo-600' : ''}`}
            >
              {stage}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
