import React, { useEffect, useState, Fragment } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import JobDetailsModal from './JobDetailsModal';
import { MoonLoader } from 'react-spinners';
import BreadCrumb from '../BreadCrumb';
import Pagination from './Pagination';


const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
    { name: 'Manage Vehicles', to: '/vehicles/manage', component: Link, current: false },
    { name: 'Diagnostics History', to: '/vehicles/diagnosisHistory', component: Link, current: true },  
  ];

const DiagnosisHistory = () => {
    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();
     const [selectedJob, setSelectedJob] = useState(null);
     const [isModalOpen, setIsModalOpen] = useState(false);
    const [vehicle, setVehicle] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const vehicleId = location.state?.vehicleId;
    const [currentPageDiagnostic, setCurrentPageDiagnostic] = useState(0);
    const [currentPageJobs, setCurrentPageJobs] = useState(0);
    const [pageSize] = useState(5);
   

    useEffect(() => {
        if (!vehicleId) {
            console.error("Vehicle ID is undefined.");
            setIsLoading(false);
            return;
        }

        const fetchVehicleData = async () => {
            setIsLoading(true);
            try {
                const vehicleRef = doc(db, 'vehicles', vehicleId);
                const vehicleSnap = await getDoc(vehicleRef);
                if (vehicleSnap.exists()) {
                    setVehicle(vehicleSnap.data());
                } else {
                    console.log('Vehicle not found');
                }

                const q = query(collection(db, 'jobs'), where('vehicleId', '==', vehicleId));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const jobsData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        jobCardNo: doc.data().jobCardNo,
                        status: doc.data().status,
                        approvalStatus: doc.data().approvalStatus,
                        technicianObservation: doc?.data().technicianObservation,
                        vehicleMake: doc.data().vehicleMake,
                        vehicleModel: doc?.data().vehicleModel,
                        vehicleYear: doc?.data().vehicleYear,
                        customerName: doc?.data().customerName,
                        customerEmail: doc?.data().customerEmail,
                        registrationNumber: doc?.data().registrationNumber,
                        workRequired: doc?.data().workRequired,
                        servicesRequired: doc?.data().servicesRequired,
                        partsRequired: doc?.data().partsRequired,
                        inspectionChecklist: doc?.data().inspectionChecklist,
                        entryMileage: doc?.data().entryMileage,
                        ownerRequest: doc.data().ownerRequest,
                        markedItemComments: doc.data().markedItemComments,
                        date: doc.data().createdAt && doc.data().createdAt.toDate ? doc.data().createdAt.toDate() : null,
                    }));
                    setJobs(jobsData);
                    setIsLoading(false);
                });

                return () => unsubscribe(); 
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Failed to fetch data.');
                setIsLoading(false);
            }
        };

        fetchVehicleData();
    }, [vehicleId]);

    const openUpdateJobModal = (jobId) => {
        navigate(`/vehicles/updateJob/${jobId}`);
    };

    const diagnosticJobs = jobs.filter(job => job.approvalStatus === 'No');
    const jobCards = jobs.filter(job => job.approvalStatus === 'Yes');

    const renderTable = (data, currentPage, setCurrentPage, total, title, showStatusColumn = true) => {
        return (
            <>
                <h2 className="uppercase text-sm font-semibold mt-2 mb-2 text-gray-600">{title}</h2>
                <div className="mx-auto p-4 bg-white rounded-lg shadow">
                    <table className="w-full border-collapse">
                        <thead>
                            <tr className="text-left bg-gray-100 text-sm">
                                <th className="px-4 py-2 border-b border-gray-200 text-gray-600 font-medium uppercase">Job Card No</th>
                                <th className="px-4 py-2 border-b border-gray-200 text-gray-600 font-medium uppercase">Customer Complaint</th>
                                {showStatusColumn && <th className="px-4 py-2 border-b border-gray-200 text-gray-600 font-medium uppercase">Job Card Status</th>}
                                <th className="px-4 py-2 border-b border-gray-200 text-gray-600 font-medium uppercase">Date</th>
                                <th className="px-4 py-2 border-b border-gray-200 text-gray-600 font-medium uppercase">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(job => (
                                <tr key={job.id} className="hover:bg-gray-50 text-sm">
                                    <td className="px-4 py-1 border-b border-gray-200">{job.jobCardNo}</td>
                                    <td className="px-4 py-1 border-b border-gray-200">{job.ownerRequest}</td>
                                    {showStatusColumn && <td className="px-4 py-1 border-b border-gray-200">{job.status}</td>}
                                    <td className="px-4 py-1 border-b border-gray-200">{job.date ? job.date.toLocaleDateString() : 'No Date Provided'}</td>
                                    <td className="px-4 py-1 border-b border-gray-200">
                                        <div className="relative inline-block text-left">
                                            <Menu as="div">
                                                <Menu.Button className="inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                    Actions
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items className="absolute z-10 mt-2 w-48 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="p-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => viewDetails(job.id)}
                                                                        className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                    >
                                                                        View Details
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                            {title === "Diagnostic Questionnaire" ? (
                                                                <>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => console.log('Update DQ')}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Update DQ
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => console.log('Delete DQ')}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Delete DQ
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                </>
                                                            ) : (
                                                                <>
                                                                <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                            onClick={() => openUpdateJobModal(job.id)}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Update Job
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => console.log('Close Job')}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Close Job
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => console.log('Delete Job')}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Delete Job
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => console.log('Re-Open Job')}
                                                                                className={`${active ? 'bg-gray-100' : ''} flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900`}
                                                                            >
                                                                                Re-Open Job
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination currentPage={currentPage} onPageChange={setCurrentPage} totalPages={Math.ceil(total / pageSize)} />
                </div>
            </>
        );
    };
    
    const viewDetails = (jobId) => {
        const job = jobs.find(j => j.id === jobId);
        setSelectedJob(job);
        setIsModalOpen(true);
    };

   
    return (
        <>
            <ToastContainer />
            <BreadCrumb pages={pages} />
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">Diagnosis History for {vehicle.vehicleMake || ''} {vehicle.vehicleModel || ''} - {vehicle.registrationNumber || ''}</h1>
              
              <div>  <button onClick={() => navigate('/vehicles/newDQ', { state: { vehicle, vehicleId } })} className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
                    Create DQ
                </button></div>
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <MoonLoader size={50} color="#0000ff" />
                    </div>
                ) : (
                    <>
                        {renderTable(diagnosticJobs, currentPageDiagnostic, setCurrentPageDiagnostic, diagnosticJobs.length, "Diagnostic Questionnaire", false)}
                        {renderTable(jobCards, currentPageJobs, setCurrentPageJobs, jobCards.length, "Job Cards", true)}
                    </>
                )}
            </div>
            <JobDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} job={selectedJob} />                       

        </>
    );
};

export default DiagnosisHistory;