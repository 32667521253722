// JobCardDropdown.js
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; // adjust the path as necessary

const JobCardDropdown = ({ onJobCardSelect }) => {
  const [jobCards, setJobCards] = useState([]);

  useEffect(() => {
    const fetchJobCards = async () => {
      const querySnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          value: data.jobCardNo,
          label: `${data.jobCardNo} - ${data.registrationNumber}`,
          vehicleId: data.vehicleId,
          vehicleMake: data.vehicleMake,
          vehicleModel: data.vehicleModel,
          registrationNumber: data.registrationNumber,
        };
      });
      setJobCards(jobsData);
    };

    fetchJobCards();
  }, []);

  const handleChange = (selectedOption) => {
    if (onJobCardSelect && selectedOption) {
      onJobCardSelect(selectedOption);
    }
  };

  return <Select options={jobCards} 
  onChange={handleChange}
  className="form-select text-xs rounded border-gray-300 w-1/2 p-2 mt-4 mb-2" 
   />;
};

export default JobCardDropdown;
