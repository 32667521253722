import React from 'react';

const ServiceMargins = ({ settings, handleEdit, confirmDelete }) => {
  return (
    <div className="mb-4">
          <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Description</th>
              <th className="px-4 py-2 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Margin</th>
              <th className="px-4 py-2 border-b-2 border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {settings.services.map(service => (
              <tr key={service.id} className="hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-200">{service.description}</td>
                <td className="px-4 py-2 border-b border-gray-200">{service.margin}%</td>
                <td className="px-4 py-2 border-b border-gray-200 text-sm text-gray-500">
                  <button onClick={() => handleEdit(service)} className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">Edit</button>
                  <button onClick={() => confirmDelete(service.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceMargins;
