import React from 'react';
import { banks } from './banks';

const EditModal = ({ supplier, onChange, onSubmit, onClose }) => {

  const handleBankDetailsChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...supplier.bankDetails];
    list[index][name] = value;
    onChange({
      target: {
        name: 'bankDetails',
        value: list,
      },
    });
  };

  const handleDeleteBankDetail = (indexToDelete) => {
    const updatedBankDetails = supplier.bankDetails.filter((_, index) => index !== indexToDelete);
    onChange({
      target: {
        name: 'bankDetails',
        value: updatedBankDetails,
      },
    });
  };

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="grid grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
                  Company Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="companyName"
                  name="companyName"
                  value={supplier.companyName}
                  onChange={onChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  name="firstName"
                  value={supplier.firstName}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  name="lastName"
                  value={supplier.lastName}
                  onChange={onChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address"
                  name="address"
                  value={supplier.address}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  type="email"
                  value={supplier.email}
                  onChange={onChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumbers">
                  Phone Numbers
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phoneNumbers"
                  name="phoneNumbers"
                  value={supplier.phoneNumbers}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productSold">
                  Product Sold
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="productSold"
                  name="productSold"
                  value={supplier.productSold}
                  onChange={onChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentTerms">
                  Payment Terms
                </label>
                <select
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  id="paymentTerms"
                  name="paymentTerms"
                  value={supplier.paymentTerms}
                  onChange={onChange}
                >
                  <option value="">Select Vendor Payment Terms</option>
                  <option value="Upfront Payment">Upfront Payment</option>
                  <option value="Part Payment">Part Payment</option>
                  <option value="Payment Post-Delivery">Payment Post-Delivery</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="warrantyAvailable">
                Warranty Available
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="warrantyAvailable"
                name="warrantyAvailable"
                type="checkbox"
                checked={supplier.warrantyAvailable}
                onChange={onChange}
              />
            </div>
            <div className="grid grid-cols-4 gap-6">
              {supplier.bankDetails.map((bankDetail, index) => (
                <React.Fragment key={index}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`bankName${index}`}>
                      Bank Name
                    </label>
                    <select
                      className="shadow appearance-none border rounded w-full text-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={`bankName${index}`}
                      name="bankName"
                      value={bankDetail.bankName}
                      onChange={(event) => handleBankDetailsChange(index, event)}
                    >
                      {banks.map((bank) => (
                        <option key={bank} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`accountName${index}`}>
                      Account Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded text-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={`accountName${index}`}
                      name="accountName"
                      value={bankDetail.accountName}
                      onChange={(event) => handleBankDetailsChange(index, event)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`accountNumber${index}`}>
                      Account Number
                    </label>
                    <input
                      className="shadow appearance-none border rounded text-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={`accountNumber${index}`}
                      name="accountNumber"
                      value={bankDetail.accountNumber}
                      onChange={(event) => handleBankDetailsChange(index, event)}
                    />
                  </div>
                  <div className="mb-4">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white text-sm font-bold mt-6 py-2 px-4 rounded"
                      onClick={() => handleDeleteBankDetail(index)}
                    >
                      Delete
                    </button>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
