import React, { useEffect, useState, Fragment } from 'react';
import { doc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatCurrency } from '../Help/helper';
import { Dialog, Transition } from '@headlessui/react';
import { ToastContainer, toast } from 'react-toastify';

const RequisitionDetails = ({ procurementId, onClose }) => {
  const [procurement, setProcurement] = useState(null);
  const [approvalComment, setApprovalComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProcurement = async () => {
      if (!procurementId) {
        console.error('No procurement ID provided');
        return;
      }

      try {
        const docRef = doc(db, 'procurements', procurementId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProcurement({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        toast.error('Error fetching procurement details');
      }
    };

    fetchProcurement();
  }, [procurementId]);

  if (!procurement) {
    return <div>Loading procurement details...</div>;
  }

  const handleApprovalChange = async (newStatus) => {
    const procurementRef = doc(db, 'procurements', procurementId);
    const newFields = {
      status: newStatus,
      approvalComment: approvalComment,
      statusUpdateDate: new Date(),
    };

    try {
      setLoading(true);
      await updateDoc(procurementRef, newFields);

      if (newStatus === 'Approved') {
        // Optionally add the procurement to the payables collection if approved
        await addDoc(collection(db, 'payables'), {
          ...procurement,
          ...newFields,
        });
      }

      toast.success(`Procurement ${newStatus.toLowerCase()} successfully.`);
      setProcurement((prev) => ({ ...prev, ...newFields }));
      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Failed to update the procurement.');
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer />
    <Transition.Root show={true} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
      <div className="flex items-center justify-center min-h-screen px-4 py-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full max-w-4xl p-6">
            <div>
              <h3 className="text-lg font-semibold leading-7 text-gray-900">
                Procurement Details - {procurement.procurementNumber}
              </h3>
              <div className="mt-1">
               
                <div className="mt-2">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Quantity
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {procurement.cartItems.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                            {item.description}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                            {item.quantity}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                            {formatCurrency(item.price)}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                            {formatCurrency(item.price * item.quantity)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
  
              {/* Approval Section */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Approval Comment
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    rows="2"
                    value={approvalComment}
                    onChange={(e) => setApprovalComment(e.target.value)}
                    placeholder="Add a comment for approval/rejection"
                  />
                </label>
  
                <div className="mt-5 sm:mt-6 flex justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center w-1/2 mr-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    onClick={() => handleApprovalChange('Approved')}
                    disabled={loading}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-1/2 ml-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                    onClick={() => handleApprovalChange('Rejected')}
                    disabled={loading}
                  >
                    Reject
                  </button>
                </div>
              </div>
  
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
  </>
  );
};

export default RequisitionDetails;
