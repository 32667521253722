import React, { useEffect, useState, useRef } from 'react';
import { Chart, ArcElement, CategoryScale, DoughnutController } from 'chart.js';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase';
import { useMediaQuery } from 'react-responsive';
import {printPDF} from './helpers/pdfUtils';
import CommentModal from './helpers/CommentModal';
import RecommendationModal from './helpers/RecommendationModal';
import './VehicleInspectionReport.css';
import Ayden from '../../images/ayden.jpg';
import PrintFriendlyHeader from './helpers/PrintFriendlyHeader'
import PrintFriendlyEngineerSection from './helpers/PrintFriendlyEngineerSection';
import PrintFriendlyImageGallery from './helpers/PrintFriendlyImageGallery';
import PrintFriendlyComponentDetails from './helpers/PrintFriendlyComponentDetails';
import PieChartSummary from './helpers/PieChartSummary';
import ComponentDetails from './helpers/ComponentDetails';
import StatusSummary from './helpers/StatusSummary';
import InspectionTable from './helpers/InspectionTable';



Chart.register(ArcElement, CategoryScale, DoughnutController);


const VehicleInspectionReport = () => {
  const location = useLocation();
  const vehicle = location.state?.vehicle;
  const [inspectionData, setInspectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const [counts, setCounts] = useState({ Ok: 0, Defective: 0, Service: 0 });
  const printRef = useRef(null);
  const [engineerComments, setEngineerComments] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
   const [printerFriendly, setPrinterFriendly] = useState(false);

  

  useEffect(() => {
    if (vehicle) {
      const fetchInspectionData = async () => {
        try {
          setLoading(true);
          const docRef = doc(db, "inspections", vehicle.registrationNumber);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            const data = docSnap.data();
            setInspectionData(data);
            setEngineerComments(data.engineerComments || '');
            setRecommendations(data.recommendations || '');
            // Calculate counts here
            const newCounts = { Ok: 0, Defective: 0, Service: 0 };
            Object.values(data.inspection).forEach(value => {
              if (newCounts.hasOwnProperty(value)) {
                newCounts[value]++;
              }
            });
            setCounts(newCounts);
            setLoading(false);
          } else {
            throw new Error("No such document!");
          }
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
  
      fetchInspectionData();
    }
  }, [vehicle]);


  const data = {
    labels: ['Ok', 'Defective', 'Service'],
    datasets: [
      {
        data: [counts.Ok, counts.Defective, counts.Service],
        backgroundColor: ['green', 'red', 'yellow'],
      },
    ],
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!inspectionData || !inspectionData.inspection) {
    return <div>No Inspection Data Found</div>;
  }
 

    
  const handleCommentSubmit = async (comment) => {
    setEngineerComments(comment);
    // Implement Firestore save logic
    const docRef = doc(db, "inspections", vehicle.registrationNumber);
    await updateDoc(docRef, { engineerComments: comment });
  };
  
  const handleRecommendationSubmit = async (recommendation) => {
    setRecommendations(recommendation);
    // Implement Firestore save logic
    const docRef = doc(db, "inspections", vehicle.registrationNumber);
    await updateDoc(docRef, { recommendations: recommendation });
  };
  

  if (printerFriendly) {
    return (
      <div className="print-friendly-container">
        <PrintFriendlyHeader data={inspectionData} />
        <PrintFriendlyEngineerSection comments={engineerComments} recommendations={recommendations} />
        <PrintFriendlyImageGallery inspectionData={inspectionData} />
        <PrintFriendlyComponentDetails components={inspectionData.inspection} />
        </div>
    );
  }


  return (
  
    <div className={isMobile ? "p-4" : "p-8"}>
       
      <button onClick={() => printPDF(printRef, isMobile)} 
      className="print-version-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
      Print PDF
    </button>

    <button 
  onClick={() => setPrinterFriendly(!printerFriendly)} 
  className="print-friendly-toggle-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
>
  {printerFriendly ? "Exit Printer Friendly" : "Printer Friendly Version"}
</button>


      <div className="report-container" ref={printRef}>
    
      <section className="report-header text-center flex flex-col justify-between h-screen p-4 a4-height">        
          {/* Report Title and Details */}
          <div className="flex flex-col items-center justify-center flex-grow">
            <img className="mx-auto mb-8" src={Ayden} alt="Ayden Garage" style={{ maxWidth: "200px" }} />
            <h1 className='text-3xl font-extrabold mb-4'>Vehicle Inspection Report</h1>
            <p className='font-semibold mb-2'>Inspection Date: <span className='font-normal'>{inspectionData.inspectionDate.toDate().toLocaleDateString()}</span></p>
            <p className='font-semibold mb-2'>Vehicle: <span className='font-normal'>{inspectionData.vehicleMake} {inspectionData.vehicleModel}</span></p>
            <p className='font-semibold'>Registration Number: <span className='font-normal'>{inspectionData.vehicleRegistrationNumber}</span></p>
          </div>

          {/* Company Address */}
          <div className='text-xs font-light pb-8'>
            <p>Block 7, Plot 7, Etal Avenue,</p>
            <p>Off Kudirat Abiola Way, Oregun,</p>
            <p>Lagos, Nigeria</p>
          </div>   
        </section>

        <section className='report-details print:h-a4 a4-size p-6'>
        <div className="p-4">
              <h2 className="text-lg font-bold mb-4 mt-4">INTRODUCTION</h2>
              <p className="mb-2 text-gray-600 text-sm">
                Welcome to AYDENS Garage's comprehensive Vehicle Inspection Report. This document is a testament to our commitment to ensuring the utmost safety, efficiency, and performance of your vehicle.
                 Through our detailed inspection process, we aim to provide not only a thorough assessment of your vehicle's condition but also peace of mind for you as a driver.
              </p>
              <p className="mb-2 text-gray-600 text-sm">
                In this report, we have meticulously examined various critical components of your vehicle. These components include:
              </p>
                  <ul className="list-none list-inside mb-4 text-gray-600 text-sm text-justify leading-normal ">
                    <li><span className='font-bold'>AC & Cooling System:</span> Ensuring your comfort and the efficient operation of the vehicle's temperature regulation system.</li>
                    <li><span className='font-bold'>Brake Inspection:</span> Checking the brakes for wear and tear, ensuring your safety on the road.</li>
                    <li><span className='font-bold'>Interior & Amenities:</span> Assessing the condition of the interior, including seating, dashboard, and other amenities for your comfort and convenience.</li>
                    <li><span className='font-bold'>Electronics & Drive System:</span> Evaluating the electronic systems, including the drive mechanisms, for optimal performance and reliability.</li>
                    <li><span className='font-bold'>Exterior & Body: </span> Inspecting the vehicle’s exterior and bodywork for any damage or issues that could affect its aesthetics and structural integrity.</li>
                    <li><span className='font-bold'>Suspension System: </span> Examining the suspension components for a smooth and stable ride.</li>
                    <li><span className='font-bold'>Transmission: </span> Checking the transmission system for smooth gear shifting and overall reliability.</li>
                    <li><span className='font-bold'>Exhaust System: </span> Assessing the exhaust system to ensure it is functioning correctly and not causing environmental harm.</li>
                    <li><span className='font-bold'>Road Test: </span> Conducting a road test to evaluate the overall performance and handling of the vehicle in real-world driving conditions.</li>
                  </ul>
              <p className="mb-2 mt-2 text-gray-600 text-sm">
                The purpose of this inspection is not only to identify any current issues but also to preemptively address potential future problems. 
                This proactive approach helps in maintaining the longevity and performance of your vehicle, ensuring it remains a reliable companion on the road.
                 Furthermore, regular inspections can significantly enhance your vehicle's resale value and reduce the likelihood of costly repairs down the line.
              </p>
              <br/>
              <p className='text-gray-600 text-sm pb-22'>
                We trust that this report will provide you with valuable insights into your vehicle's condition and guide you in its proper maintenance. Thank you for choosing AYDENS Garage for your vehicle inspection needs.
              </p>
            </div>
            <br/> <br/> <br/> <br/>
            
           
        </section>

        <section className="report-details flex flex-col mt-24 py-4 items-center justify-center h-screen p-6 mb-24 a4-size a4-height">
            <h2 className="text-2xl font-semibold mb-8 pt-24 mt-20">Vehicle Health Status Summary</h2>
            <div className="flex flex-col items-center w-full pt-6">
              <PieChartSummary data={data} />
              <StatusSummary counts={counts} />
              <InspectionTable inspectionData={inspectionData.inspection} />           
            </div>
             
          </section>
     

          <section className="engineer-section a4-height p-6 bg-white mt-14 mb-6 pb-10 print:flex print:flex-col print:justify-between a4-size">
                  <h2 className="text-2xl font-semibold mb-8">Comments and Recommendations</h2>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 print:gap-12">
                        <div className="mb-6 md:mb-0">
                          <h3 className="text-lg font-semibold mb-2 bg-gray-200 text-gray-600 p-2">Engineer Comments</h3>
                          <div className="border p-4 rounded text-sm min-h-[10rem]">
                            {engineerComments ? (
                              <p className="text-gray-600">{engineerComments}</p>
                            ) : (
                              <p className="text-gray-400 italic">No comments provided.</p>
                            )}
                          </div>
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold mb-2 bg-gray-200 text-gray-600 p-2">Recommendations</h3>
                          <div className="border p-4 rounded text-sm min-h-[10rem]">
                            {recommendations ? (
                              <p className="text-gray-600">{recommendations}</p>
                            ) : (
                              <p className="text-gray-400 italic">No recommendations provided.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    
                        {/* Print-only button */}
                        <div className="print:hidden">
                          <button 
                            onClick={() => setShowCommentModal(true)}
                            className="text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded mr-4"
                          >
                            Add Comment
                          </button>
                          <button 
                            onClick={() => setShowRecommendationModal(true)}
                            className="text-white bg-green-500 hover:bg-green-600 font-medium py-2 px-4 rounded"
                          >
                            Add Recommendation
                          </button>
                        </div>
                  </section>


              <section className="image-gallery-section a4-height mt-20 bg-white shadow-md rounded-lg p-4 mb-6 print:h-a4">
                <h2 className="text-xl font-semibold mb-4 border-b pb-2">Inspection Images</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {inspectionData && inspectionData.inspection && Object.entries({
                    acImageUrls: "AC and Cooling Inspection",
                    chasisImageUrls: "Brake Inspection",
                    electronicsdrive_ImageUrls: "Drive and Electronics System",
                    exhaustImageUrls: "Exhaust Inspection",
                    exteriorbodyImageUrls: "Exterior and Body Inspection",
                    interiorImageUrls: "Interior Amenities",
                    roadtestImageUrls: "Road Test",
                    suspensionImageUrls: "Suspension Inspection",
                    transmissionImageUrls: "Transmission Inspection",
                  }).map(([key, caption]) => (
                    inspectionData.inspection[key] && inspectionData.inspection[key].length > 0 && (
                      inspectionData.inspection[key].map((imageUrl, index) => (
                        <div key={`${key}-${index}`} className="image-container text-center">
                        <img src={imageUrl} alt={caption} className="image-gallery-img" />
                        <p className="image-caption">{caption}</p>
                      </div>
                      
                      ))
                    )
                  ))}
              </div>
                </section>
            
                
                <section className="report-details print:h-a4 a4-size">
                <ComponentDetails 
                  components={inspectionData.inspection} 
                                 
                />
                 </section>
      
    
      </div>

      <CommentModal
        isOpen={showCommentModal}
        onClose={() => setShowCommentModal(false)}
        onSubmit={handleCommentSubmit}
      />

      <RecommendationModal
        isOpen={showRecommendationModal}
        onClose={() => setShowRecommendationModal(false)}
        onSubmit={handleRecommendationSubmit}
      />
    </div>
  );
};


export default VehicleInspectionReport;