import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';

const FeedbackDetails = ({ isOpen, onRequestClose, feedback, customerName }) => {
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [employeeName, setEmployeeName] = useState('Loading...');
    const [isUpdating, setIsUpdating] = useState(false);
    const [managerComments, setManagerComments] = useState('');
    const [customerCommunication, setCustomerCommunication] = useState('');
    const [status, setStatus] = useState(feedback.status);

    useEffect(() => {
        const fetchVehicleDetails = async () => {
            if (feedback.vehicleId) {
                const vehicleRef = doc(db, 'vehicles', feedback.vehicleId);
                const vehicleSnap = await getDoc(vehicleRef);

                if (vehicleSnap.exists()) {
                    setVehicleDetails(vehicleSnap.data());
                } else {
                    console.log("No such vehicle!");
                }
            }
        };

        fetchVehicleDetails();
    }, [feedback.vehicleId]);

    useEffect(() => {
        const fetchEmployeeName = async () => {
            if (feedback.employeeId) {
                const employeeRef = doc(db, 'employees', feedback.employeeId);
                const employeeSnap = await getDoc(employeeRef);

                if (employeeSnap.exists()) {
                    const employeeData = employeeSnap.data();
                    setEmployeeName(`${employeeData.firstName || ''} ${employeeData.lastName || ''}`);
                } else {
                    setEmployeeName('Unknown');
                    console.log("No such employee!");
                }
            } else {
                setEmployeeName('Not Applicable');
            }
        };

        fetchEmployeeName();
    }, [feedback.employeeId]);

    const handleUpdateStatus = async (e) => {
        e.preventDefault();
        if (!feedback.id) {
            console.error("Feedback ID is missing.");
            return;
        }

        const updateData = {
            status: status,
            managerComments: managerComments,
            customerCommunication: customerCommunication,
        };

        if (status === "Resolved") {
            updateData.resolvedDate = new Date();
        }

        try {
            const feedbackRef = doc(db, "feedbacks", feedback.id);
            await updateDoc(feedbackRef, updateData);
            toast.success("Feedback status updated successfully.");
            
        } catch (error) {
            console.error("Error updating feedback status: ", error);
            toast.error("Failed to update feedback status.");
        }
    };

    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onRequestClose} 
            className="overflow-auto p-5 bg-white rounded-lg outline-none" 
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <div className="p-2">
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Feedback Type: <span className={` ${feedback.feedbackType === "Complaint" ? "bg-red-300" : ""}`}>{feedback.feedbackType}</span>
                    </h3>
                    <button 
                        className="ml-4 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={onRequestClose}
                    >
                        Close
                    </button>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 font-semibold">
                        Date Posted: <span className="text-xs"> {feedback.date.toDate().toLocaleString()} </span>
                    </p>
                </div>

                <div>       
                    <div className="mt-1 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customerName}</dd>
                            </div>
                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Vehicle Affected</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {vehicleDetails?.vehicleMake} {vehicleDetails?.vehicleModel} ({vehicleDetails?.year}) - {vehicleDetails?.registrationNumber}
                                </dd>
                            </div>

                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Employee Affected</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {employeeName}
                                </dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Mode of Escalation</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.escalationMode}</dd>
                            </div>
                            <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Nature of Escalation</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.complaintNature}</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Comments</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.feedback}</dd>
                            </div>

                            <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Initial Response to Customer</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.companyFeedback}</dd>
                            </div>

                            {feedback.status === "Resolved" && (
                                <>
                                    <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Manager's Comments</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.managerComments}</dd>
                                    </div>

                                    <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Official Response</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{feedback.customerCommunication}</dd>
                                    </div>

                                    <div className="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Date Resolved</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {feedback.resolvedDate?.toDate().toLocaleString()}
                                        </dd>
                                    </div>
                                </>
                            )}
                        </dl>

                        {feedback.status !== "Resolved" && (
                            <>
                                <div>
                                    <label htmlFor="managerComments" className="block text-sm font-medium text-gray-700">
                                        Manager Comments
                                    </label>
                                    <textarea
                                        id="managerComments"
                                        value={managerComments}
                                        onChange={(e) => setManagerComments(e.target.value)}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                                        placeholder="Enter manager's comments"
                                    />
                                </div>

                                <div className="mt-4">
                                    <label htmlFor="customerCommunication" className="block text-sm font-medium text-gray-700">
                                        Official Response to Customer
                                    </label>
                                    <textarea
                                        id="customerCommunication"
                                        value={customerCommunication}
                                        onChange={(e) => setCustomerCommunication(e.target.value)}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                                        placeholder="Enter response to customer"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                
                {isUpdating ? (
                    <form onSubmit={handleUpdateStatus}>
                        {feedback.status !== "Resolved" && (
                            <div>
                                <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                                    Update Status
                                </label>
                                <select
                                    id="status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                    className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="">Update Status</option>
                                    <option value="Open">Open</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Resolved">Resolved</option>
                                </select>
                            </div>
                        )}
                        <button 
                            type="submit"  
                            className="rounded-md mt-2 bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Submit Update
                        </button>
                    </form>
                ) : (
                    feedback.status !== "Resolved" && (
                        <button 
                            type="button"  
                            className="rounded-md mt-2 bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setIsUpdating(true)}
                        >
                            Update Status
                        </button>
                    )
                )}
            </div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal>
    );
};

export default FeedbackDetails;
