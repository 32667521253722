// Customer.jsx
import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  where,
  query,
  updateDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { CustomerCreationModal } from './CustomerCreationModal';
import CustomerVehiclesModal from './CustomerVehiclesModal';
import EditCustomerModal from './EditCustomerModal';
import { generateCustomerNumber } from './generateCustomerNumber';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import BreadCrumb from '../BreadCrumb';
import SearchBar from './SearchBar';
import CustomerTable from './CustomerTable';

const pages = [
  { name: 'Customer Care', to: '/customers', component: Link, current: false },
  { name: 'Customer Admin', to: '/customers/manage', component: Link, current: true },
];

const initialCustomerFormState = {
  customerNumber: '',
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  phoneNumber: '',
  dateOfBirth: '',
  customerType: '',
};

export const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [agents, setAgents] = useState([]);
  // Removed selectedCustomer and showModal from state
  const [isVehiclesModalOpen, setIsVehiclesModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerVehicles, setCustomerVehicles] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(initialCustomerFormState);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Removed isSuccess from state
  const [isReferred, setIsReferred] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const customersPerPage = 10;
  const pagesVisited = pageNumber * customersPerPage;

  const initialFormState = {
    firstName: '',
    lastName: '',
    customerType: '',
    dateOfBirth: '',
    gender: '',
    phoneNumber: '',
    email: '',
    address: '',
    agentId: '',
    specialNotes: '',
    preferences: [],
  };
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    const fetchAgents = async () => {
      const agentSnapshot = await getDocs(collection(db, 'agents'));
      const agentList = agentSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setAgents(agentList);
    };

    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      const customerSnapshot = await getDocs(collection(db, 'customers'));
      const customerList = customerSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customerList);
      setIsLoading(false);
    };

    fetchCustomers();
  }, []);

  const sortedCustomers = [...customers].sort((a, b) => {
    if (sortConfig.key) {
      const order = sortConfig.direction === 'asc' ? 1 : -1;
      if (
        typeof a[sortConfig.key] === 'string' &&
        typeof b[sortConfig.key] === 'string'
      ) {
        return a[sortConfig.key].localeCompare(b[sortConfig.key]) * order;
      } else {
        return (a[sortConfig.key] < b[sortConfig.key] ? -1 : 1) * order;
      }
    }
    return 0;
  });

  // Filter customers based on searchTerm
  const filteredCustomers = sortedCustomers.filter((customer) =>
    `${customer.firstName} ${customer.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Query to find customers created today to ensure sequential numbering
    const todayQuery = query(
      collection(db, 'customers'),
      where('createdAt', '>=', Timestamp.fromDate(today)),
      where('createdAt', '<', Timestamp.fromDate(tomorrow))
    );

    try {
      const todaySnapshot = await getDocs(todayQuery);
      const customerCountToday = todaySnapshot.size + 1; // Add one for the new customer

      let customerData = {
        ...formState,
        editedAt: Timestamp.now(),
      };

      if (!formState.id) {
        const newCustomerNumber = generateCustomerNumber(
          formState.firstName,
          formState.lastName,
          formState.customerType, // 'I' for individual, 'C' for corporate
          customerCountToday
        );

        customerData = {
          ...customerData,
          customerNumber: newCustomerNumber,
          createdAt: Timestamp.now(),
        };

        delete customerData.id;
        const docRef = await addDoc(collection(db, 'customers'), customerData);
        setCustomers((prevCustomers) => [
          ...prevCustomers,
          { id: docRef.id, ...customerData },
        ]);
        toast.success('Customer created successfully!');
      } else {
        // Update existing customer logic here...
      }

      setFormState(initialFormState);
      setIsModalOpen(false); // Use setIsModalOpen instead of setShowModal
    } catch (error) {
      console.error('Error in customer operation:', error);
      toast.error('An error occurred while processing the customer.');
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingCustomer && editingCustomer.id) {
        // Update existing customer
        const updatedCustomerData = {
          ...editingCustomer,
          editedAt: Timestamp.now(),
        };
        const customerRef = doc(db, 'customers', editingCustomer.id);
        await updateDoc(customerRef, updatedCustomerData);
        toast.success('Customer updated successfully!');

        // Update the customer in the local state
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.id === editingCustomer.id ? updatedCustomerData : customer
          )
        );
      }

      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error('An error occurred while updating the customer.');
    }
  };

  const deleteCustomer = async (customerId) => {
    if (!customerId) {
      toast.error('Invalid customer ID.');
      return;
    }

    try {
      await deleteDoc(doc(db, 'customers', customerId));
      toast.success('Customer deleted successfully!');
      setCustomers((prevCustomers) =>
        prevCustomers.filter((customer) => customer.id !== customerId)
      );
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast.error('Failed to delete customer.');
    }
  };

  const openEditModal = (customer) => {
    setEditingCustomer({ ...customer });
    setIsEditModalOpen(true);
  };

  const handleEditInputChange = (e) => {
    setEditingCustomer((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setIsEditing(false);
    setFormState(initialFormState);
  };

  const handleCloseModal = () => {
    setIsVehiclesModalOpen(false);
  };

  const fetchCustomerVehicles = async (customerId) => {
    try {
      const vehiclesRef = collection(db, 'vehicles');
      const q = query(vehiclesRef, where('customerId', '==', customerId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('No matching vehicles found');
        return [];
      }

      let customerVehicles = [];
      querySnapshot.forEach((doc) => {
        customerVehicles.push({ id: doc.id, ...doc.data() });
      });

      return customerVehicles;
    } catch (error) {
      console.error('Error fetching vehicles: ', error);
      throw error;
    }
  };

  const handleVehiclesButtonClick = async (customer) => {
    setIsLoading(true);
    const vehicles = await fetchCustomerVehicles(customer.id);
    setCustomerVehicles(vehicles);
    setIsLoading(false);
    setIsVehiclesModalOpen(true);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const exportToCSV = () => {
    const csvData = customers.map((customer) => ({
      CustNo: customer?.customerNumber,
      Name: customer.firstName + ' ' + customer.lastName,
      Email: customer.email,
      PhoneNumber: customer.phoneNumber,
      DOB: customer.dateOfBirth,
      Type: customer.customerType,
      Gender: customer.gender,
      Address: customer.address,
    }));

    const csvString = Papa.unparse(csvData, {
      header: true,
    });

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Customers.csv');
  };

  return (
    <>
      <BreadCrumb pages={pages} />

      <div className="flex flex-col">
        <ToastContainer />

        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-4">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <CustomerCreationModal
                showModal={isModalOpen}
                closeModal={closeModal}
                handleSubmit={handleSubmit}
                formState={formState}
                handleInputChange={handleInputChange}
                isEditing={isEditing}
                agents={agents}
                isReferred={isReferred}
                setIsReferred={setIsReferred}
                setFormState={setFormState}
              />

              {/* Search and Filter UI */}
              <div className="flex items-center justify-between mb-4">
                <SearchBar setSearchTerm={setSearchTerm} />

                <div className="flex space-x-4">
                  <button
                    className="bg-blue-600 hover:bg-blue-700 text-sm text-white font-semibold py-2 px-4 rounded focus:outline-none"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Create Customer
                  </button>

                  <button
                    className="bg-green-600 hover:bg-green-700 text-sm text-white font-semibold py-2 px-4 rounded focus:outline-none"
                    onClick={exportToCSV}
                  >
                    Export to CSV
                  </button>
                </div>
              </div>

              <CustomerTable
                customers={filteredCustomers}
                pagesVisited={pagesVisited}
                customersPerPage={customersPerPage}
                handleSort={handleSort}
                sortConfig={sortConfig}
                isLoading={isLoading}
                handleVehiclesButtonClick={handleVehiclesButtonClick}
                openEditModal={openEditModal}
                deleteCustomer={deleteCustomer}
              />
            </div>
          </div>
        </div>

        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={Math.ceil(filteredCustomers.length / customersPerPage)}
          onPageChange={changePage}
          containerClassName={'flex items-center justify-center space-x-2 mt-4'}
          previousLinkClassName={
            'bg-blue-500 hover:bg-blue-700 text-white font-semibold text-sm py-1 px-2 rounded'
          }
          nextLinkClassName={
            'bg-blue-500 hover:bg-blue-700 text-white font-semibold text-sm py-1 px-2 rounded'
          }
          pageClassName={'bg-white hover:bg-gray-200 px-2 py-1 rounded'}
          breakClassName={'px-2 py-1'}
          activeClassName={'bg-blue-700 text-red font-bold py-1 px-2 rounded'}
        />

        <EditCustomerModal
          showModal={isEditModalOpen}
          closeModal={() => setIsEditModalOpen(false)}
          handleSubmit={handleEditSubmit}
          formState={editingCustomer}
          handleInputChange={handleEditInputChange}
          agents={agents}
        />

        {isVehiclesModalOpen && customerVehicles.length > 0 && (
          <CustomerVehiclesModal
            vehicles={customerVehicles}
            onClose={handleCloseModal}
            isLoading={isLoading} // pass the loading state
          />
        )}
      </div>
    </>
  );
};
