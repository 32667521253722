import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import VehicleList from './VehicleList';
import {
  preferenceOptions,
  toReadableFormat,
} from '../../CRM/customerPreferences';

const CustomerDetails = ({ customer }) => {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    // Fetch all vehicles for the selected customer
    const fetchVehicles = async () => {
      const q = query(
        collection(db, 'vehicles'),
        where('customerId', '==', customer.id)
      );
      const vehiclesSnapshot = await getDocs(q);
      const vehicleList = vehiclesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehicles(vehicleList);
    };

    if (customer) {
      fetchVehicles();
    }
  }, [customer]);

  const getLabeledPreferences = (preferences) => {
    const labeledPreferences = [];

    // Check each category in the preferences object
    Object.keys(preferenceOptions).forEach((category) => {
      preferenceOptions[category].forEach((option) => {
        if (preferences.includes(option)) {
          labeledPreferences.push({
            category: toReadableFormat(category),
            option: option,
          });
        }
      });
    });

    return labeledPreferences;
  };

  const labeledPreferences = getLabeledPreferences(customer.preferences || []);

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">
            Customer Details
          </h3>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Name:</span> {customer.firstName}{' '}
            {customer.lastName}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Email:</span> {customer.email}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Phone:</span> {customer.phoneNumber}
          </p>
        </div>

        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">
            Preferences
          </h3>
          {labeledPreferences.length > 0 ? (
            <ul className="list-disc list-inside space-y-2">
              {labeledPreferences.map((preference, index) => (
                <li key={index} className="text-gray-700">
                  <span className="font-medium">{preference.category}:</span>{' '}
                  {preference.option}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No preferences available.</p>
          )}
        </div>
      </div>

      <h3 className="text-2xl font-semibold mb-6 text-gray-800">Vehicles</h3>
      {vehicles.length > 0 ? (
        <VehicleList vehicles={vehicles} />
      ) : (
        <p className="text-gray-500">No vehicles found for this customer.</p>
      )}
    </div>
  );
};

export default CustomerDetails;
