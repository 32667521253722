import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, getDoc, doc, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const LeaveRequestForm = ({ employeeId, onClose }) => {
  const [leaveType, setLeaveType] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [tempDesignateId, setTempDesignateId] = useState('');
  const [employees, setEmployees] = useState([]);
  const [employeeLeaveInfo, setEmployeeLeaveInfo] = useState(null);
  const [existingRequestId, setExistingRequestId] = useState(null);
  const [numDays, setNumDays] = useState(0);

  useEffect(() => {
    const fetchEmployeeLeaveInfo = async () => {
      try {
        const pendingQuery = query(collection(db, 'leaveRequests'), where('employeeId', '==', employeeId), where('status', '==', 'Pending'));
        const pendingSnapshot = await getDocs(pendingQuery);
        if (!pendingSnapshot.empty) {
          const existingRequest = pendingSnapshot.docs[0];
          setExistingRequestId(existingRequest.id);
          const requestData = existingRequest.data();
          setLeaveType(requestData.leaveType);
          setSelectedDates(requestData.leaveDates.map(date => date.toDate()));
          setTempDesignateId(requestData.tempDesignateId);
        }

        const docRef = doc(db, 'employees', employeeId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEmployeeLeaveInfo(docSnap.data());
        } else {
          toast.error('Employee data not found.');
        }
      } catch (error) {
        toast.error('Failed to fetch employee leave info: ' + error.message);
      }
    };

    const fetchEmployees = async () => {
      try {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesList);
      } catch (error) {
        toast.error('Failed to fetch employees: ' + error.message);
      }
    };

    fetchEmployeeLeaveInfo();
    fetchEmployees();
  }, [employeeId]);

  const calculateWeekdays = (dates) => {
    let count = 0;
    dates.forEach(date => {
      const dayOfWeek = new Date(date).getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    if (selectedDates.length > 0) {
      const days = calculateWeekdays(selectedDates);
      setNumDays(days);
    } else {
      setNumDays(0);
    }
  }, [selectedDates]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!employeeLeaveInfo) {
      toast.error('Unable to validate leave request.');
      return;
    }

    let availableDays;
    if (leaveType === 'Annual Leave') {
      availableDays = employeeLeaveInfo.annualLeaveRemaining;
    } else if (leaveType === 'Casual Leave') {
      availableDays = employeeLeaveInfo.casualLeaveRemaining;
    }

    if (numDays > availableDays) {
      toast.error(`You cannot request more than ${availableDays} days.`);
      return;
    }

    try {
      if (existingRequestId) {
        // Update existing request
        const requestDocRef = doc(db, 'leaveRequests', existingRequestId);
        await updateDoc(requestDocRef, {
          leaveType,
          leaveDates: selectedDates.map(date => Timestamp.fromDate(new Date(date))),
          tempDesignateId,
        });
        toast.success('Leave request updated successfully');
      } else {
        // Create new request
        await addDoc(collection(db, 'leaveRequests'), {
          employeeId,
          leaveType,
          leaveDates: selectedDates.map(date => Timestamp.fromDate(new Date(date))),
          tempDesignateId,
          status: 'Pending',
        });
        toast.success('Leave request submitted successfully');
      }
      onClose();
    } catch (error) {
      toast.error('Failed to submit leave request: ' + error.message);
    }
  };

  const handleDelete = async () => {
    if (existingRequestId) {
      try {
        const requestDocRef = doc(db, 'leaveRequests', existingRequestId);
        await deleteDoc(requestDocRef);
        toast.success('Leave request deleted successfully');
        onClose();
      } catch (error) {
        toast.error('Failed to delete leave request: ' + error.message);
      }
    }
  };

  return (
    <>
    <div className='mt-4 pt-10'>

    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto bg-white shadow-md rounded px-4 pt-4 pb-2 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Leave Type</label>
          <select 
            value={leaveType} 
            onChange={(e) => setLeaveType(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none text-sm focus:border-indigo-500"
          >
            <option value="">Select Leave Type</option>
            <option value="Annual Leave">Annual Leave</option>
            <option value="Casual Leave">Casual Leave</option>
            <option value="Paternity Leave">Paternity Leave</option>
            <option value="Maternity Leave">Maternity Leave</option>
            <option value="Compassionate Leave">Compassionate Leave</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Temporary Designate</label>
          <select 
            value={tempDesignateId} 
            onChange={(e) => setTempDesignateId(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none text-sm focus:border-indigo-500"
          >
            <option value="">Select Temporary Designate</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-2">
        <label className="block text-sm font-medium text-gray-700 mb-2">Select Dates</label>
        <DatePicker
          selected={null}
          onChange={(date) => setSelectedDates([...selectedDates, date])}
          startDate={selectedDates[0]}
          endDate={selectedDates[selectedDates.length - 1]}
          selectsRange
          inline
          className="w-full border rounded-lg focus:outline-none focus:border-indigo-500"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Number of Days Requested (Excluding Weekends)</label>
          <p className="text-lg font-semibold">{numDays} days</p>
        </div>
        
        {employeeLeaveInfo && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Available {leaveType} Days</label>
            <p className="text-lg font-semibold">
              {leaveType === 'Annual Leave' ? employeeLeaveInfo.annualLeaveRemaining : employeeLeaveInfo.casualLeaveRemaining} days
            </p>
          </div>
        )}
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">Selected Dates:</label>
        <ul className="list-disc list-inside mt-2 grid grid-cols-2 md:grid-cols-3 gap-2">
          {selectedDates.map((date, index) => (
            <li key={index} className="text-sm">{new Date(date).toLocaleDateString()}</li>
          ))}
        </ul>
      </div>

      <div className="mt-8 flex justify-end space-x-4">
        {existingRequestId && (
          <button type="button" onClick={handleDelete} className="px-4 py-2 border border-red-300 rounded-md text-sm font-medium text-red-700 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Delete Request
          </button>
        )}
        <button type="button" onClick={onClose} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Cancel
        </button>
        <button type="submit" className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {existingRequestId ? 'Update Request' : 'Submit Request'}
        </button>
      </div>
    </form>

    </div>
    </>
  );
};

export default LeaveRequestForm;
