// banks.js
export const banks = [
    "Greenlight Microfinance Bank",
    "KUDA Microfinance Bank",
    "LAPO Microfinance Bank",
    "OPAY",
    "Access Bank",
    "Citibank Nigeria",
    "Diamond Bank",
    "Ecobank Nigeria",
    "Fidelity Bank",
    "First Bank of Nigeria",
    "First City Monument Bank",
    "GT Bank",
    "Heritage Bank",
    "Keystone Bank",
    "Polaris Bank",
    "Providus Bank",
    "Stanbic IBTC Bank",
    "Standard Chartered Bank Nigeria",
    "Sterling Bank",
    "SunTrust Bank Nigeria",
    "Union Bank of Nigeria",
    "United Bank for Africa",
    "Unity Bank",
    "Wema Bank",
    "Zenith Bank"
  ];
  