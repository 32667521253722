import React from 'react';
import Select from 'react-select';
import { YearSelect } from '../YearSelect';
import vehicleManufacturers from '.././vehicleManufacturers';

const VehicleForm = ({ customers, selectedCustomer, setSelectedCustomer, formState, handleInputChange, handleYearSelection, addVehicle }) => (
  <div className="flex-1">
    <h2 className="text-2xl text-gray-600 font-semibold mb-4">Customer Vehicle Administration</h2>
    <form onSubmit={addVehicle} className="mb-4 space-y-2">
      <div>
        <label htmlFor="selectedCustomer" className="block text-sm font-medium text-gray-700 px-4">Select Registered Customer</label>
        <Select
          options={customers}
          onChange={setSelectedCustomer}
          value={selectedCustomer}
          className="p-2 pb-2 text-sm w-4/5"
          placeholder="Select a Customer..."
          isSearchable
        />
      </div>

      <div className="flex items-center mb-2 py-6 mt-10">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="px-3 text-gray-500">
          Optional - Add Vehicle Details to Selected Customer
        </span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="vehicleManufacturers" className="block text-sm font-medium text-gray-700">Vehicle Manufacturer</label>
          <Select
            options={vehicleManufacturers}
            onChange={(selectedOption) => handleInputChange({ target: { name: 'vehicleMake', value: selectedOption.value } })}
            value={vehicleManufacturers.find(manufacturer => manufacturer.value === formState.vehicleMake)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 text-sm"
            placeholder="Vehicle Make"
            isSearchable
          />
        </div>

        <div>
          <label htmlFor="vehicleModel" className="block text-sm font-medium text-gray-700 px-1 pb-2">Vehicle Model</label>
          <input
            name="vehicleModel"
            value={formState.vehicleModel}
            onChange={handleInputChange}
            placeholder="Vehicle Model"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="Year" className="block text-sm font-medium text-gray-700">Year</label>
          <YearSelect onChange={handleYearSelection} value={formState.year} />
        </div>

        <div>
          <label htmlFor="VIN" className="block text-sm font-medium text-gray-700">VIN</label>
          <input
            name="VIN"
            value={formState.VIN}
            onChange={handleInputChange}
            placeholder="VIN"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <label htmlFor="registrationNumber" className="block text-sm font-medium text-gray-700">Registration Number</label>
          <input
            name="registrationNumber"
            value={formState.registrationNumber}
            onChange={handleInputChange}
            placeholder="Registration Number"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <button
        type="submit"
        className="mt-4 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      >
        Add Customer Vehicle
      </button>
    </form>
  </div>
);

export default VehicleForm;
