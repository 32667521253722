import React from 'react';
import Logo from "../../../images/bigAyden.jpg"; 
import { formatCurrency } from '../../Help/helper';
import './QuotationModalPrint.css'; 


const PrintInvoiceModal = ({ isOpen, onClose, invoice }) => {
  if (!isOpen) return null;
  

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="print-invoice-modal">
      <div className="relative top-10 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
      <div className="mb-4 p-2 text-center font-bold text-sm text-white border-t bg-gradient-to-b from-blue-800 to-blue-950">
              </div>
        
        <div className="flex justify-between items-start p-2 ">
          <div className="logo flex-shrink-0 w-22 h-22">
            <img src={Logo} alt="Ayden Logo" />
          </div>

          <div className='ml-2'>
        <h2 className="text-3xl leading-6 font-medium uppercase mb-1 text-blue-800 ">Invoice</h2>
        <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Invoice No:</span> {invoice?.invoiceNumber}</p>
        <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Job Card No:</span> {invoice?.jobCardNo}</p>
        <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Date:</span> {new Date().toLocaleDateString()}</p>
      </div>
      </div>

          <div className="text-left">
            <h2 className="text-2xl font-bold">AYDENS Garage</h2>
            <p className="text-sm">Block 7, Plot 7, Etal Avenue, Off Kudirat Abiola Way,<br/> Oregun, Lagos, Nigeria.</p>
            <p className="text-sm">+234-7045972414</p>
            <p className="text-sm">info@aydensgarage.com</p>
          </div>
       

        {/* Invoice Header */}
        <div className="mt-6 mb-4 px-4">
    <h3 className=" leading-6 font-medium text-gray-900">CUSTOMER:</h3>
    <p className="text-sm">{invoice.customerName}</p>
    <p className="text-sm">{invoice.address}</p>
    <p className="text-sm">{invoice.customerEmail || 'email@example.com'}</p>
  </div>

        {/* Invoice Items Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal divide-y divide-gray-300">
          <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Item
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100  text-xs font-semibold text-gray-600 uppercase tracking-wider">
                 Unit Price
                </th>
                <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Extended Price
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {invoice.cartItems.map((item, index) => (
                <tr key={index} className="even:bg-gray-50">
                   <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center text-gray-500">
                    {item.description}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center text-gray-500">
                    {item.quantity}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center text-gray-500">
                    {formatCurrency(item.price)}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm text-center text-gray-500">
                    {formatCurrency(item.price * item.quantity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Total Section */}
        <div className="mt-4 text-right pr-20">
            <p className="text-sm text-gray-900 font-semibold">
              SUBTOTAL: {formatCurrency(invoice.subtotal)}
            </p>
            <p className="text-sm text-gray-500">
             7.5% VAT: {formatCurrency(invoice.taxAmount)}
            </p>
            <p className="text-lg text-gray-900 font-bold">
              GRAND TOTAL: {formatCurrency(invoice.totalSale)}
            </p>
          </div>

          <div className="mt-4 p-4">
        <h3 className="text-lg font-semibold text-gray-900 border-b pb-2">Payment Information:</h3>
        <div className="mt-2 text-sm">
          <p>Bank: AYDENS Garage Ltd Bank - STANBIC IBTC BANK Plc</p>
          <p>Acc No: 0050351584</p>
        </div>
      </div>

          <div className="mt-4 p-1 text-center font-bold text-sm text-white border-t bg-gradient-to-b from-blue-800 to-blue-950">
        <p className="text-xs">TIN: 31422177-0001 | RC6722665</p>
         </div>
        <div className="flex justify-between items-center px-4 py-3 print:hidden">
          <button onClick={onClose} className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-blue-300">
            Close</button>

          <button onClick={() => window.print()} type='button'  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-300">
            Print</button>
        </div>
      </div>
    </div>
  );
};

export default PrintInvoiceModal;
