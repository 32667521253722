

export const preferenceOptions = {
  vehicleService: ["Regular Maintenance", "Oil Change", "Tire Rotation", "Eco-Friendly Products"],
  communication: ["Email", "Phone Call", "WhatsApp", "Text Message"],
  appointment: ["Weekdays", "Weekends", "Early Morning", "Late Afternoon", "Evening"],
  paymentBilling: ["Debit Card", "Cash", "Bank Transfer", "Online Payment"],
  vehicleParts: ["Original Manufacturer Parts", "Third-Party Parts", "Specific Brand Preference"],
  serviceReminders: ["Email Notifications", "SMS Reminders", "Phone Call Reminders"],
  customizationUpgrades: ["Audio System Upgrade", "Performance Enhancement", "Exterior Modifications", "Interior Customization"]
};


  export const toReadableFormat = (str) => {
    // Split the string at all the places preceded by a capital letter
    const words = str.split(/(?=[A-Z])/);
    
    // Capitalize the first letter of each word and join them with a space
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };
  