import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import BreadCrumb from '../BreadCrumb';
import { Link, Navigate } from 'react-router-dom'
import LeaveRequestApproval from './LeaveRequestApproval';
import LeavePolicyManager from './LeavePolicyManager';
import CreateLeaveEntry from './CreateLeaveEntry';
import LeaveCalendar from './LeaveCalendar';
import LeaveReports from './LeaveReports';
import EmployeePerformanceReview from './EmployeePerformanceReview';

const pages = [{ name: 'Human Resources', to: '/humanresources', component: Link, current: true },];

const HRDashboard = () => {

    const [activeComponent, setActiveComponent] = useState('leaveCalendar');
    const [pendingRequests, setPendingRequests] = useState(0);
    const [upcomingLeaves, setUpcomingLeaves] = useState(0);
    const { role } = useAuth();

    useEffect(() => {
      const fetchLeaveRequests = async () => {
        try {
          // Fetch pending leave requests
          const pendingQuery = query(collection(db, 'leaveRequests'), where('status', '==', 'Pending'));
          const pendingSnapshot = await getDocs(pendingQuery);
          setPendingRequests(pendingSnapshot.size);
  
          // Fetch upcoming leaves (e.g., leaves starting today or later)
          const today = new Date();
          const upcomingQuery = query(collection(db, 'leaveRequests'), where('startDate', '>=', today));
          const upcomingSnapshot = await getDocs(upcomingQuery);
          setUpcomingLeaves(upcomingSnapshot.size);
        } catch (error) {
          console.error('Failed to fetch leave requests:', error);
        }
      };
  
      fetchLeaveRequests();
    }, []);


    const renderComponent = () => {
        switch (activeComponent) {
    
          case 'leaveCalendar':
            return <LeaveCalendar />;
            
          case 'leaveEntry':
            return <CreateLeaveEntry />;
    
            case 'leavePolicy':
              return <LeavePolicyManager />;
    
            case 'leaveApproval':
              return <LeaveRequestApproval />;
    
              case 'leaveReports':
                return <LeaveReports />;

                case 'performanceReviews':
                  return <EmployeePerformanceReview />;
      
           
          default:
            return <LeaveCalendar />;
        }
      };
    

      if (role !== 'Finance' && role !== 'Admin') {
   
        return <Navigate to="/unauthorized" replace />; 
      }


  return (
    <>
    
    <BreadCrumb pages={pages} />

    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">HR Manager Dashboard</h1>
      
      {/* Summary Widgets */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-blue-500 text-white p-4 rounded-md">
          <h2 className="text-lg font-medium">Pending Requests</h2>
          <p className="text-2xl">{pendingRequests}</p>
        </div>
        <div className="bg-green-500 text-white p-4 rounded-md">
          <h2 className="text-lg font-medium">Upcoming Leaves</h2>
          <p className="text-2xl">{upcomingLeaves}</p>
        </div>
        {/* Add more summary widgets as needed */}
      </div>


      <nav className="mb-4">
          <ul className="flex space-x-4 justify-center border-b-2 border-gray-200 pb-2 text-sm">
          <li>
              <button
                onClick={() => setActiveComponent('leaveCalendar')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'leaveCalendar' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Leave Calendar
              </button>
            </li>

            <li>
              <button
                onClick={() => setActiveComponent('performanceReviews')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'performanceReviews' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Performance Reviews
              </button>
            </li>

            <li>
              <button
                onClick={() => setActiveComponent('leaveEntry')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'leaveEntry' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Create Leave Request
              </button>
            </li>

            <li>
              <button
                onClick={() => setActiveComponent('leavePolicy')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'leavePolicy' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Set Employee Leave Policy
              </button>
            </li>
           
        

            <li>
              <button
                onClick={() => setActiveComponent('leaveApproval')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'leaveApproval' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Approval Requests
              </button>
            </li>        

            <li>
              <button
                onClick={() => setActiveComponent('leaveReports')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'leaveReports' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Leave Reports
              </button>
            </li>   
                      


            
           
          </ul>
        </nav>

        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          {renderComponent()}
        </div>













    
    </div>
    </>
  );
};

export default HRDashboard;
