import {collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 

const getWeekRange = () => {
    const now = new Date();
    const firstDayOfWeek = now.getDate() - now.getDay(); // First day is the day of the month - the day of the week
    const lastDayOfWeek = firstDayOfWeek + 6; // last day is the first day + 6
  
    const firstDay = new Date(now.setDate(firstDayOfWeek));
    firstDay.setHours(0, 0, 0, 0);
  
    const lastDay = new Date(now.setDate(lastDayOfWeek));
    lastDay.setHours(23, 59, 59, 999);
  
    return { firstDay, lastDay };
  };
  
 export  const birthdaysOfTheWeek = async () => {
    const { firstDay, lastDay } = getWeekRange();
    const customersSnapshot = await getDocs(collection(db, 'customers'));
    
    const birthdaysThisWeek = [];
    customersSnapshot.forEach(doc => {
      const customer = doc.data();
      const dob = new Date(customer.dateOfBirth);
      if (dob >= firstDay && dob <= lastDay) {
        birthdaysThisWeek.push({
          name: `${customer.firstName} ${customer.lastName}`,
          dateOfBirth: customer.dateOfBirth,
          email: customer.email
        });
      }
    });
  
    return birthdaysThisWeek;
  };
  