import React, { useState, useEffect } from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import { toast } from 'react-toastify';
import '../JobCard.css';

const PartsAndServicesForm = ({ formData, updateData, saveAndExit }) => {
    const [services, setServices] = useState([]);
    const [partsData, setPartsData] = useState(formData.partsRequired.length ? formData.partsRequired : [{ partName: '', type: 'generic', quantity: 1 }]);
    const [servicesData, setServicesData] = useState(formData.servicesRequired.length ? formData.servicesRequired : [{ serviceType: '', technicianType: '', serviceQuantity: '', serviceCost: '' }]);
    const [repairTypes, setRepairTypes] = useState(formData.repairTypes || {
        GR: false,
        BP: false,
        ER: false,
        MR: false,
        QS: false,
    });

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const servicesCollection = collection(db, 'services');
                const servicesSnapshot = await getDocs(servicesCollection);
                const servicesList = servicesSnapshot.docs.map(doc => doc.data().name);
                setServices(servicesList);              
            } catch (error) {
                toast.error("Failed to fetch services.");
            }
        };
        fetchServices();
    }, []);


    const handleRepairTypeChange = (e) => {
        const { name, checked } = e.target;
        const updatedRepairTypes = { ...repairTypes, [name]: checked };
        setRepairTypes(updatedRepairTypes);
        updateData({ ...formData, repairTypes: updatedRepairTypes });

        // Set repair type abbreviation for job card
        const selectedJobTypes = Object.keys(updatedRepairTypes).filter(key => updatedRepairTypes[key]);
        const repairTypeAbbreviation = selectedJobTypes.join('-').toUpperCase(); // Fix abbreviation generation
        updateData({ ...formData, repairTypeAbbreviation });
    };


    const handlePartsInputChange = (event, index) => {
        const { name, value } = event.target;
        const parts = [...partsData];
        parts[index] = { ...parts[index], [name]: value };
        setPartsData(parts);
        updateData({ partsRequired: parts, servicesRequired: servicesData });
    };

    const handleAddPartClick = () => {
        const newParts = [...partsData, { partName: '', type: 'generic', quantity: 1 }];
        setPartsData(newParts);
        updateData({ partsRequired: newParts, servicesRequired: servicesData });
    };

    const handleRemovePartClick = index => {
        const newParts = [...partsData];
        newParts.splice(index, 1);
        setPartsData(newParts);
        updateData({ partsRequired: newParts, servicesRequired: servicesData });
    };

    const incrementPartQuantity = index => {
        const parts = [...partsData];
        parts[index].quantity = Number(parts[index].quantity) + 1;
        setPartsData(parts);
        updateData({ partsRequired: parts, servicesRequired: servicesData });
    };

    const decrementPartQuantity = index => {
        const parts = [...partsData];
        if (parts[index].quantity > 1) {
            parts[index].quantity = Number(parts[index].quantity) - 1;
            setPartsData(parts);
            updateData({ partsRequired: parts, servicesRequired: servicesData });
        }
    };

    const handleServiceInputChange = (event, index) => {
        const { name, value } = event.target;
        const services = [...servicesData];
        services[index] = { ...services[index], [name]: value };
        setServicesData(services);
        updateData({ partsRequired: partsData, servicesRequired: services });
    };

    const handleAddServiceClick = () => {
        const newServices = [...servicesData, { serviceType: '', technicianType: '', serviceQuantity: '', serviceCost: '' }];
        setServicesData(newServices);
        updateData({ partsRequired: partsData, servicesRequired: newServices });
    };

    const handleRemoveServiceClick = index => {
        const newServices = [...servicesData];
        newServices.splice(index, 1);
        setServicesData(newServices);
        updateData({ partsRequired: partsData, servicesRequired: newServices });
    };

 

    return (
        <div className="space-y-6">

<div className='mt-4 bg-yellow-50 mb-2 pt-2 pl-4 pb-2'>
                <label className="block text-gray-700 font-bold mb-2 uppercase">Repair Types</label>
                <div className="grid grid-cols-3 gap-4">
                    {/* Checkboxes for Repair Types */}
                    {Object.keys(repairTypes).map((type) => (
                        <div key={type}>
                            <label>
                                <input
                                    type="checkbox"
                                    name={type}
                                    checked={repairTypes[type]}
                                    onChange={handleRepairTypeChange}
                                    className="mr-2"
                                />
                                {type === 'GR' && 'General Repair (GR)'}
                                {type === 'BP' && 'Body Work and Painting (BP)'}
                                {type === 'ER' && 'Electrical Repair (ER)'}
                                {type === 'MR' && 'Mechanical Repair (MR)'}
                                {type === 'QS' && 'Quick Service (QS)'}
                            </label>
                        </div>
                    ))}
                </div>
            </div>


                    <div className='mt-4 bg-gray-50 mb-2 pl-4 pb-2'>
                        <label className="block text-gray-500 font-bold mb-2 uppercase pt-2">Parts Required</label>
                        
                        {/* Grid layout for table headers */}
                        <div className="grid grid-cols-4 gap-2 font-semibold text-sm text-gray-700 text-center">
                            <div>Part Name</div>
                            <div>Type</div>
                            <div>Quantity</div>
                            <div>Action</div>
                        </div>

                        {partsData.map((item, index) => (
                            <div className="grid grid-cols-4 gap-2 items-center mb-1" key={index}>
                                <input
                                    name="partName"
                                    value={item.partName}
                                    onChange={(e) => handlePartsInputChange(e, index)}
                                    className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Part Name"
                                />

                                <select
                                    name="type"
                                    value={item.type}
                                    onChange={(e) => handlePartsInputChange(e, index)}
                                    className="shadow appearance-none border rounded py-1 px-3 text-sm w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="generic">Generic Spare Part</option>
                                    <option value="vehicle-specific">Vehicle Specific Spare Part</option>
                                </select>

                                <div className='flex items-center justify-center'>
                                    <button
                                        type="button"
                                        onClick={() => decrementPartQuantity(index)}
                                        className="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-10 w-8 rounded-l cursor-pointer"
                                    >
                                        -
                                    </button>
                                    <input
                                        type="text"
                                        name="quantity"
                                        className="shadow appearance-none border rounded text-sm py-1 px-3 text-center w-1/5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        value={item.quantity}
                                        onChange={(e) => handlePartsInputChange(e, index)}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => incrementPartQuantity(index)}
                                        className="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-10 w-8 rounded-r cursor-pointer"
                                    >
                                        +
                                    </button>
                                </div>

                                <div className="flex justify-center">
                                    {partsData.length !== 1 && (
                                        <MinusCircleIcon
                                            className="h-6 w-6 text-red-500 cursor-pointer"
                                            onClick={() => handleRemovePartClick(index)}
                                        />
                                    )}
                                    {partsData.length - 1 === index && (
                                        <PlusCircleIcon
                                            className="h-6 w-6 text-green-500 cursor-pointer"
                                            onClick={handleAddPartClick}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                     <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />



                    <div className='mt-4 bg-blue-50 mb-2 pt-2 pl-4 pb-2'>
                        <label className="block text-gray-700 font-bold mb-2 uppercase">Services Required</label>
                        
                      
                        <div className="grid grid-cols-5 gap-2 font-semibold text-sm text-gray-700 text-center">
                            <div>Service Description</div>
                            <div>Technician Type</div>
                            <div>Qty/Duration</div>
                            <div>Cost</div>
                            <div>Action</div>
                        </div>

                        {servicesData.map((item, index) => (
                            <div className="grid grid-cols-5 gap-2 items-center mb-2" key={index}>
                                <input
                                    name="serviceType"
                                    placeholder="Service Description"
                                    value={item.serviceType}
                                    onChange={(event) => handleServiceInputChange(event, index)}
                                    className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />

                                <select
                                    name="technicianType"
                                    value={item.technicianType}
                                    onChange={(event) => handleServiceInputChange(event, index)}
                                    className="shadow appearance-none border rounded w-full py-1 px-3 text-sm text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="">Select Technician Type</option>
                                    {services.map(service => (
                                        <option value={service} key={service}>{service}</option>
                                    ))}
                                </select>
                                <div className='px-10'>
                                <input
                                    type="number"
                                    name="serviceQuantity"
                                    placeholder="Qty/Duration"
                                    value={item.serviceQuantity}
                                    onChange={(event) => handleServiceInputChange(event, index)}
                                    className="shadow appearance-none border rounded w-16 py-1 px-3 text-sm text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                </div>

                                <input
                                    type="number"
                                    name="serviceCost"
                                    value={item.serviceCost}
                                    placeholder="Total Cost"
                                    onChange={(event) => handleServiceInputChange(event, index)}
                                    className="shadow appearance-none border rounded w-full py-1 px-3 text-sm text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />

                                <div className="flex justify-center">
                                    {servicesData.length !== 1 && (
                                        <MinusCircleIcon
                                            className="h-6 w-6 text-red-500 cursor-pointer"
                                            onClick={() => handleRemoveServiceClick(index)}
                                        />
                                    )}
                                    {servicesData.length - 1 === index && (
                                        <PlusCircleIcon
                                            className="h-6 w-6 text-green-500 cursor-pointer"
                                            onClick={handleAddServiceClick}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}


                    </div>
                    <button onClick={saveAndExit} className="bg-red-500 hover:bg-red-700 text-sm text-white font-bold py-2 px-4 rounded">Save and Exit</button>
        </div>
    );
};

export default PartsAndServicesForm;
