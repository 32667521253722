// AddModal.js
import React, { useState } from 'react';
import { banks } from './banks';


const AddModal = ({ onChange, onSubmit, onClose }) => {

  const [bankDetails, setBankDetails] = useState([{ bankName: '', accountName: '', accountNumber: '' }]);
  const [paymentTerms, setPaymentTerms] = useState('');
  const [warrantyAvailable, setWarrantyAvailable] = useState(false);


  const handleBankDetailsChange = (index, event) => {
    const values = [...bankDetails];
    values[index][event.target.name] = event.target.value;
    setBankDetails(values);
    onChange({ target: { name: 'bankDetails', value: values } });
  };

  const handleAddBankDetails = () => {
    setBankDetails([...bankDetails, { bankName: '', accountName: '', accountNumber: '' }]);
  };

  const handleRemoveBankDetails = (index) => {
    const values = [...bankDetails];
    values.splice(index, 1);
    setBankDetails(values);
    onChange({ target: { name: 'bankDetails', value: values } });
  };

  const handlePaymentTermsChange = (event) => {
    setPaymentTerms(event.target.value);
    onChange({ target: { name: 'paymentTerms', value: event.target.value } });
  };

  const handleWarrantyChange = (event) => {
    const warranty = event.target.value === 'Yes' ? true : false;
    setWarrantyAvailable(warranty);
    onChange({ target: { name: 'warrantyAvailable', value: warranty } });
  };


  const handleSubmit = () => {
    onSubmit();
    onClose();
  };
  
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pb-10 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="bg-white px-4 pt-2 pb-4 sm:p-6 sm:pb-4">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
              {/* Company Name and Email */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
                  Company Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="companyName"
                  name="companyName"
                  onChange={onChange}
                  placeholder="Company Name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full text-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  type="email"
                  onChange={onChange}
                  placeholder="Email"
                />
              </div>
  
              {/* First Name and Last Name */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="firstName"
                  id='firstName'
                  onChange={onChange}
                  required
                  placeholder="First Name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="lastName"
                  id='lastName'
                  onChange={onChange}
                  required
                  placeholder="Last Name"
                />
              </div>
  
              {/* Address and Phone */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className="shadow appearance-none border rounded text-xs w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="address"
                  onChange={onChange}
                  placeholder="Contractor Address"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumbers">
                  Phone Numbers
                </label>
                <input
                  className="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="phoneNumbers"
                  type="text"
                  onChange={onChange}
                  placeholder="Phone"
                />
              </div>
  
              {/* Product Sold and Vendor Payment Terms */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productSold">
                  Product Sold
                </label>
                <input
                  className="shadow appearance-none border rounded text-xs w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="productSold"
                  type="text"
                  onChange={onChange}
                  placeholder="Product Specialization"
                />
              </div>
              <div className="mb-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentTerms">
                  Payment Terms
                </label>
                <select
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                  focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                  name="paymentTerms"
                  value={paymentTerms}
                  onChange={handlePaymentTermsChange}
                >
                  <option className='text-xs' value="">Payment Terms</option>
                  <option value="Upfront Payment">Upfront Payment</option>
                  <option value="Part Payment">Part Payment</option>
                  <option value="Payment Post-Delivery">Payment Post-Delivery</option>
                </select>
              </div>
  
              {/* Warranty Available */}
              <div className="mt-1 col-span-full">
                <span className="text-gray-700 text-sm">Does Vendor Offer Warranty:</span>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="warrantyAvailable"
                      value="Yes"
                      checked={warrantyAvailable === true}
                      onChange={handleWarrantyChange}
                    />
                    <span className="ml-2 text-sm">Yes</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="warrantyAvailable"
                      value="No"
                      checked={warrantyAvailable === false}
                      onChange={handleWarrantyChange}
                    />
                    <span className="ml-2 text-sm">No</span>
                  </label>
                </div>
              </div>
  
              {/* Bank Details */}
              {bankDetails.map((bankDetail, index) => (
                <div key={index} className="grid grid-cols-3 gap-4 mb-2 col-span-full">
                  <select
                    className="appearance-none border rounded w-full py-1 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="bankName"
                    value={bankDetail.bankName}
                    onChange={(event) => handleBankDetailsChange(index, event)}
                  >
                    <option value="">Select Bank</option>
                    {banks.map((bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="accountName"
                    value={bankDetail.accountName}
                    onChange={(event) => handleBankDetailsChange(index, event)}
                    placeholder="Account Name"
                  />
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="accountNumber"
                    value={bankDetail.accountNumber}
                    onChange={(event) => handleBankDetailsChange(index, event)}
                    placeholder="Account Number"
                  />
                  <button 
                    onClick={() => handleRemoveBankDetails(index)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold h-8 px-4 m-2 text-xs  rounded focus:outline-none focus:shadow-outline"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                onClick={handleAddBankDetails}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold h-8 px-4 m-2 text-xs rounded focus:outline-none focus:shadow-outline col-span-full"
              >
                Add Bank Details
              </button>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default AddModal;
