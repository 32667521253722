import { Fragment } from 'react'
import { ChevronRightIcon, HomeIcon,HomeModernIcon,QueueListIcon,
  ShoppingCartIcon,PhoneIcon,BanknotesIcon } from '@heroicons/react/20/solid'


  import { Link, Navigate } from 'react-router-dom';
  import { useAuth } from '../Auth/Auth';

  const actions = [

    {
      title: 'Open and Closed Job Cards',
      to: '/analytics/jobs',
      desc: "Job Card Status",
      icon: PhoneIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },   

    {
      title: 'Customer Job History',
      to: '/analytics/customers',
      desc: "Details of Customers and their Jobs",
      component: Link,
      icon: BanknotesIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    {
      title: 'Revenue By Service Type',
      to: '/analytics/revenue',
      desc: "Total, Commission and Product Sales",
      component: Link,
      icon: BanknotesIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

   

    {
      title: 'Customer Request Trends',
      to: '/analytics/servicedemand',
      desc: "Frequently Requested Services by Customers",
      component: Link,
      icon: ChevronRightIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    {
        title: 'Financial Analysis ',
        to: '/analytics/financial',
        desc: "Outstanding Invoice and Payment Status",
        icon: ShoppingCartIcon,
        iconForeground: 'text-rose-700',
        iconBackground: 'bg-rose-50',
      }, 

    {
      title: 'Customer Insights',
      to: '/analytics/customers',
      desc: "Segmentation and Loyalty Analysis",
      icon: QueueListIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    }, 

    {
      title: 'Operational Analytics',
      to: '/analytics/operations',
      desc: "Job Status and Performance",
      icon: HomeModernIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },   

    
 
    {
      title: 'Agent Performance Metrics',
      to: '/analytics/agents',
      icon: HomeIcon,
      component: Link,
      desc: "View Agent Commission and Activity levels",
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },                          
             
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }



const Analytics = () => {
  const { role, loading } = useAuth();


  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Service Advisor' && role !== 'Admin') {
   
    return <Navigate to="/unauthorized" replace />; 
  }



  return (
    <>


     <div className='flex justify-center items-center pt-6'>
    <div className="divide-y divide-gray-50 grid grid-cols-3 gap-4 overflow-hidden rounded-lg bg-gray-200 shadow">
        {actions.map((action, actionIdx) => (
            <div
                key={action.title}
                className={classNames(
                    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 transform transition duration-500 ease-in-out hover:scale-105'
                )}
            >
                <div className="flex items-center justify-center">
                    <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'inline-flex rounded-lg p-3 ring-4 ring-white'
                        )}
                    >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div className="mt-8">
                    <h3 className="text-base font-semibold text-center leading-6 text-gray-900">
                        <Link to={action.to} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            {action.title}
                        </Link>
                    </h3>
                    <p className="mt-2 text-sm text-center text-gray-500">
                        {action.desc} 
                    </p>
                </div>
                <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    aria-hidden="true"
                >
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
        ))}
    </div>
</div>


 

</>
  )
}

export default Analytics