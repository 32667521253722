import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Select from 'react-select';


const localizer = momentLocalizer(moment);

const TaskCalendar = () => {
  const [tasks, setTasks] = useState([]);
  const [mechanics, setMechanics] = useState([]);
  const [jobCards, setJobCards] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedMechanic, setSelectedMechanic] = useState(null);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
 

  useEffect(() => {
    const fetchTasks = async () => {
      const querySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        start: doc.data().startDate.toDate(),
        end: doc.data().deadline.toDate(),
      }));
      setTasks(tasksData);
      setFilteredTasks(tasksData);
    };

    const fetchMechanics = async () => {
      const q = query(collection(db, 'employees'), where('role', '==', 'Mechanic'));
      const querySnapshot = await getDocs(q);
      setMechanics(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchJobCards = async () => {
      const querySnapshot = await getDocs(collection(db, 'jobs'));
      setJobCards(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchTasks();
    fetchMechanics();
    fetchJobCards();
  }, []);

  const handleMechanicChange = (selectedOption) => {
    setSelectedMechanic(selectedOption);
  };

  const handleJobCardChange = (selectedOption) => {
    setSelectedJobCard(selectedOption);
  };

  useEffect(() => {
    let filtered = tasks;
    if (selectedMechanic) {
      filtered = filtered.filter(task => task.assignedTo === selectedMechanic.id);
    }
    if (selectedJobCard) {
      filtered = filtered.filter(task => task.jobCardNo === selectedJobCard.jobCardNo);
    }
    setFilteredTasks(filtered);
  }, [selectedMechanic, selectedJobCard, tasks]);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-lg font-semibold text-gray-900">Task Calendar</h2>
      <div className="flex space-x-4 mb-4">
        <div className="w-1/3">
          <label className="block text-sm font-medium text-gray-700">Filter by Mechanic</label>
          <Select
            options={mechanics.map(mechanic => ({ value: mechanic.id, label: `${mechanic.firstName} ${mechanic.lastName}` }))}
            onChange={handleMechanicChange}
            isClearable
            className="mt-1"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-sm font-medium text-gray-700">Filter by Job Card</label>
          <Select
            options={jobCards.map(job => ({ value: job.jobCardNo, label: `${job.jobCardNo} - ${job.vehicleMake} ${job.vehicleModel}` }))}
            onChange={handleJobCardChange}
            isClearable
            className="mt-1"
          />
        </div>
      </div>
      <Calendar
        localizer={localizer}
        events={filteredTasks}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        titleAccessor="taskTitle"
      />
    </div>
  );
};

export default TaskCalendar;
