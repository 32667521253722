import React, { useEffect} from 'react'
import { Route, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import AdminRBAC from '../Admin/AdminRBAC';
// import Finance from '../Finance/Finance';
import Home from '../Home/Home';
import HelpCenterPage from '../Help/HelpCenterPage';
import {Customer} from '../Customers/Customer';
import Vehicle from '../Vehicles/Vehicle';
import ManageVehicle from '../Vehicles/ManageVehicle';
import Services from '../Admin/Services';
import Contractors from '../Admin/Contractors';
import ProcessOverview from '../Help/ProcessOverview';
import JobCard from '../Jobs/JobCard';
import Jobs from '../Jobs/Jobs';
import AllJobs from '../Jobs/AllJobs';
import JobDetail from '../Jobs/JobDetail';
import ClosedJobs from '../Jobs/ClosedJobs';
import EditJobComponent from '../Jobs/EditJobComponent';
import UpdateCustomerNumbers from '../Customers/UpdateCustomerNumbers';
import InventoryItemDetail from '../Inventory/InventoryItemDetail';
import InspectionContainer from '../Inspection/InspectionContainer';
import VehicleHistory from '../Vehicles/VehicleHistory';
import Quotation from '../Finance/Quotation';
import ExpenseTracker from '../Finance/ExpenseTracker';
import Invoices from '../Finance/Invoices';
import InvoiceDetails from '../Finance/InvoiceDetails';
import InvoiceTracker from '../Finance/InvoiceTracker';
import VehicleInspectionReport from '../Inspection/VehicleInspectionReport'; 
import Admin from '../Admin/Admin';
import ReferralAgent from '../ReferralAgent/ReferralAgent';
import Taxes from '../Finance/Taxes';
import Procurement from '../Procurement/Procurement';
import Suppliers from '../Suppliers/Suppliers';
import Requisition from '../Procurement/Requisition';
import Inventory from '../Inventory/Inventory';
import Receivable from '../Finance/Receivables';
import CommissionPayable from '../Finance/CommissionPayable';
// import ProcurementStatus from './Procurement/ProcurementStatus';
import Employee from '../Employees/Employee';
import LayoutRoutes from './LayoutRoutes';
import Analytics from '../Analytics/Analytics';
import OperationalAnalytics from '../Analytics/OperationalAnalytics';
import AdminMarginSettings from '../Procurement/AdminMarginSettings';
import ManageProcurements from '../Procurement/ManageProcurements';
import Quotations from '../Finance/Quotations';
import QuotationDetails from '../Finance/QuotationDetails';
import EditQuotation from '../Finance/EditQuotation';
import Unauthorized from './Unauthorized';
import CreateTask from '../Tasks/CreateTask';
import CustomerPage from '../Customers/CustomerPage';
import FeedbackManager from '../Customers/FeedbackManager';
import AppointmentsManager from '../Customers/AppointmentsManager';
import QuotationPage from '../Finance/QuotationPage';
import JobAppointments from '../Vehicles/JobAppointments';
import ProcurementsTable from '../Procurement/ProcurementsTable';
import RequisitionDetails from '../Procurement/RequisitionDetails';
import ProcurementApproval from '../Procurement/ProcurementApproval';
import ProcQuotationPage from '../Procurement/ProcQuotationPage';
import PreQuotations from '../Procurement/PreQuotations';
import Payables from '../Finance/Payables';
import MechanicTasks from '../Mechanics/MechanicTasks';
import EditPreQuotations from '../Procurement/EditPreQuotations';
import EditProcQuotationPage from '../Procurement/EditProcQuotationPage';
import EmployeePerformanceMetrics from '../Mechanics/EmployeePerformanceMetrics';
import ManagerMechanicTasks from '../Tasks/ManagerMechanicTasks';
import JobStatusDashboard from '../Analytics/JobStatusDashboard';
import RevenueByServiceType from '../Analytics/RevenueByServiceType';
import ServiceDemandTrends from '../Analytics/ServiceDemandTrends';
import CustomerJobHistory from '../Analytics/CustomerJobHistory';
import TaskDashboard from '../Tasks/TaskDashboard';
import TaskCompletionReport from '../Tasks/TaskCompletionReport';
import TaskCalendar from '../Tasks/TaskCalendar';
import Supervisor from '../Mechanics/Supervisor';
import EmployeeProfile from '../Profile/EmployeeProfile';
import HRDashboard from '../HR/HRDashboard';
import DQ from '../Jobs/DQ';
import DiagnosisHistory from '../Vehicles/DiagnosisHistory';
import DQContainer from '../Jobs/DQ/DQContainer';
import UpdateJob from '../Vehicles/UpdateJob';
import JobUpdate from '../Jobs/JobUpdate';
import DiagQ from '../Jobs/DiagQ';

import CustomerSelector from '../Customers/Composite/CustomerSelector';
import CustomerProfile from '../Customers/CustomerProfile';
import HelpContent from '../Help/HelpContent';
import ArticleForm from '../Help/Articles/ArticleForm';
import ArticleList from '../Help/Articles/ArticleList';


export default function ProtectedRoutes() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/login");
  }, [user, navigate]);

  return (
    <LayoutRoutes>    
    
      <Route index element={<Home />} />

      <Route path="/help" element={<HelpCenterPage />} />
      <Route path="/help/article/:id" element={<HelpContent />} />

      <Route path="/sop" element={<ProcessOverview />} />

      <Route path='/profile' element={<EmployeeProfile />} />

      <Route path='/humanresources' element={<HRDashboard />} />

 {/* Customer Care Routes */}
      <Route path="/customers" element={<CustomerPage />} />  
      <Route path="/customers/manage" element={<Customer />} />  
      <Route path="/customers/feedback" element={<FeedbackManager />} />  
      <Route path="/customers/profile/:id" element={<CustomerProfile />} /> 
      <Route path="/customers/appointments" element={<AppointmentsManager />} />  
      <Route path="/customers/update" element={<UpdateCustomerNumbers />} />  
      <Route path="/customers/reports" element={<CustomerSelector />} />  


      {/* <Route path="/finance" element={<Finance />} />   */}
      <Route path="/vehicles" element={<Vehicle />} />      
        <Route path="/vehicles/manage" element={<ManageVehicle />} />
       <Route path="/vehicles/openjobs" element={<Jobs />} />
        <Route path="/vehicles/jobs" element={<JobCard />} />
        <Route path="/vehicles/dq" element={<DQ />} />
        <Route path="/vehicles/diagq" element={<DiagQ />} />
        <Route path="/vehicles/newDQ" element={<DQContainer />} />
        <Route path="/vehicles/closedjobs" element={<ClosedJobs />} />
        <Route path="/vehicles/jobs/edit/:jobId" element={<EditJobComponent />} />
        <Route path="/vehicles/alljobs" element={<AllJobs />} />
        <Route path="/vehicles/inspectionReport" element={<VehicleInspectionReport />} /> 

        <Route path="/vehicles/diagnosisHistory" element={<DiagnosisHistory />} />  
        <Route path="/vehicles/jobupdate/:jobCardNo" element={<JobUpdate />} />
        <Route path="/vehicles/updateJob/:jobCardNo" element={<UpdateJob />} />

        <Route path="/vehicles/vehicleHistory/:id" element={<VehicleHistory />} />     
        <Route path="/vehicles/jobdetail/:jobCardNo" element={<JobDetail />} />
        <Route path="/vehicles/appointments" element={<JobAppointments />} />             
        <Route path="/inspection" element={<InspectionContainer />} />  

        <Route path="/vehicles/assigntask" element={<CreateTask />} />  
        <Route path="/vehicles/mechanicperformance" element={<EmployeePerformanceMetrics />} />
        <Route path="/vehicles/managertasks" element={<ManagerMechanicTasks />} />
        <Route path="/vehicles/taskdashboard" element={<TaskDashboard />} />
        <Route path="/vehicles/taskcompleted" element={<TaskCompletionReport />} />
        <Route path="/vehicles/mechanic-calendar" element={<TaskCalendar />} />
        <Route path="/supervisor" element={<Supervisor />} />
       
        <Route path="/procurement" element={<Procurement />} /> 
        <Route path="/procurement/jobcardpricing" element={<PreQuotations />} /> 
        <Route path="/procurement/editjobcardpricing" element={<EditPreQuotations />} /> 
        <Route path="/procurement/editjobcardpricing/editjobcost" element={<EditProcQuotationPage />} /> 
        <Route path="/procurement/jobcardpricing/jobcost" element={<ProcQuotationPage />} />  
        <Route path="/procurement/inventory" element={<Inventory />} />  
        <Route path="/procurement/inventory/:itemId" element={<InventoryItemDetail />} />  
       <Route path="/procurement/suppliers" element={<Suppliers />} />    
        <Route path="/procurement/buyitem" element={<Requisition />} />  
         <Route path="/procurement/manage" element={<ManageProcurements />} />  
        <Route path="/procurement/requests" element={<ProcurementsTable />} />  
        <Route path="/procurement/requisition/:id" element={<RequisitionDetails/>} />  
        
        <Route path="/procurement/approval" element={<ProcurementApproval />} />  

       

        
        <Route path="/finance/quotation/:jobCardNo" element={<Quotation />} />
        <Route path="/finance" element={<ExpenseTracker />} /> 
        <Route path="/finance/receivable" element={<Receivable />} /> 
        <Route path="/finance/commissionpending" element={<CommissionPayable />} /> 
        <Route path="/finance/taxes" element={<Taxes />} /> 
        <Route path="/finance/invoices" element={<Invoices />} /> 
        <Route path="/finance/margin-admin" element={<AdminMarginSettings />} />  
        <Route path="/finance/invoices/:id" element={<InvoiceDetails />} /> 
        <Route path="/finance/quotations" element={<Quotations />} /> 
        <Route path="/finance/quotations/:id" element={<QuotationDetails />} /> 
        <Route path="finance/edit-quotation/:id" element={<EditQuotation />} /> 
        <Route path="finance/quotationpage" element={<QuotationPage />} /> 
        <Route path="/finance/trackInvoice" element={<InvoiceTracker />} />
        <Route path="/finance/payables" element={<Payables />} />
        
        <Route path="/admin" element={<Admin />} /> 
        <Route path="/admin/rbac" element={<AdminRBAC />} />
        <Route path="/admin/employees" element={<Employee />} />
        <Route path="/admin/contractors" element={<Contractors />} /> 
        <Route path="/admin/services" element={<Services />} />
        <Route path="/admin/agents" element={<ReferralAgent />} />
        <Route path="/admin/newarticle" element={<ArticleForm />} />
        <Route path="/admin/allarticles" element={<ArticleList />} />


        <Route path="/unauthorized" element={<Unauthorized />} />

       
        <Route path="/analytics" element={<Analytics />} />

        <Route path="/analytics/revenue" element={<RevenueByServiceType />} />
        <Route path="/analytics/servicedemand" element={<ServiceDemandTrends />} />

        <Route path="/analytics/operations" element={<OperationalAnalytics />} />
        <Route path="/analytics/jobs" element={<JobStatusDashboard />} />
        <Route path="/analytics/customers" element={<CustomerJobHistory />} />
       

      
      <Route path="/mechanics" element={<MechanicTasks />} />


        </LayoutRoutes>
     
  );
}
