import React from "react";
import capitalizeFirstLetter from './utils';

const ComponentDetails = ({ components }) => {
    // Filter out image URL fields and capitalize component names
    const filteredComponents = Object.entries(components)
      .filter(([key, _]) => !key.includes('ImageUrl'))
      .map(([key, value]) => [capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1')), value]);
  
    return (
      <div className='component-details'>
        <h2 className="text-xl font-semibold mb-4">Inspection Component Details</h2>
        <table className="table-auto w-full bg-white">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Component</th>
              <th className="py-3 px-6 text-left">Status</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {filteredComponents.map(([component, status], index) => (
              <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {component}
                </td>
                <td className="py-3 px-6 text-left">
                  {status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  export default ComponentDetails;