import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import EmployeeLeave from '../Leave/EmployeeLeave';
import ManagerLeaveApproval from './ManagerLeaveApproval';
import { MoonLoader } from 'react-spinners';

const EmployeeProfile = () => {
  const [employee, setEmployee] = useState(null);
  const [managerName, setManagerName] = useState(null);
  const [pendingRequests, setPendingRequests] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (user) {
        const docRef = doc(db, 'employees', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const employeeData = docSnap.data();
          setEmployee(employeeData);
    
          // Fetch manager's name if a manager is assigned
          if (employeeData.designatedManagerId) {
            const managerDocRef = doc(db, 'employees', employeeData.designatedManagerId);
            const managerDocSnap = await getDoc(managerDocRef);
            if (managerDocSnap.exists()) {
              const managerData = managerDocSnap.data();
              setManagerName(`${managerData.firstName} ${managerData.lastName}`);
            }
          }
    
          // If the user is a manager, check for pending leave requests for employees they manage
          if (employeeData.isManager) {
            const employeesQuery = query(
              collection(db, 'employees'),
              where('designatedManagerId', '==', user.uid)
            );
            const employeesSnapshot = await getDocs(employeesQuery);
            const managedEmployeeIds = employeesSnapshot.docs.map(doc => doc.id);
    
            if (managedEmployeeIds.length > 0) {
              const batchSize = 10; // Firestore supports up to 10 IDs in an "in" query
              const batches = [];
              for (let i = 0; i < managedEmployeeIds.length; i += batchSize) {
                const batch = managedEmployeeIds.slice(i, i + batchSize);
                batches.push(
                  getDocs(query(
                    collection(db, 'leaveRequests'),
                    where('status', '==', 'Pending'),
                    where('employeeId', 'in', batch)
                  ))
                );
              }
    
              const leaveRequestsSnapshots = await Promise.all(batches);
              const pendingRequests = leaveRequestsSnapshots.flatMap(snapshot =>
                snapshot.docs.map(doc => ({
                  id: doc.id, // Add the document ID here
                  ...doc.data(),
                }))
              );
    
              setPendingRequests(pendingRequests);
            } else {
              setPendingRequests([]); // Clear pending requests if no employees are managed
            }
          }
        } else {
          console.error('No such document!');
        }
      }
    };
    


    fetchEmployeeData();
  }, [user]);

  if (!employee) {
    return (
      <div className="flex justify-center items-center h-full">
        <MoonLoader size={50} color={"#4A90E2"} />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg mt-2 rounded-lg p-2 space-y-2">
      <div className="text-center">
        <div className="w-32 h-32 mx-auto rounded-full bg-gray-200 flex items-center justify-center">
          <span className="text-4xl font-semibold text-gray-600">
            {employee.firstName[0]}{employee.lastName[0]}
          </span>
        </div>
        <h2 className="text-2xl font-semibold text-gray-900 mt-4">
          {employee.firstName} {employee.lastName}
        </h2>
        <p className="text-sm text-gray-500">{employee.role}</p>
        <p><strong>Manager:</strong> {managerName || 'None'}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50 p-4 rounded-lg shadow-sm">

        <EmployeeLeave employeeId={user.uid} />

        </div>

        <div className="p-2 rounded-lg shadow-sm">
        {employee.isManager && pendingRequests.length > 0 && (
          <div className="bg-white p-2 rounded-lg shadow-lg mt-1">
            <ManagerLeaveApproval managerId={user.uid} pendingRequests={pendingRequests} />
          </div>
        )}
        </div>

       
      </div>
    </div>
  );
};

export default EmployeeProfile;
