import React from 'react';
 import { Link, Navigate} from 'react-router-dom';
import { TruckIcon, ClockIcon,MagnifyingGlassCircleIcon, PlusCircleIcon,WrenchIcon, PresentationChartLineIcon} from '@heroicons/react/20/solid';
 import BreadCrumb from '../BreadCrumb';
 import { useAuth } from '../Auth/Auth';


  const actions = [

    {
      title: 'Manage Vehicle',
      to: '/vehicles/manage',
      desc: "Register Customer & Vehicle, View History, Create Job",
      component: Link,
      icon: TruckIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    {
      title: 'Manage Open Jobs',
      to: '/vehicles/openjobs',
      desc: "Update the Status of Open Job Cards",
      icon: WrenchIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },

    {
      title: 'Diagnostic Questionnaires',
      to: '/vehicles/diagq',
      desc: "Update the Status of DQs",
      icon: WrenchIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },



    {
      title: 'Closed Jobs',
      to: '/vehicles/closedjobs',
      desc: "View Closed Job Cards",
      icon: WrenchIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },



    



    {
      title: 'All Jobs',
      to: '/vehicles/alljobs',
      desc: "View All Jobs - Active and Closed ",
      icon: MagnifyingGlassCircleIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },

  
    {
        title: 'Scheduled Appointments',
        to: '/vehicles/appointments',
        icon: ClockIcon,
        component: Link,
        desc: "View Appointment",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
  
      {
        title: 'Task Scheduler',
        to: '/vehicles/assigntask',
        icon: PlusCircleIcon,
        component: Link,
        desc: "Assign Task to Mechanic",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
  
       
      {
        title: 'Mechanic Performance',
        to: '/vehicles/mechanicperformance',
        icon: PresentationChartLineIcon,
        component: Link,
        desc: "Analytics on Job Cards and Engineers",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
  
      {
        title: 'Manager Task Monitoring',
        to: '/vehicles/managertasks',
        icon: PresentationChartLineIcon,
        component: Link,
        desc: "Manager Tracking and Updates on Tasks",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
    
        
      {
        title: 'Task Dashboard',
        to: '/vehicles/taskdashboard',
        icon: PresentationChartLineIcon,
        component: Link,
        desc: "View New, In-Progress & Closed Count",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
     
      {
        title: 'Task Completion Report',
        to: '/vehicles/taskcompleted',
        icon: PresentationChartLineIcon,
        component: Link,
        desc: "Analytics on Completed Tasks",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
     
        
      {
        title: 'Mechanic Task Calendar',
        to: '/vehicles/mechanic-calendar',
        icon: PresentationChartLineIcon,
        component: Link,
        desc: "Analytics on Completed Tasks",
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      },
     
   
   
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: true },
     ];

const Vehicle = () => {
  const { role, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Service Advisor' && role !== 'Admin') {
   
    return <Navigate to="/unauthorized" replace />; 
  }


  return (
    <>

<BreadCrumb pages={pages} />

     <div className='flex justify-center items-center pt-6'>
    <div className="divide-y divide-gray-50 grid grid-cols-4 gap-2 overflow-hidden rounded-lg bg-gray-200 shadow">
        {actions.map((action, actionIdx) => (
            <div
                key={action.title}
                className={classNames(
                    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 transform transition duration-500 ease-in-out hover:scale-105'
                )}
            >
                <div className="flex items-center justify-center">
                    <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'inline-flex rounded-lg p-3 ring-4 ring-white'
                        )}
                    >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div className="mt-8">
                    <h3 className="text-base font-semibold text-center leading-6 text-gray-900">
                        <Link to={action.to} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            {action.title}
                        </Link>
                    </h3>
                    <p className="mt-2 text-sm text-center text-gray-500">
                        {action.desc} 
                    </p>
                </div>
                <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    aria-hidden="true"
                >
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
        ))}
    </div>
</div>


 

</>
  )
}

export default Vehicle