// App.js
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './components/Auth/ProtectedRoutes';
import LoginForm from './components/Auth/LoginForm';
import { AuthProvider } from './components/Auth/Auth';
import { FormProvider } from './components/Inspection/FormContext';
import NotAuthorizedPage from './components/Auth/NotAuthorizedPage';


function App() {
  return (
    <AuthProvider>
        <FormProvider>
    <Routes>
      <Route path="login" element={<LoginForm />} />
      <Route path="not-authorized" element={<NotAuthorizedPage />} />
          <Route path="*" element={<ProtectedRoutes />} />                    
        </Routes>
    </FormProvider>
    </AuthProvider>
  );
}

export default App;
