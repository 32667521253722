import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import { toast, ToastContainer } from 'react-toastify';
import Accordion from '../Accordion';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import BreadCrumb from '../BreadCrumb';




const EditJobComponent = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [editedJob, setEditedJob] = useState({ servicesRequired: [], partsRequired: [] });
  const navigate = useNavigate();
  const { user } = useAuth();

  const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
    { name: 'Open Job Cards', to: '/vehicles/openjobs', component: Link, current: false },
    { name: `Edit Job Card`, to: `/vehicles/jobs/edit/${jobId}`, component: Link, current: true },
  ];


  useEffect(() => {
    const fetchJob = async () => {
      const docRef = doc(db, 'jobs', jobId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const fetchedJob = { id: docSnap.id, ...docSnap.data() };
        setJob(fetchedJob);
        setEditedJob(fetchedJob); 
      } else {
        toast.error("No such document!");
      }
    };
  
    fetchJob();
  }, [jobId]);


  useEffect(() => {
    setEditedJob({ ...job, servicesRequired: job?.servicesRequired || [], partsRequired: job?.partsRequired || [] });
  }, [job]);
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedJob(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handlePartChange = (e, index, field) => {
    const { value } = e.target;
    setEditedJob(prevState => {
      const updatedParts = [...prevState.partsRequired];
      updatedParts[index] = { ...updatedParts[index], [field]: value };
      return { ...prevState, partsRequired: updatedParts };
    });
  };
  

  const handleServiceChange = (e, index, field) => {
    const { value } = e.target;
    setEditedJob(prevState => {
      const updatedServices = [...prevState.servicesRequired];
      updatedServices[index] = { ...updatedServices[index], [field]: value };
      return { ...prevState, servicesRequired: updatedServices };
    });
  };

  
  
  const addNewService = () => {
    const newService = { serviceType: '', serviceQuantity: 0, technicianType: '', serviceCost: '' }; // Added serviceCost initialization here
    setEditedJob({ ...editedJob, servicesRequired: [...editedJob.servicesRequired, newService] });
  };

  const deleteService = (index) => {
    const updatedServices = editedJob.servicesRequired.filter((_, idx) => idx !== index);
    setEditedJob({ ...editedJob, servicesRequired: updatedServices });
  };

  const addNewPart = () => {
    const newPart = { partName: '', price: '', quantity: 1 };
    setEditedJob({ ...editedJob, partsRequired: [...editedJob.partsRequired, newPart] });
  };

  const deletePart = (index) => {
    const updatedParts = editedJob.partsRequired.filter((_, idx) => idx !== index);
    setEditedJob({ ...editedJob, partsRequired: updatedParts });
  };

  const handleAdditionalObservationsChange = (e) => {
    const { value } = e.target;
    setEditedJob(prevState => ({
      ...prevState,
      additionalObservations: value,
    }));
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'jobs', job.id), {
        ...editedJob, 
        editedBy: user.email,
        editedDate: Timestamp.now(),
      });
  
      toast.success('Job Card updated successfully!');
      setTimeout(() => navigate('/vehicles/openjobs'), 2000);
      } catch (error) {
      console.error('Error updating document:', error);
      toast.error('Failed to update job. Please try again.');
    }
  };


  const handleCancel = () => {
    toast.error("Cancelling Edit and Redirecting");
    setTimeout(() => navigate('/vehicles/openjobs'), 2000);
   
  };

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <>  
    <BreadCrumb pages={pages} />
    <div>
      <ToastContainer />
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Job Card: {editedJob.jobCardNo} {editedJob.vehicleMake} {editedJob.vehicleModel} {editedJob.vehicleYear} </h3>
                       <form onSubmit={handleSubmit}>

                              <div className="mt-2 px-7 py-3">

                                      <Accordion title="Technician Observation">
                                        <div className='mb-2'>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="technicianObservation">
                                          Technician Observation
                                        </label>
                                        <textarea
                                          name="technicianObservation"
                                          rows={15}
                                          value={editedJob.technicianObservation}
                                          onChange={handleChange}
                                          className="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                        </div>
                                    </Accordion>
                    
                                        <Accordion title="Parts Required">
                                        <div className="mt-2 overflow-x-auto">
                                          <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                              <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  Part Name
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  Quantity
                                                </th>
                                                <th scope="col" className="relative px-6 py-3">
                                                  <span className="sr-only">Edit</span>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                              {editedJob.partsRequired.map((part, index) => (
                                                <tr key={index}>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    <input
                                                      type="text"
                                                      name="partName"
                                                      value={part.partName}
                                                      onChange={(e) => handlePartChange(e, index, 'partName')}
                                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                      placeholder="Part Name"
                                                    />
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <input
                                                      type="number"
                                                      name="quantity"
                                                      value={part.quantity}
                                                      onChange={(e) => handlePartChange(e, index, 'quantity')}
                                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                      placeholder="Quantity"
                                                    />
                                                  </td>
                                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <button
                                                      type="button"
                                                      onClick={() => deletePart(index)}
                                                      className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                      Delete
                                                    </button>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          <button 
                                            type="button" 
                                            onClick={addNewPart} 
                                            className="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                          >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Add New Part
                                          </button>
                                        </div>
                                      </Accordion>
              
                                      <Accordion title="Services Required">
                                        <div className="mt-2 overflow-x-auto">
                                          <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                              <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  Service Description
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Service Cost
                                                  </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                  Quantity/Duration
                                                </th>
                                                <th scope="col" className="relative px-6 py-3">
                                                  <span className="sr-only">Edit</span>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                              {editedJob.servicesRequired && editedJob.servicesRequired?.map((service, index) => (
                                                <tr key={index}>
                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">                         
                                                  <input
                                                    type="text"
                                                    name="serviceType"
                                                    value={service.serviceType}
                                                    onChange={(e) => handleServiceChange(e, index, 'serviceType')}
                                                    placeholder="Service Type"
                                                    className="col-span-5 appearance-none border rounded px-3 py-1.5 text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset"
                                                  />
                                                  </td>

                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <input
                                                      type="number"
                                                      name="serviceCost"
                                                      value={service.serviceCost}
                                                      onChange={(e) => handleServiceChange(e, index, 'serviceCost')}
                                                      placeholder="Service Cost"
                                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                  </td>

                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <input
                                                      type="number"
                                                      name="serviceQuantity"
                                                      value={service.serviceQuantity}
                                                      onChange={(e) => handleServiceChange(e, index, 'serviceQuantity')}
                                                      placeholder="Quantity"
                                                      className="col-span-4 shadow appearance-none border rounded py-1.5 px-3 text-gray-900 text-sm leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-inset"
                                                    />
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                      <button 
                                                        type="button" 
                                                        onClick={() => deleteService(index)} 
                                                        className="col-span-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded inline-flex items-center justify-center">
                                                        <TrashIcon className="h-5 w-5"/>
                                                      </button>                                       
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>
                                              <button 
                                                type="button" 
                                                onClick={addNewService} 
                                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center">
                                                <PlusIcon className="h-5 w-5 mr-2" />
                                                Add New Service
                                                </button>
                                                </div>
                                        </Accordion>
              
                                        <Accordion title="Work Required">
                                          <div className="mt-4 px-7 py-3">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="workRequired">
                                              Work Required
                                            </label>
                                            <textarea
                                              name="workRequired"
                                              rows={5}
                                              value={editedJob.workRequired || ''}
                                              onChange={handleChange}
                                              placeholder="Describe the work required"
                                              className="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                          </div>
                                      </Accordion>

                                        <div className="mt-4 px-7 py-3">
                                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="additionalObservations">
                                            Additional Observations
                                          </label>
                                          <textarea
                                            name="additionalObservations"
                                            value={editedJob.additionalObservations || ''}
                                            onChange={handleAdditionalObservationsChange}
                                            placeholder="Additional Observations"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                          />
                                        </div>

                              </div>         

                                        <div className="flex justify-between mt-4">
                                          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Save Changes
                                          </button>
                                          <button onClick={handleCancel} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                            Cancel
                                          </button>
                                       </div>
                    </form>
                  </div>
                </div>
                </>
              );
            };

            export default EditJobComponent;