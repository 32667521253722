import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';


const AdminRBAC = () => {
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [features, setFeatures] = useState([]);
    const [rolePermissions, setRolePermissions] = useState({});

    useEffect(() => {
        const fetchRolesAndFeatures = async () => {
            // Fetch roles
            const rolesSnapshot = await getDocs(collection(db, "roles"));
            const rolesData = rolesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Fetch features
            const featuresSnapshot = await getDocs(collection(db, "features"));
            const featuresData = featuresSnapshot.docs.map(doc => doc.data().name);

            setRoles(rolesData);
            setFeatures(featuresData);

            // Set existing role permissions
            const newRolePermissions = {};
            rolesData.forEach(role => {
                newRolePermissions[role.id] = role.permissions || [];
            });
            setRolePermissions(newRolePermissions);
        };

        fetchRolesAndFeatures();
    }, []);

    const handleAddRole = async () => {
        if (newRole) {
            // Check if the role already exists
            const existingRole = roles.find(role => role.name.toLowerCase() === newRole.toLowerCase());
            if (existingRole) {
                toast.error("Role already exists");
                return;
            }
    
            try {
                const docRef = await addDoc(collection(db, "roles"), { name: newRole, permissions: [] });
                setRoles([...roles, { id: docRef.id, name: newRole, permissions: [] }]);
                setNewRole('');
                toast.success("Role added successfully");
            } catch (error) {
                toast.error("Error adding role: " + error.message);
            }
        } else {
            toast.error("Role name cannot be empty");
        }
    };
    

    const handlePermissionChange = (roleId, feature, isChecked) => {
        const updatedPermissions = { ...rolePermissions };
        if (isChecked) {
            if (updatedPermissions[roleId]) {
                updatedPermissions[roleId].push(feature);
            } else {
                updatedPermissions[roleId] = [feature];
            }
        } else {
            updatedPermissions[roleId] = updatedPermissions[roleId].filter(f => f !== feature);
        }
        setRolePermissions(updatedPermissions);
    };

    const handleSubmitPermissions = async (roleId) => {
        try {
            const roleRef = doc(db, "roles", roleId);
            await updateDoc(roleRef, { permissions: rolePermissions[roleId] || [] });
            toast.success("Permissions updated successfully");
        } catch (error) {
            toast.error("Error updating permissions: " + error.message);
        }
    };

    

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-xl font-semibold mb-3">Manage Roles and Permissions</h2>
            <div className="mb-5 flex">
                <input 
                    type="text" 
                    value={newRole} 
                    onChange={(e) => setNewRole(e.target.value)} 
                    placeholder="New Role Name" 
                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                
                <button 
                    onClick={handleAddRole} 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Add Role
                </button>
            </div>

            <div className="overflow-x-auto">
            <table className="min-w-full text-sm shadow-md rounded">
                <thead className="bg-gray-100">
                    <tr>
                        <th className="px-2 py-2">Permissions | Roles</th>
                        {roles.map(role => (
                            <th key={role.id} className="px-4 py-2">{role.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {features.map(feature => (
                        <tr key={feature}>
                            <td className="border px-4 py-2">{feature}</td>
                            {roles.map(role => (
                                <td key={role.id} className="border px-2 py-2 text-center">
                                    <input 
                                        type="checkbox"
                                        checked={rolePermissions[role.id]?.includes(feature)}
                                        onChange={(e) => handlePermissionChange(role.id, feature, e.target.checked)}
                                        className="h-4 w-4 rounded border-gray-600 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={roles.length + 1} className="text-right border px-2 py-2 ">
                            {roles.map(role => (
                                <button
                                    key={role.id}
                                    className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold py-2 px-4 rounded ml-2"
                                    onClick={() => handleSubmitPermissions(role.id)}
                                >
                                    Update {role.name}
                                </button>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ToastContainer />
    </div>

);
};

export default AdminRBAC;