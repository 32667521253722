import React, { useState } from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';


const PartsAndServices = ({ jobCard, handleAddPart, handleAddService }) => {
    const [partsData, setPartsData] = useState(jobCard.partsRequired || [{ partName: '', type: 'generic', quantity: 1 }]);
    const [servicesData, setServicesData] = useState(jobCard.servicesRequired || [{ serviceType: '', technicianType: '', serviceQuantity: '' }]);
    const isJobClosed = jobCard?.status === 'Closed';


    const handlePartsInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedParts = [...partsData];
        updatedParts[index] = { ...updatedParts[index], [name]: value };
        setPartsData(updatedParts);
    };

    const handleServiceInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedServices = [...servicesData];
        updatedServices[index] = { ...updatedServices[index], [name]: value };
        setServicesData(updatedServices);
    };

    const handleAddPartClick = () => {
        const newPart = { partName: '', type: 'generic', quantity: 1 };
        const updatedParts = [...partsData, newPart];
        setPartsData(updatedParts);
        handleAddPart(newPart); // Call parent handler to update the data in Firestore
    };

    const handleRemovePartClick = (index) => {
        const updatedParts = [...partsData];
        updatedParts.splice(index, 1);
        setPartsData(updatedParts);
        // Handle part removal with your logic
    };

    const handleAddServiceClick = () => {
        const newService = { serviceType: '', technicianType: '', serviceQuantity: '' };
        const updatedServices = [...servicesData, newService];
        setServicesData(updatedServices);
        handleAddService(newService); // Call parent handler to update the data in Firestore
    };

    const handleRemoveServiceClick = (index) => {
        const updatedServices = [...servicesData];
        updatedServices.splice(index, 1);
        setServicesData(updatedServices);
        // Handle service removal with your logic
    };

    const incrementPartQuantity = (index) => {
        const updatedParts = [...partsData];
        updatedParts[index].quantity = Number(updatedParts[index].quantity) + 1;
        setPartsData(updatedParts);
    };

    const decrementPartQuantity = (index) => {
        const updatedParts = [...partsData];
        if (updatedParts[index].quantity > 1) {
            updatedParts[index].quantity = Number(updatedParts[index].quantity) - 1;
            setPartsData(updatedParts);
        }
    };

    return (
        <div className="space-y-8">
            {/* Parts Required */}
            <div className="mt-4 bg-gray-50 mb-2 pl-4 pb-2">
                <label className="block text-gray-500 font-bold mb-2 uppercase pt-2">Parts Required</label>
                <div className="grid grid-cols-4 gap-2 font-semibold text-sm text-gray-700 text-center">
                    <div>Part Name</div>
                    <div>Type</div>
                    <div>Quantity</div>
                    <div>Action</div>
                </div>

                {partsData.map((item, index) => (
                    <div className="grid grid-cols-4 gap-2 items-center mb-1" key={index}>
                        <input
                            name="partName"
                            value={item.partName}
                            disabled={isJobClosed} 
                            onChange={(e) => handlePartsInputChange(e, index)}
                            className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Part Name"
                        />
                        <select
                            name="type"
                            value={item.type}
                            disabled={isJobClosed} 
                            onChange={(e) => handlePartsInputChange(e, index)}
                            className="shadow appearance-none border rounded py-1 px-3 text-sm w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="generic">Generic Spare Part</option>
                            <option value="vehicle-specific">Vehicle Specific Spare Part</option>
                        </select>
                        <div className='flex items-center justify-center'>
                            <button
                                type="button"
                                disabled={isJobClosed} 
                                onClick={() => decrementPartQuantity(index)}
                                className="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-10 w-8 rounded-l cursor-pointer"
                            >
                                -
                            </button>
                            <input
                                type="text"
                                name="quantity"
                                disabled={isJobClosed} 
                                className="shadow appearance-none border rounded text-sm py-1 px-3 text-center w-1/5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={item.quantity}
                                onChange={(e) => handlePartsInputChange(e, index)}
                            />
                            <button
                                type="button"
                                disabled={isJobClosed} 
                                onClick={() => incrementPartQuantity(index)}
                                className="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-10 w-8 rounded-r cursor-pointer"
                            >
                                +
                            </button>
                        </div>
                        <div className="flex justify-center">
                            {partsData.length !== 1 && (
                                <MinusCircleIcon
                                    className="h-6 w-6 text-red-500 cursor-pointer"
                                    onClick={() => handleRemovePartClick(index)}
                                    disabled={isJobClosed} 
                                />
                            )}
                            {partsData.length - 1 === index && (
                                <PlusCircleIcon
                                    className="h-6 w-6 text-green-500 cursor-pointer"
                                    onClick={handleAddPartClick}
                                    disabled={isJobClosed} 
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {/* Services Required */}
            <div className="mt-4 bg-blue-50 mb-2 pl-4 pb-2">
                <label className="block text-gray-700 font-bold mb-2 uppercase">Services Required</label>
                <div className="grid grid-cols-4 gap-2 font-semibold text-gray-700 text-center">
                    <div>Service Description</div>
                    <div>Technician Type</div>
                    <div>Quantity/Duration</div>
                    <div>Action</div>
                </div>

                {servicesData.map((item, index) => (
                    <div className="grid grid-cols-4 gap-2 items-center mb-2" key={index}>
                        <input
                            name="serviceType"
                            placeholder="Service Description"
                            value={item.serviceType}
                            disabled={isJobClosed} 
                            onChange={(event) => handleServiceInputChange(event, index)}
                            className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <input
                            type="text"
                            name="technicianType"
                            placeholder="Technician Type"
                            value={item.technicianType}
                            disabled={isJobClosed} 
                            onChange={(event) => handleServiceInputChange(event, index)}
                            className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <div className="flex justify-center">
                        <input
                            type="number"
                            name="serviceQuantity"
                            placeholder="Quantity or Duration"
                            value={item.serviceQuantity}
                            disabled={isJobClosed} 
                            onChange={(event) => handleServiceInputChange(event, index)}
                             className="shadow appearance-none border rounded text-sm py-1 px-3 text-center w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        </div>

                        <div className="flex justify-center">
                            {servicesData.length !== 1 && (
                                <MinusCircleIcon
                                    className="h-6 w-6 text-red-500 cursor-pointer"
                                    onClick={() => handleRemoveServiceClick(index)}
                                    disabled={isJobClosed} 
                                />
                            )}
                            {servicesData.length - 1 === index && (
                                <PlusCircleIcon
                                    className="h-6 w-6 text-green-500 cursor-pointer"
                                    onClick={handleAddServiceClick}
                                    disabled={isJobClosed} 
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartsAndServices;
