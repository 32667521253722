
export function generateCustomerNumber(firstName, lastName, customerType, sequentialNumber) {
    const timestamp = new Date().toISOString().slice(0, 6); // Shortened timestamp for example
    const initials = `${firstName.slice(0, 2).toUpperCase()}${lastName.slice(0, 2).toUpperCase()}`;
    
    // Map 'Private' to 'I' and 'Corporate' to 'C'
    let customerTypeCode;
    if (customerType === 'Private') {
      customerTypeCode = 'I'; // Individual
    } else if (customerType === 'Corporate') {
      customerTypeCode = 'C'; // Corporate
    } else {
      throw new Error('Unknown customer type');
    }
    
    // Format sequential number to 3 digits
    const sequential = String(sequentialNumber).padStart(3, '0');
  
    // Generate final customer number
    return `${timestamp}${initials}${customerTypeCode}${sequential}`;
  }