import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import { Link, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import BreadCrumb from '../BreadCrumb';
import moment from 'moment';
import { MoonLoader } from 'react-spinners';


const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Monitor Mechanic Tasks', to: '/vehicles/mechanicperformance', component: Link, current: true },
];

const ManagerMechanicTasks = () => {
  const [mechanics, setMechanics] = useState([]);
  const [selectedMechanic, setSelectedMechanic] = useState('');
  const [verificationComments, setVerificationComments] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { user, role, loading } = useAuth();

  useEffect(() => {
    const fetchMechanics = async () => {
      const q = query(collection(db, "employees"), where("role", "==", "Mechanic"));
      const querySnapshot = await getDocs(q);
      setMechanics(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchMechanics();
  }, []);

  useEffect(() => {
    if (selectedMechanic) {
      const fetchTasks = async () => {
        const q = query(collection(db, "tasks"), where("assignedTo", "==", selectedMechanic));
        const querySnapshot = await getDocs(q);
        const loadedTasks = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          allSubtasksCompleted: doc.data().subtasks.every(subtask => subtask.status === 'completed')
        }));
        setTasks(loadedTasks);
      };
      fetchTasks();
    }
  }, [selectedMechanic]);

  const handleMechanicChange = (e) => {
    setSelectedMechanic(e.target.value);
    setSelectedTask(null); // Clear selected task when changing mechanic
  };

  const handleTaskSelection = (task) => {
    setSelectedTask(task);
    setShowModal(true); 
    setShowVerificationModal(task.allSubtasksCompleted && task.status === 'completed' && !task.taskVerified);
  };

  const updateTaskStatus = async (taskId, newStatus) => {
    const taskRef = doc(db, "tasks", taskId);
    try {
      await updateDoc(taskRef, {
        status: newStatus,
        updatedAt: Timestamp.now(),
        ...(newStatus === 'completed' && { completedAt: Timestamp.now() })
      });
      toast.success('Task status updated');
      setTasks(tasks.map(task => task.id === taskId ? { ...task, status: newStatus, ...(newStatus === 'completed' && { completedAt: Timestamp.now() }) } : task));
    } catch (error) {
      console.error('Error updating task status:', error);
      toast.error('Failed to update task status');
    }
  };


  const updateSubtaskStatus = async (taskId, subtaskId, newStatus) => {
    const taskRef = doc(db, "tasks", taskId);
    const taskSnapshot = await getDoc(taskRef);
    if (taskSnapshot.exists()) {
      const taskData = taskSnapshot.data();
      const updatedSubtasks = taskData.subtasks.map(subtask =>
        subtask.id === subtaskId ? { ...subtask, status: newStatus } : subtask
      );

      const allSubtasksCompleted = updatedSubtasks.every(subtask => subtask.status === 'completed');

      await updateDoc(taskRef, {
        subtasks: updatedSubtasks,
        updatedAt: Timestamp.now(),
        ...(allSubtasksCompleted && { status: 'completed', completedAt: Timestamp.now() })
      });

      toast.success('Subtask status updated successfully');
      setTasks(tasks.map(task => task.id === taskId ? { ...task, subtasks: updatedSubtasks } : task));
      setSelectedTask(current => current && current.id === taskId ? { ...current, subtasks: updatedSubtasks } : current);

      // Update the tracker in the corresponding job document if all subtasks are completed
      if (allSubtasksCompleted) {
        const jobRef = doc(db, 'jobs', taskData.jobCardNo);
        const jobSnapshot = await getDoc(jobRef);

        if (jobSnapshot.exists()) {
          const jobData = jobSnapshot.data();
          const updatedTracker = [
            ...jobData.tracker,
            {
              Date: Timestamp.now(),
              State: 'Repair Concluded',
              comments: `All subtasks for task "${taskData.taskTitle}" have been completed.`
            }
          ];

          await updateDoc(jobRef, { tracker: updatedTracker });
        }
      }
    }
  };

  const verifyTask = async () => {
    if (selectedTask) {
      const taskRef = doc(db, "tasks", selectedTask.id);
      try {
        await updateDoc(taskRef, {
          taskVerified: true,
          verifiedBy: user.email,
          verifiedAt: Timestamp.now(),
          verificationComments
        });
        toast.success('Task verified successfully');
        // Update local state to reflect changes
        setTasks(tasks.map(task => task.id === selectedTask.id ? { ...task, taskVerified: true, verificationComments } : task));
        setShowVerificationModal(false);
      } catch (error) {
        console.error('Error verifying task:', error);
        toast.error('Failed to verify task');
      }
    }
  };

 

  const calculateTimeDifference = (start, end) => {
    if (!start || !end) return '';
    const duration = moment.duration(moment(end.toDate()).diff(moment(start.toDate())));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours} hour(s) and ${minutes} minute(s)`;
  };

  const checkOnTime = (deadline, completedAt) => {
    if (!deadline || !completedAt) return '';
    const deadlineTime = moment(deadline.toDate());
    const completedTime = moment(completedAt.toDate());
    const difference = completedTime.diff(deadlineTime, 'hours', true);
    const status = difference <= 0 ? 'early' : 'late';
    const statusColor = difference <= 0 ? 'text-green-600' : 'text-red-600';
    return (
      <span className={statusColor}>
        Completed {Math.abs(difference).toFixed(2)} hours {status}
      </span>
    );
  };

  if (loading) {
    return (
        <div className="flex justify-center items-center h-64">
            <MoonLoader size={50} color="#4A90E2" />
        </div>
    );
}

  if (role !== 'Service Advisor' && role !== 'Admin') {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <>
      <BreadCrumb pages={pages} />

      <div className="flex flex-col p-4">
        <div>
          <label htmlFor="mechanic-select" className="block text-sm font-medium text-gray-700">Select Mechanic:</label>
          <select
            id="mechanic-select"
            value={selectedMechanic}
            onChange={handleMechanicChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">Select a Mechanic</option>
            {mechanics.map(mechanic => (
              <option key={mechanic.id} value={mechanic.id}>{mechanic.firstName} {mechanic.lastName}</option>
            ))}
          </select>
        </div>

        {tasks.length > 0 && (
          <div className="mt-4">
            <h2 className="text-lg font-semibold text-gray-900">Assigned Tasks</h2>
            {tasks.map(task => (
              <div key={task.id} className="p-4 border rounded-lg mb-4 bg-white shadow">
                <h3 className="text-lg font-semibold text-gray-800">{task.taskTitle}</h3>
                <p className="text-sm text-gray-600">Start Date: {moment(task.startDate.toDate()).format('LLL')}</p>
                <p className="text-sm text-gray-600">Deadline: {moment(task.deadline.toDate()).format('LLL')}</p>
                <p className="text-sm text-gray-600">Assigned To: {task.assignedToName}</p>
                <p className="text-sm text-gray-600">Assigned By: {task.assignedBy}</p>
                <p className="text-sm text-gray-600">Status: {task.status}</p>
                {task.status === 'completed' && (
                  <>
                    <p className="text-sm text-gray-600">Completed At: {moment(task.completedAt.toDate()).format('LLL')}</p>
                    <p className="text-sm text-gray-600">Time Taken to Start: {calculateTimeDifference(task.startDate, task.startedAt)}</p>
                    <p className="text-sm text-gray-600">Time Taken to Complete: {calculateTimeDifference(task.deadline, task.completedAt)}</p>
                    <p className="text-sm font-semibold">{checkOnTime(task.deadline, task.completedAt)}</p>
                  </>
                )}
                <button onClick={() => handleTaskSelection(task)} className="mt-2 inline-flex items-center px-3 py-1 bg-blue-500 text-white text-sm font-medium rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
                  View Details / Edit
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Modal for task details and actions */}
        {showModal && selectedTask && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-lg shadow-lg">
              <h3 className="font-bold text-lg mb-4">Task Details</h3>
              <p><strong>Title:</strong> {selectedTask.taskTitle}</p>
              <p><strong>Status:</strong> {selectedTask.status}</p>
              <p><strong>Assigned By:</strong> {selectedTask.assignedBy}</p>
              {selectedTask.status === 'completed' && (
                <>
                  <p><strong>Completed At:</strong> {moment(selectedTask.completedAt.toDate()).format('LLL')}</p>
                  <p><strong>Time Taken to Start:</strong> {calculateTimeDifference(selectedTask.startDate, selectedTask.startedAt)}</p>
                  <p><strong>Time Taken to Complete:</strong> {calculateTimeDifference(selectedTask.deadline, selectedTask.completedAt)}</p>
                  <p><strong>{checkOnTime(selectedTask.deadline, selectedTask.completedAt)}</strong></p>
                </>
              )}
              {selectedTask.status !== 'completed' && (
                <button onClick={() => updateTaskStatus(selectedTask.id, 'completed')} className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  Mark as Completed
                </button>
              )}
              <table className="min-w-full leading-normal mt-4">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Description
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTask.subtasks.map((subtask, index) => (
                    <tr key={index}>
                      <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subtask.description}</td>
                      <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                        <select
                          value={subtask.status}
                          onChange={(e) => updateSubtaskStatus(selectedTask.id, subtask.id, e.target.value)}
                          className="px-2 py-1 rounded bg-white border text-sm border-gray-300 shadow-inner focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                        >
                          <option value="pending">Pending</option>
                          <option value="in-progress">In Progress</option>
                          <option value="completed">Completed</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => setShowModal(false)} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                Close
              </button>
            </div>
          </div>
        )}

        {/* Modal for task verification */}
        {showVerificationModal && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
            <div className="bg-white p-4 rounded-lg max-w-lg">
              <h3 className="font-bold text-lg">Verify Task</h3>
              <textarea
                value={verificationComments}
                onChange={e => setVerificationComments(e.target.value)}
                placeholder="Enter verification comments..."
                className="w-full h-32 p-2 border border-gray-300 rounded"
              />
              <button onClick={verifyTask} className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit Verification
              </button>
              <button onClick={() => setShowVerificationModal(false)} className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                Cancel
              </button>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default ManagerMechanicTasks;
