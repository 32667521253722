import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import Select from 'react-select';
import ScheduleAppointment from '../Tasks/Modals/ScheduleAppointment';
import AppointmentCalendar from '../Tasks/AppointmentsCalendar';
import BreadCrumb from '../BreadCrumb';
import { Dialog } from '@headlessui/react';



const pages = [
  { name: 'Customer Care', to: '/customers', component: Link, current: false },
  { name: 'Customer Appointments', to: '/customers/appointments', component: Link, current: true },
];

const AppointmentsManager = () => {
  const [vehicles, setVehicles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [selectedVehicleForAppointment, setSelectedVehicleForAppointment] = useState(null);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [nextServiceDate, setNextServiceDate] = useState('');
  const [odometerReading, setOdometerReading] = useState('');

  useEffect(() => {
    const unsubscribeFromVehicles = onSnapshot(collection(db, 'vehicles'), (snapshot) => {
      const vehicleList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehicles(vehicleList);
    });

    const unsubscribeFromCustomers = onSnapshot(collection(db, 'customers'), (snapshot) => {
      const customerList = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: `${doc.data().firstName} ${doc.data().lastName}`,
      }));
      setCustomers(customerList);
    });

    return () => {
      unsubscribeFromVehicles();
      unsubscribeFromCustomers();
    };
  }, []);

 

  const handleScheduleAppointment = (vehicle) => {
    setSelectedVehicleForAppointment(vehicle);
    setIsAppointmentModalOpen(true);
  };

  const handleServiceDetails = (vehicle) => {
    setSelectedVehicleForAppointment(vehicle);
    setIsServiceModalOpen(true);
  };

  const handleServiceDetailsSubmit = async () => {
    if (selectedVehicleForAppointment) {
      const vehicleRef = doc(db, 'vehicles', selectedVehicleForAppointment.id);
      await updateDoc(vehicleRef, {
        nextServiceDate: new Date(nextServiceDate),
        odometerReading: Number(odometerReading),
      });
      setIsServiceModalOpen(false);
      setNextServiceDate('');
      setOdometerReading('');
    }
  };

  return (
    <>
      <BreadCrumb pages={pages} />

      <div className="grid grid-cols-1 mt-1 gap-4 sm:grid-cols-5">
        <div className="p-4 bg-gray-100 rounded sm:col-span-2">
          <h2 className="text-lg font-medium mb-2">Select Customer</h2>
          <div>
            <label htmlFor="selectedCustomer" className="block text-sm font-medium text-gray-700 px-4">Select Customer</label>
            <Select
              options={customers}
              onChange={setSelectedCustomer}
              value={selectedCustomer}
              className="p-2 pb-2 text-sm"
              placeholder="Select a Customer..."
              isSearchable
            />
          </div>

          <div className="flex-1">
            {vehicles.filter(vehicle => vehicle.customerId === (selectedCustomer?.value || '')).map((vehicle) => (
              <div key={vehicle.id} className="p-4 bg-white shadow-lg rounded-lg">
                <h3 className="font-semibold text-lg">{vehicle.vehicleMake} {vehicle.vehicleModel}</h3>
                <p><span className="font-medium">Year:</span> {vehicle.year}</p>
                <p><span className="font-medium">VIN:</span> {vehicle.VIN}</p>
                <p><span className="font-medium">Reg. Number:</span> {vehicle.registrationNumber}</p>

                <div className="inline-flex rounded-md shadow-sm mt-4">
                  <button
                    type="button"
                    onClick={() => handleScheduleAppointment(vehicle)}
                    className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Schedule Appointment
                  </button>
                  <button
                    type="button"
                    onClick={() => handleServiceDetails(vehicle)}
                    className="ml-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Enter Service Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-4 bg-gray-100 rounded sm:col-span-3">
          <h2 className="text-lg font-medium mb-2">Appointment Calendar</h2>
          <AppointmentCalendar />
        </div>

        {isAppointmentModalOpen && (
          <ScheduleAppointment
            vehicle={selectedVehicleForAppointment}
            customer={customers.find(c => c.value === selectedVehicleForAppointment.customerId)}
            onClose={() => setIsAppointmentModalOpen(false)}
          />
        )}

        {isServiceModalOpen && (
          <Dialog
            open={isServiceModalOpen}
            onClose={() => setIsServiceModalOpen(false)}
            className="fixed inset-0 flex items-center justify-center z-50"
          >
          
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full p-6">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  Enter Service Details
                </Dialog.Title>
                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">Next Service Date</label>
                  <input
                    type="date"
                    value={nextServiceDate}
                    onChange={(e) => setNextServiceDate(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
                  />
                  <label className="block text-sm font-medium text-gray-700 mt-4">Odometer Reading</label>
                  <input
                    type="number"
                    value={odometerReading}
                    onChange={(e) => setOdometerReading(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mt-4 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleServiceDetailsSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setIsServiceModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default AppointmentsManager;
