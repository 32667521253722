import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const CloseJobModal = ({
  isModalOpen,
  setIsModalOpen,
  exitMileage,
  setExitMileage,
  jobClosureComments,
  setJobClosureComments,
  nextServiceMileage,
  setNextServiceMileage,
  deliveryDate,
  setDeliveryDate,
  handleCloseJob,
  renderOdometerInput
}) => {
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                  Close Job
                </Dialog.Title>

                <div className="space-y-4">
                  {/* Exit Mileage */}
                  <div>
                    <label htmlFor="exitMileage" className="block text-sm font-medium text-gray-700">Exit Mileage</label>
                    {renderOdometerInput('exitMileage', exitMileage)}
                  </div>

                  {/* Job Closure Comments */}
                  <div>
                    <label htmlFor="jobClosureComments" className="block text-sm font-medium text-gray-700">Job Closure Comments</label>
                    <textarea
                      id="jobClosureComments"
                      value={jobClosureComments}
                      onChange={(e) => setJobClosureComments(e.target.value)}
                      className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      rows={3}
                      placeholder="Add comments..."
                    />
                  </div>

                  {/* Next Service Mileage */}
                  <div>
                    <label htmlFor="nextServiceMileage" className="block text-sm font-medium text-gray-700">Next Service Mileage</label>
                    {renderOdometerInput('nextServiceMileage', nextServiceMileage)}
                  </div>

                  {/* Delivery Date */}
                  <div>
                    <label htmlFor="deliveryDate" className="block text-sm font-medium text-gray-700">Delivery Date</label>
                    <input
                      type="date"
                      id="deliveryDate"
                      value={deliveryDate}
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="px-5 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="px-5 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={handleCloseJob}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CloseJobModal;
