import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Dialog } from '@headlessui/react';
import LeaveRequestForm from './LeaveRequestForm';
import { toast } from 'react-toastify';

const EmployeeLeave = ({ employeeId }) => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveBalances, setLeaveBalances] = useState({ annualLeaveDays: 0, casualLeaveDays: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRequestId, setEditRequestId] = useState(null);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const leaveRequestsQuery = query(collection(db, 'leaveRequests'), where('employeeId', '==', employeeId));
        const leaveRequestsSnapshot = await getDocs(leaveRequestsQuery);
        const leaveRequestsData = leaveRequestsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeaveRequests(leaveRequestsData);
      } catch (error) {
        toast.error('Failed to fetch leave requests: ' + error.message);
      }
    };

    const fetchLeaveBalances = async () => {
      try {
        const employeeDocRef = doc(db, 'employees', employeeId);
        const employeeDocSnap = await getDoc(employeeDocRef);
        if (employeeDocSnap.exists()) {
          const employeeData = employeeDocSnap.data();
          setLeaveBalances({
            annualLeaveDays: parseInt(employeeData.annualLeaveDays, 10) || 0,
            casualLeaveDays: parseInt(employeeData.casualLeaveDays, 10) || 0,
          });
        }
      } catch (error) {
        toast.error('Failed to fetch leave balances: ' + error.message);
      }
    };

    fetchLeaveRequests();
    fetchLeaveBalances();
  }, [employeeId]);

  const openModal = () => {
    setEditRequestId(null); // Ensure we are not editing any request
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const editRequest = (requestId) => {
    setEditRequestId(requestId);
    setIsModalOpen(true);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-medium leading-6 text-gray-900">Your Leave Information</h2>
      
      <div>
        <h3 className="text-md font-medium leading-6 text-gray-700">Leave Balances</h3>
        <p><strong>Annual Leave Days:</strong> {leaveBalances.annualLeaveDays}</p>
        <p><strong>Casual Leave Days:</strong> {leaveBalances.casualLeaveDays}</p>
      </div>

      <div>
        <h3 className="text-md font-medium leading-6 text-gray-700">Leave Requests</h3>
        <button onClick={openModal} className="bg-blue-500 text-white px-4 py-2 rounded-md">
          New Leave Request
        </button>
        
        <div className="mt-4">
          {leaveRequests.length > 0 ? (
            <ul>
              {leaveRequests.map((request) => (
                <li key={request.id} className="mb-2">
                  <div className="p-4 bg-gray-100 rounded-md">
                    <p><strong>Type:</strong> {request.leaveType}</p>
                    <p><strong>Dates:</strong></p>
                    <ul className="list-disc ml-6">
                      {request.leaveDates && request.leaveDates.map((date, index) => (
                        <li key={index}>{new Date(date.seconds * 1000).toLocaleDateString()}</li>
                      ))}
                    </ul>
                    <p><strong>Status:</strong> {request.status}</p>
                    {request.status === 'Pending' && (
                      <div className="flex space-x-4 mt-2">
                        <button
                          onClick={() => editRequest(request.id)}
                          className="bg-yellow-500 text-white px-3 py-1 rounded-md"
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No leave requests found.</p>
          )}
        </div>
      </div>

      <Dialog open={isModalOpen} onClose={closeModal}>
        <div className="fixed inset-0 bg-black bg-opacity-25 py-10" />
        <div className="fixed inset-0 flex items-center justify-center p-2">
          <Dialog.Panel className="bg-white rounded-lg shadow-lg p-2 max-w-md w-full">
            <LeaveRequestForm
              employeeId={employeeId}
              onClose={closeModal}
              existingRequestId={editRequestId} 
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default EmployeeLeave;
