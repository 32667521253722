// Receivables.js
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { collection, query, where, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { NewInvoiceModal } from '../Utils/NewInvoiceModal';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../Help/helper';
import MoonLoader from 'react-spinners/MoonLoader';

const Receivables = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false);
  const navigate = useNavigate();

  const handleDeleteInvoice = async (invoiceId) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      try {
        await deleteDoc(doc(db, 'newInvoices', invoiceId));
        toast.success('Invoice deleted successfully.');
      } catch (error) {
        console.error('Error deleting invoice: ', error);
        toast.error('Failed to delete invoice.');
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // Updated query to filter invoices based on invoiceStatus
    const q = query(
      collection(db, 'newInvoices'),
      where('invoiceStatus', 'in', ['Pending', 'Part Payment'])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoicesData = [];
      querySnapshot.forEach((doc) => {
        invoicesData.push({ ...doc.data(), id: doc.id });
      });
      setInvoices(invoicesData);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
      },
      {
        Header: 'Job Card',
        accessor: 'jobCardNo',
      },
      {
        Header: 'Receivable',
        accessor: 'receivable',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Date',
        accessor: 'invoiceDate',
        Cell: ({ value }) =>
          value && value.seconds
            ? new Date(value.seconds * 1000).toLocaleDateString()
            : 'N/A',
      },
      {
        Header: 'Payment Status',
        accessor: 'invoiceStatus',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <>
            <button
              type="button"
              onClick={() => navigate(`/finance/invoices/${row.original.id}`)}
              className="rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              View
            </button>
            <button
              type="button"
              onClick={() => handleDeleteInvoice(row.original.id)}
              className="rounded bg-red-500 px-2 py-1 text-xs font-semibold ml-4 text-white shadow-sm hover:bg-green-400"
            >
              Delete
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );

  const data = useMemo(() => {
    return invoices.filter((invoice) =>
      Object.values(invoice).some((item) =>
        String(item).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [invoices, searchInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // instead of 'rows', we'll use page
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="p-5 bg-white shadow rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="p-2 w-1/4 text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search..."
          />
          <button
            onClick={() => setShowNewInvoiceModal(true)}
            className="ml-4 px-4 py-2 bg-blue-500 text-white rounded font-semibold text-sm hover:bg-blue-600"
          >
            Create New Invoice
          </button>
        </div>
        {showNewInvoiceModal && (
          <NewInvoiceModal closeModal={() => setShowNewInvoiceModal(false)} />
        )}

        <table {...getTableProps()} className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="text-sm font-medium text-gray-700"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-6 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={columns.length}>
                  <div className="flex justify-center items-center">
                    <MoonLoader size={50} />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {page.map((row) => {
                // 'rows' is replaced with 'page'
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="text-sm text-gray-500 hover:bg-gray-100"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-2 whitespace-nowrap"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="py-2 flex items-center justify-between">
          <div>
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'<<'}
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'<'}
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'>'}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {'>>'}
            </button>
          </div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
      </div>
    </>
  );
};

export default Receivables;
