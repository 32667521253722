import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../components/Auth/Auth';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon, Bars3Icon, BellIcon, Cog6ToothIcon, UsersIcon, FolderIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import Ayden from '../images/ayden.jpg';
import { LogoutModal } from './Auth/LogoutModal';
import MoonLoader from 'react-spinners/MoonLoader';

const navigation = [
  { name: 'Home', href: '/', icon: Bars3Icon, current: false },
  { name: 'Customers', href: '/customers', icon: UsersIcon, current: false },
  { name: 'Advisor', href: '/vehicles', icon: FolderIcon, current: false },
  { name: 'Finance', href: '/finance', icon: ChartPieIcon, current: false },
  { name: 'HR', href: '/humanresources', icon: Cog6ToothIcon, current: false },
  { name: 'Procurement', href: '/procurement', icon: FolderIcon, current: false },
  { name: 'Supervisor', href: '/supervisor', icon: FolderIcon, current: false },
  { name: 'Mechanics', href: '/mechanics', icon: FolderIcon, current: false },
  { name: 'Reports', href: '/analytics', icon: ChartPieIcon, current: false },
  { name: 'Admin', href: '/admin', icon: ChartPieIcon, current: false },
  { name: 'Help', href: '/help', icon: ChartPieIcon, current: false },
];

const userNavigation = [
  { name: 'Your Profile', href: '/profile', action: 'navigate' },
  { name: 'Sign out', action: 'signOut' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({ children }) {
  const { user } = useAuth();
  let navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasPendingRequests, setHasPendingRequests] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  useEffect(() => {
    const checkPendingLeaveRequests = async () => {
      if (user?.uid) {
        try {
          // Query employees where the current user is the designated manager
          const employeesQuery = query(
            collection(db, 'employees'),
            where('designatedManagerId', '==', user.uid)
          );

          const employeesSnapshot = await getDocs(employeesQuery);
          const employeeIds = employeesSnapshot.docs.map(doc => doc.id);

          if (employeeIds.length > 0) {
            // Query leaveRequests where the employeeId is in the list of managed employees and status is Pending
            const leaveRequestsQuery = query(
              collection(db, 'leaveRequests'),
              where('employeeId', 'in', employeeIds),
              where('status', '==', 'Pending')
            );

            const leaveRequestsSnapshot = await getDocs(leaveRequestsQuery);
            setHasPendingRequests(!leaveRequestsSnapshot.empty);
          } else {
            setHasPendingRequests(false);
          }
        } catch (error) {
          console.error('Error checking pending leave requests:', error);
          setHasPendingRequests(false);
        }
      } else {
        setHasPendingRequests(false);
      }
      setLoading(false); 
    };

    checkPendingLeaveRequests();
  }, [user]);

  const handleSignOut = async () => {
     const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error('Sign out error', error);
    }
  };

  const confirmLogout = () => {
    setIsLogoutModalOpen(true); 
  };

  const cancelLogout = () => {
    setIsLogoutModalOpen(false); 
  };

  const handleNavigation = (action, href) => {
    if (action === 'signOut') {
      confirmLogout();
    } else if (action === 'navigate') {
      navigate(href);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <MoonLoader size={50} color="#4A90E2" />
      </div>
    );
  }

  return (
    <>
      <div>
              <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
                <div className="fixed inset-0 bg-gray-900/80" />
                <div className="fixed inset-0 flex">
                  <Dialog.Panel className="relative mr-10 flex w-full max-w-xs flex-1 transform bg-gray-900 p-4">
                    <div className="flex items-center justify-between">
                      <img className="h-8 w-auto" src={Ayden} alt="AYDEN Garage" />
                      <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                    <nav className="mt-5">
                      <ul className="space-y-2">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'flex items-center px-4 py-2 text-sm font-semibold'
                              )}
                            >
                              <item.icon className="h-6 w-6 mr-3" aria-hidden="true" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </Dialog.Panel>
                </div>
              </Dialog>

              <LogoutModal 
        isOpen={isLogoutModalOpen} 
        onCancel={cancelLogout} 
        onConfirm={handleSignOut} 
      />

              

                      <div className="hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 lg:w-40 lg:bg-gray-900 lg:pt-5 lg:px-6">
                <div className="flex items-center">
                  <img className="h-8 w-auto" src={Ayden} alt="AYDEN Garage" />
                </div>
                <nav className="mt-5">
                  <ul className="space-y-2">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'flex items-center px-4 py-2 text-sm font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 mr-3" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

                <div className="lg:pl-40">
                  <div className="sticky top-0 z-40 flex h-16 bg-white border-b border-gray-200 shadow-sm px-4 lg:px-8">
                    <div className="flex items-center justify-end flex-1 gap-x-4">
                      <div className="relative">
                      <BellIcon 
                       className={`h-6 w-6 ${hasPendingRequests ? 'text-red-500' : 'text-gray-400 hover:text-gray-500'}`}
                       />
                        {hasPendingRequests && (
                            <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500" />
                          )}
                        </div>
                      <Menu as="div" className="relative">
                        <Menu.Button className="flex items-center">
                        <span className="text-sm font-semibold text-gray-900"> {user?.firstName} {user?.lastName}</span>
                          <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <button
                                  onClick={() => handleNavigation(item.action, item.href)}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                  >
                                    {item.name}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <main className="py-1">
                    <div className="px-4 sm:px-6 lg:px-8">{children}</div>
                  </main>
                </div>
      </div>
    </>
  );
}
