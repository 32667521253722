import React, { useEffect, useState, useMemo } from 'react';
import { db } from '../../firebase';
import { getDocs, collection, deleteDoc, doc } from 'firebase/firestore';
import ArticleForm from './Articles/ArticleForm';
import ArticleList from './Articles/ArticleList';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import { deserialize } from './helper';
import Modal from 'react-modal';
import { useAuth } from '../Auth/Auth';


Modal.setAppElement('#root');

const HelpCenterPage = () => {
  const { role } = useAuth();
  const [popularArticles, setPopularArticles] = useState([]);
  const [currentView, setCurrentView] = useState('list');
  const [currentArticle, setCurrentArticle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    const fetchPopularArticles = async () => {
      const popularArticlesRef = collection(db, 'articles');
      const snapshot = await getDocs(popularArticlesRef);
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPopularArticles(articles);
    };
    fetchPopularArticles();
  }, []);

  const handleAddNewArticle = () => {
    setCurrentView('form');
    setCurrentArticle(null);
    setTitle('');
    setContent([{ type: 'paragraph', children: [{ text: '' }] }]);
  };

  const handleEditArticle = (article) => {
    setCurrentView('form');
    setCurrentArticle(article);
    setTitle(article.title);
    setContent(deserialize(article.content));
  };

  const handleViewArticle = (article) => {
    setCurrentArticle(article);
    setIsModalOpen(true);
  };

  const handleDeleteArticle = async (id) => {
    await deleteDoc(doc(db, 'articles', id));
    setPopularArticles((prevArticles) => prevArticles.filter((article) => article.id !== id));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentArticle(null);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <header className="bg-white border-b shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <h1 className="text-2xl font-bold text-gray-900">
              AYGEN Garage Management System Help Center
            </h1>
            {currentView === 'list' && role === 'Admin' && (
              <button onClick={handleAddNewArticle} className="bg-blue-500 text-white text-sm py-2 px-4 rounded">
                Create New Article
              </button>
            )}
          </div>
        </div>
      </header>

       {/* Main Content */}
       <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        {currentView === 'list' && (
          <ArticleList
            articles={popularArticles}
            onEdit={role === 'Admin' ? handleEditArticle : null}
            onView={handleViewArticle}
            onDelete={role === 'Admin' ? handleDeleteArticle : null}
            role={role}
          />
        )}

        {currentView === 'form' && (
          <ArticleForm
            isEdit={!!currentArticle}
            article={currentArticle}
            onSave={(newArticle) => {
              if (currentArticle) {
                // Update
                setPopularArticles((prevArticles) =>
                  prevArticles.map((art) => (art.id === newArticle.id ? newArticle : art))
                );
              } else {
                // Add new
                setPopularArticles((prevArticles) => [...prevArticles, newArticle]);
              }
              setCurrentView('list');
            }}
            onCancel={() => setCurrentView('list')}
            title={title}
            setTitle={setTitle}
            content={content}
            setContent={setContent}
            editor={editor}
          />
        )}

        {/* Modal for Viewing Article Details */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Article Details"
          className="max-w-3xl mx-auto p-4 bg-white rounded shadow-lg mt-20"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start"
        >
          {currentArticle && (
            <>
              <h1 className="text-2xl font-semibold">{currentArticle.title}</h1>
              <div
                className="prose mt-6"
                dangerouslySetInnerHTML={{ __html: currentArticle.content }}
              ></div>
              <button onClick={closeModal} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
                Close
              </button>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default HelpCenterPage;
