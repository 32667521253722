import './Spinner.css';

const Spinner = () => (
    <div className="spinner">
      <div className="dot1"></div>
      <div className="dot2"></div>
    </div>
  );
  
  export default Spinner;
  