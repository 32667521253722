import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, where, doc,getDoc } from 'firebase/firestore';
import { Link, Navigate } from 'react-router-dom';
import { db } from '../../firebase';
import Modal from './Modal';
import { Bar } from 'react-chartjs-2';
import { MoonLoader } from 'react-spinners';
import { formatCurrency } from '../Help/helper';
import moment from 'moment/moment';
import BreadCrumb from '../BreadCrumb';
import { useAuth } from '../Auth/Auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const pages = [
    { name: 'Reports', to: '/analytics', component: Link, current: false },
    { name: 'Customer Job Card History ', to: '/analytics/customers', component: Link, current: true }, 
];

const CustomerJobHistory = () => {
    const [customers, setCustomers] = useState([]);
    const { role, loading } = useAuth();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSales, setTotalSales] = useState(0);
    const [vehicleCount, setVehicleCount] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    const [chartDataJobCards, setChartDataJobCards] = useState({
        labels: [],
        datasets: [{
            label: 'Number of Job Cards',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.6)'
        }]
    });

    const [chartDataInvoiceValue, setChartDataInvoiceValue] = useState({
        labels: [],
        datasets: [{
            label: 'Total Invoice Value',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.6)'
        }]
    });
    const [loadingJobCardsChart, setLoadingJobCardsChart] = useState(false);
    const [loadingInvoiceValueChart, setLoadingInvoiceValueChart] = useState(false);


    useEffect(() => {
        const fetchCustomers = async () => {
            setIsLoading(true);
            const snapshot = await getDocs(collection(db, "customers"));
            const customersList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCustomers(customersList);
            setIsLoading(false);
        };
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (selectedCustomer) {
            setIsLoading(true);
            const fetchVehiclesAndJobs = async () => {
                const vehiclesQuery = query(collection(db, "vehicles"), where("customerId", "==", selectedCustomer.id));
                const vehiclesSnapshot = await getDocs(vehiclesQuery);
                const vehicleIds = vehiclesSnapshot.docs.map(doc => doc.id);
                setVehicleCount(vehiclesSnapshot.size);
    
                let allJobs = [];
                let total = 0;
    
                // Helper function to fetch invoices and calculate the total sales
                const fetchInvoicesForJob = async (jobCardNo) => {
                    const invoicesQuery = query(collection(db, "newInvoices"), where("jobCardNo", "==", jobCardNo));
                    const invoicesSnapshot = await getDocs(invoicesQuery);
                    let jobTotal = 0;
                    invoicesSnapshot.forEach(doc => {
                        jobTotal += Number(doc.data().totalSale || 0);
                    });
                    return jobTotal;
                };
    
                for (const vehicleId of vehicleIds) {
                    const jobsQuery = query(collection(db, "jobs"), where("vehicleId", "==", vehicleId));
                    const jobsSnapshot = await getDocs(jobsQuery);
                    const jobsData = jobsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        vehicleId,
                        createdAt: doc.data().createdAt?.toDate().toLocaleDateString(), // Formatting date
                    }));
    
                    allJobs = allJobs.concat(jobsData);
                    setJobCount(allJobs.length);
    
                    // Fetch invoices for each job to calculate total sales
                    for (const job of jobsData) {
                        const jobTotal = await fetchInvoicesForJob(job.jobCardNo);
                        total += jobTotal;
                    }
                }
    
                setJobs(allJobs);
                setTotalSales(total);
                setIsLoading(false);
            };
    
            fetchVehiclesAndJobs();
        } else {
            setJobs([]);
            setTotalSales(0);
            setVehicleCount(0);
            setJobCount(0);
            setIsLoading(false); 
        }
    }, [selectedCustomer]);
    

    async function fetchJobCardsData() {
        setLoadingJobCardsChart(true);
        try {
            const vehiclesSnapshot = await getDocs(collection(db, "vehicles"));
            const vehicleCustomerMap = {};
            vehiclesSnapshot.forEach(doc => {
                vehicleCustomerMap[doc.id] = doc.data().customerId;
            });
    
            const jobsSnapshot = await getDocs(collection(db, "jobs"));
            const jobCounts = {};
            jobsSnapshot.forEach(doc => {
                const vehicleId = doc.data().vehicleId;
                const customerId = vehicleCustomerMap[vehicleId];
                if (customerId) {
                    jobCounts[customerId] = (jobCounts[customerId] || 0) + 1;
                }
            });
    
            const customerCounts = [];
            for (const [customerId, count] of Object.entries(jobCounts)) {
                const customerDoc = await getDoc(doc(db, "customers", customerId));
                if (customerDoc.exists()) {
                    customerCounts.push({
                        name: `${customerDoc.data().firstName} ${customerDoc.data().lastName}`,
                        count
                    });
                }
            }
    
            // Sort and slice for top 10
            customerCounts.sort((a, b) => b.count - a.count);
            const topCustomers = customerCounts.slice(0, 10);
    
            setChartDataJobCards({
                labels: topCustomers.map(item => item.name),
                datasets: [{
                    label: 'Number of Job Cards',
                    data: topCustomers.map(item => item.count),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                }]
            });
            setLoadingJobCardsChart(false);
        } catch (error) {
            toast.error("Failed to load job card data");
            setLoadingJobCardsChart(false);
        }
    };
    
    async function fetchInvoiceValueData() {
        setLoadingInvoiceValueChart(true);
        try {
            const vehiclesSnapshot = await getDocs(collection(db, "vehicles"));
            const vehicleCustomerMap = {};
            vehiclesSnapshot.forEach(doc => {
                vehicleCustomerMap[doc.id] = doc.data().customerId;
            });
    
            const invoicesSnapshot = await getDocs(collection(db, "newInvoices"));
            const invoiceValues = {};
            for (const doc of invoicesSnapshot.docs) {
                const jobCardNo = doc.data().jobCardNo;
                const jobsSnapshot = await getDocs(query(collection(db, "jobs"), where("jobCardNo", "==", jobCardNo)));
                for (const jobDoc of jobsSnapshot.docs) {
                    const vehicleId = jobDoc.data().vehicleId;
                    const customerId = vehicleCustomerMap[vehicleId];
                    if (customerId) {
                        invoiceValues[customerId] = (invoiceValues[customerId] || 0) + Number(doc.data().totalSale);
                    }
                }
            }
    
            const customerValues = [];
            for (const [customerId, value] of Object.entries(invoiceValues)) {
                const customerDoc = await getDoc(doc(db, "customers", customerId));
                if (customerDoc.exists()) {
                    customerValues.push({
                        name: `${customerDoc.data().firstName} ${customerDoc.data().lastName}`,
                        value
                    });
                }
            }
    
            // Sort and slice for top 10
            customerValues.sort((a, b) => b.value - a.value);
            const topCustomers = customerValues.slice(0, 10);
    
            setChartDataInvoiceValue({
                labels: topCustomers.map(item => item.name),
                datasets: [{
                    label: 'Total Invoice Value',
                    data: topCustomers.map(item => item.value),
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                }]
            });
            setLoadingInvoiceValueChart(false);
        } catch (error) {
            toast.error("Failed to load invoice value data");
            setLoadingInvoiceValueChart(false);
        }
    };
    

    const handleCustomerChange = (event) => {
        const customer = customers.find(c => c.id === event.target.value);
        setSelectedCustomer(customer);
    };

    const openModal = (job) => {
        setSelectedJob(job);
        setShowModal(true);
    };

    if (loading) {
        return <div>Loading...</div>; 
      }


    if (role !== 'Finance' && role !== 'Admin') {
   
        return <Navigate to="/unauthorized" replace />; 
      }

    return (
        <>
        <BreadCrumb pages={pages} />

        <div className="flex flex-row gap-4 p-4">
            <div className="w-1/2">
            {isLoading ? (
                        <div className="relative">
                            <MoonLoader size={30} css="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" />
                        </div>
                    ) : (
                <div className="bg-white p-4 rounded-lg shadow-md mb-4 grid grid-cols-2 gap-4">
                    <div>
                        <h2 className="text-xl font-semibold">Customer Job History</h2>
                        <select
                            onChange={handleCustomerChange}
                            value={selectedCustomer?.id || ''}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="">Select a Customer</option>
                            {customers.map(customer => (
                                <option key={customer.id} value={customer.id}>{customer.firstName} {customer.lastName}</option>
                            ))}
                        </select>
                    </div>
                    {selectedCustomer && (
                        <div className="pl-4 border-l-2 border-gray-200">
                            <h3 className="text-lg font-semibold mb-2">Customer Details:</h3>
                            <p className='text-sm'><strong>Email:</strong> {selectedCustomer.email}</p>
                            <p className='text-sm'><strong>Phone:</strong> {selectedCustomer.phoneNumber}</p>
                            <p className='text-sm'><strong>Vehicle Count:</strong> {vehicleCount}</p>
                            <p className='text-sm'><strong>Job Card Count:</strong> {jobCount}</p>
                            <p className='text-sm'><strong>Date Registered/DOB:</strong> {moment(selectedCustomer.dateOfBirth).format("DD-MMM-YYYY")}</p>
                            <p className='text-sm'><strong>Total Value of Job Cards Invoices:</strong> {formatCurrency(totalSales)}</p>
                        </div>
                    )}
                </div>
                 )}

                {jobs.length > 0 ? (
                    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-2 px-4">Job Card No</th>
                                    <th scope="col" className="py-2 px-4">Vehicle</th>
                                    <th scope="col" className="py-2 px-4">Reg No</th>
                                    <th scope="col" className="py-2 px-4">Date Created</th>
                                    <th scope="col" className="py-2 px-4">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobs.map((job, index) => (
                                <tr key={`${job.jobCardNo}-${Date.now()}`} className="bg-white border-b">
                                        <td className="py-1 px-4 text-xs">{job.jobCardNo}</td>
                                        <td className="py-1 px-4 text-xs">{job.vehicleMake} {job.vehicleModel}</td>
                                        <td className="py-1 px-4 text-xs">{job?.registrationNumber}</td>
                                        <td className="py-1 px-4 text-xs">{job.createdAt}</td>
                                        <td className="py-1 px-4 text-xs">
                                            <button
                                                className="rounded-md bg-indigo-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={() => openModal(job)}
                                            >
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : selectedCustomer ? (
                    <p className="text-gray-500 mt-2">No jobs found for selected customer.</p>
                ) : (
                    <p className="text-gray-500 mt-2">Please select a customer to view jobs.</p>
                )}

                {showModal && (
                    <Modal job={selectedJob} onClose={() => setShowModal(false)} />
                )}
            </div>

            <div className="w-1/2 space-y-4">
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-lg font-semibold mb-2">Top 10 Customers by Job Cards</h2>
                    {loadingJobCardsChart ? (
                        <MoonLoader size={30} />
                    ) : chartDataJobCards.labels.length > 0 ? (
                        <Bar data={chartDataJobCards} />
                    ) : (
                        <div>
                            <p>No data available. Click to load data.</p>
                            <button onClick={fetchJobCardsData}  className="rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">Display Chart</button>
                        </div>
                    )}
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-lg font-semibold mb-2">Top 10 Customers by Invoice Value</h2>
                    {loadingInvoiceValueChart ? (
                        <MoonLoader size={30} />
                    ) : chartDataInvoiceValue.labels.length > 0 ? (
                        <Bar data={chartDataInvoiceValue} />
                    ) : (
                        <div>
                            <p>No data available. Click to load data.</p>
                            <button onClick={fetchInvoiceValueData}  className="rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">Display Chart</button>
                        </div>
                    )}
                </div>
            </div>


        </div>

        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

export default CustomerJobHistory;
