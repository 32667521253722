import React, { useEffect, useState } from 'react';
import { doc, updateDoc, query, where, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import CloseJobModal from './CloseJobModal';  // Import modals
import ReOpenJobModal from './ReOpenJobModal';

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return new Date(date).toLocaleString(undefined, options);
};

const JobHeader = ({ jobCard }) => {
  const [isFullyPaid, setIsFullyPaid] = useState(false);
  const [areTasksCompleted, setAreTasksCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
  const [exitMileage, setExitMileage] = useState('');
  const [jobClosureComments, setJobClosureComments] = useState('');
  const [nextServiceMileage, setNextServiceMileage] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [reOpenReason, setReOpenReason] = useState('');
  const entryMileage = jobCard?.entryMileage || '';
  const isJobClosed = jobCard?.status === 'Closed';
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (jobCard?.jobCardNo) {
      const invoiceQuery = query(collection(db, 'newInvoices'), where('jobCardNo', '==', jobCard.jobCardNo));
      const unsubscribeInvoices = onSnapshot(invoiceQuery, (snapshot) => {
        const invoices = snapshot.docs.map(doc => doc.data());
        const fullyPaidInvoice = invoices.find(invoice => invoice.invoiceStatus === 'Fully Paid');
        setIsFullyPaid(!!fullyPaidInvoice);
        setLoading(false);
      });

      const tasksQuery = query(collection(db, 'tasks'), where('jobCardNo', '==', jobCard.jobCardNo));
      const unsubscribeTasks = onSnapshot(tasksQuery, (snapshot) => {
        const tasks = snapshot.docs.map(doc => doc.data());
        const allTasksCompleted = tasks.every(task => task.status === 'completed');
        setAreTasksCompleted(allTasksCompleted);
      });

      return () => {
        unsubscribeInvoices();
        unsubscribeTasks();
      };
    }
  }, [jobCard?.jobCardNo]);

  const handleCloseJob = async () => {
    if (parseInt(exitMileage) < parseInt(entryMileage)) {
      toast.error('Exit mileage cannot be lower than entry mileage.');
      return;
    }

    try {
      const jobRef = doc(db, 'jobs', jobCard.id);
      await updateDoc(jobRef, {
        exitMileage,
        jobClosureComments,
        nextServiceMileage,
        deliveryDate: new Date(deliveryDate),
        status: 'Closed',
      });
      toast.success('Job closed successfully');
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error closing job:', error);
      toast.error('Failed to close job');
    }
  };

  const handleReOpenJob = async () => {
    if (!reOpenReason) {
      toast.error('Please select a reason for reopening the job');
      return;
    }

    try {
      setLoading(true);
      const jobRef = doc(db, 'jobs', jobCard.id);
      await updateDoc(jobRef, {
        status: 'Open',
        reOpenReason: reOpenReason,
        reOpenDate: new Date(),
        reOpenUser: user?.email || 'Unknown User',
      });
      toast.success('Job reopened successfully');
      setIsReopenModalOpen(false);
    } catch (error) {
      console.error('Error reopening job:', error);
      toast.error('Failed to reopen job');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'exitMileage') {
      setExitMileage(value);
    } else if (name === 'nextServiceMileage') {
      setNextServiceMileage(value);
    }
  };

  const renderOdometerInput = (name, value) => (
    <div className="flex space-x-1">
      {Array.from({ length: 6 }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={value[index] || ''}
          onChange={(e) => {
            const newValue = value.split('');
            newValue[index] = e.target.value;
            handleInputChange({ target: { name, value: newValue.join('') } });
          }}
          className="w-12 h-12 text-center border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-xl font-mono"
        />
      ))}
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row gap-4 p-2 bg-gray-50 rounded-lg shadow">
      {/* Job Details Section */}
      <div className="md:w-1/2 p-2 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-blue-800 border-b pb-1 mb-1">
          Customer: <span className='text-black'>{jobCard?.customerName}</span>{" "}
          <span className='text-sm text-red-400'>{jobCard?.customerEmail}</span>
        </h2>
        <div className="space-y-1 text-gray-700">
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Vehicle:</strong> {jobCard?.vehicleMake} {jobCard?.vehicleModel} ({jobCard?.registrationNumber})</p>
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">VIN:</strong> {jobCard?.VIN}</p>
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Entry Mileage:</strong> {jobCard?.entryMileage}</p>
          
          {/* Conditionally display Exit Mileage if it exists */}
          {jobCard?.exitMileage && (
            <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Exit Mileage:</strong> {jobCard?.exitMileage}</p>
          )}

          {/* Conditionally display Delivery Date if it exists */}
          {jobCard?.deliveryDate && (
            <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Delivery Date:</strong> {formatDate(jobCard?.deliveryDate.toDate())}</p>
          )}
        </div>
      </div>

      <div className="md:w-1/2 p-2 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-blue-800 border-b pb-1 mb-1">Job Card: {jobCard?.jobCardNo}</h2>
        <div className="space-y-1 text-gray-700">
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Date Posted:</strong> {formatDate(jobCard?.createdAt.toDate())}</p>
          <p className='text-sm'>
            <strong className="font-medium text-sm text-gray-900">Job Status:</strong>
            <span className={`px-2 py-1 rounded ${jobCard?.status === 'Open' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {jobCard?.status}
            </span>
          </p>

          {/* Conditionally display Re-open Reason and Date if they exist */}
          {jobCard?.reOpenReason && (
            <p className='text-sm text-gray-900'>
              <strong>Re-open Reason:</strong> {jobCard.reOpenReason}
            </p>
          )}
          {jobCard?.reOpenDate && (
            <p className='text-sm text-gray-900'>
              <strong>Re-open Date:</strong> {formatDate(jobCard.reOpenDate.toDate())}
            </p>
          )}

          <div className="flex space-x-2 mt-2">
            {!isJobClosed && (
              <button
                disabled={!isFullyPaid || !areTasksCompleted}
                onClick={() => setIsModalOpen(true)}
                className={`px-4 py-2 font-semibold text-white rounded-lg ${isFullyPaid && areTasksCompleted ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}`}
              >
                {loading ? 'Checking Status...' : 'Close Job'}
              </button>
            )}
            {isJobClosed && (
              <button
                onClick={() => setIsReopenModalOpen(true)}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
              >
                {loading ? 'Reopening...' : 'ReOpen Job'}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Modal Components */}
      <CloseJobModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        exitMileage={exitMileage}
        setExitMileage={setExitMileage}
        jobClosureComments={jobClosureComments}
        setJobClosureComments={setJobClosureComments}
        nextServiceMileage={nextServiceMileage}
        setNextServiceMileage={setNextServiceMileage}
        deliveryDate={deliveryDate}
        setDeliveryDate={setDeliveryDate}
        handleCloseJob={handleCloseJob}
        renderOdometerInput={renderOdometerInput}
      />

      <ReOpenJobModal
        isReopenModalOpen={isReopenModalOpen}
        setIsReopenModalOpen={setIsReopenModalOpen}
        reOpenReason={reOpenReason}
        setReOpenReason={setReOpenReason}
        handleReOpenJob={handleReOpenJob}
      />
    </div>
  );
};

export default JobHeader;
