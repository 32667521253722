// EditCartItems.js

import { XCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { formatCurrency } from '../../Help/helper';

export const EditCartItems = ({ 
  cartItems,
  handleQuantityChange,
  handleCostPriceChange,
  handleSalePriceChange,
  handleTypeChange,
  handleRemoveFromCart,
  handleDescriptionChange,
  showNewItemForm,
  setShowNewItemForm,
  newItem,            
  setNewItem,         
  handleAddToCart     
}) => {


    return (
<>


<div className="overflow-x-auto">
                                  <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>

                              <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Type
                                </th>

                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Part Name
                                </th>
                                <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Quantity
                                </th>
                                <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Cost Price
                                </th>
                                <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Sale Price
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Extended Price
                                </th>
                               
                                <th scope="col" className="relative px-2 py-3">
                                  <span className="sr-only">Delete</span>
                                </th>
                                <th scope="col" className="relative px-2 py-3">
                                    <button
                                      type="button"
                                      onClick={() => setShowNewItemForm(!showNewItemForm)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      <PlusCircleIcon className="h-6 w-6" />
                                    </button>
                                    <span className="sr-only">Add New Item</span>
                                  </th>

                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {cartItems.length > 0 ? cartItems.map((item, index) => (
                              <tr key={index}>

                                <td className="px-2 py-4 whitespace-nowrap">
                                  <select
                                  value={item.type}
                                  placeholder="Sale Price"
                                  onChange={(e) => handleTypeChange(index, e.target.value)}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                          
                                  <option value="default">Default Margin</option>
                                  <option value="vehicle-specific">Vehicle Part</option>
                                  <option value="service">Service</option>
                                </select>            
                                  </td>
                                                    
                                  <td className="px-6 py-4 whitespace-nowrap">
                                  <input
                                    type="text"
                                    value={item.description}
                                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                    className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
                                  />
                                </td>


                                  <td className="px-4 py-4 whitespace-nowrap">
                                  <select
                                  id={`quantity-${index}`}
                                  name={`quantity-${index}`}
                                  value={item.quantity}
                                  onChange={(e) => handleQuantityChange(index, e.target.value)}
                                  className="block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                >
                                  {/* Options for quantity */}
                                  {[1, 2, 3, 4, 5, 6, 7, 8].map((q) => (
                                    <option key={q} value={q}>{q}</option>
                                  ))}
                                </select>            
                                  </td>
                                  
                                  <td className="px-2 py-2 whitespace-nowrap">
                                  <input 
                                  type="number"
                                  value={item.costPrice}
                                  onChange={(e) => handleCostPriceChange(index, e.target.value)}
                                  placeholder="Cost Price"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />                           
                                  </td>

                                  <td className="px-1 py-2 whitespace-nowrap">

                                  <input
                                    type="number"
                                    name="number"
                                    value={item.salePrice}  
                                    onChange={(e) => handleSalePriceChange(index, e.target.value)}                                  
                                    placeholder={formatCurrency(item.salePrice)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                    
                                  />                               

                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {formatCurrency(item.salePrice * item.quantity)}
                                  </td>
                                 
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <button onClick={() => handleRemoveFromCart(index)} className="text-red-600 hover:text-red-900">
                                    <XCircleIcon className="h-6 w-6" />
                                  </button>
                                </td>

                                </tr>
                                )) : (
                                  <tr>
                                <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-center">No items in the cart.</td>
                              </tr>
                            )}

                                  
                              {showNewItemForm && (
  <tr className="bg-gray-50">
    {/* New item input fields */}
    <td className="px-2 py-2">
      <select
        className="block w-full rounded-md border border-gray-300 py-1.5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        value={newItem.type}
        onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
      >
        <option value="default">Default Margin</option>
        <option value="vehicle-specific">Vehicle Part</option>
        <option value="service">Service</option>
      </select>
    </td>
    <td className="px-6 py-2">
      <input
        type="text"
        className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        value={newItem.description}
        onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
        placeholder="Enter part name"
      />
    </td>
    <td className="px-2 py-2">
      <input
        type="number"
        className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        value={newItem.quantity}
        onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
        placeholder="Quantity"
      />
    </td>
    <td className="px-2 py-2">
      <input
        type="number"
        className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        value={newItem.costPrice}
        onChange={(e) => setNewItem({ ...newItem, costPrice: parseFloat(e.target.value) })}
        placeholder="Cost price"
      />
    </td>
    <td className="px-2 py-2">
      <input
        type="number"
        className="block w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500"
        value={newItem.salePrice}
        onChange={(e) => setNewItem({ ...newItem, salePrice: parseFloat(e.target.value) })}
        placeholder="Sale price"
      />
    </td>
    <td className="px-6 py-2">
      {formatCurrency(newItem.salePrice * newItem.quantity)}
    </td>
    <td className="px-2 py-2 text-right">
      <button
        type="button"
        className="text-blue-600 hover:text-blue-800"
        onClick={() => handleAddToCart(newItem)}
      >
        Add
      </button>
    </td>
  </tr>
)}
</tbody>
                                  </table>



                               





                                </div>




</>



    )



  };