import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export const printPDF = (printRef, isMobile) => {
  const input = printRef.current;
  if (!input) {
    console.error("No element to print");
    return;
  }
  const scale = isMobile ? 0.5 : 1; 
  

  html2canvas(input, { scale: scale, useCORS: true }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    // Calculate the number of pages.
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297;  // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0; // Top position of the image

    // Add the first page.
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add new pages if the content overflows.
    while (heightLeft >= 0) {
      position -= pageHeight; // Move position to next page
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('inspection-report.pdf');
  });
};
