import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { preferenceOptions, toReadableFormat } from '../CRM/customerPreferences';


export const CustomerCreationModal = ({ showModal, closeModal,  handleSubmit, formState, setFormState, handleInputChange, isEditing, isSuccess, agents, isReferred, setIsReferred, setShowModal }) => {
   
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      preferences: checked
        ? [...prevState.preferences, name]
        : prevState.preferences.filter(preference => preference !== name)
    }));
  };
  




  return (
    <Transition appear show={showModal} as={React.Fragment}>
    <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}   
    >
      <div className="min-h-screen px-4 text-center">
        {/* <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" /> */}

        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-3xl">
          <Dialog.Title
            as="h3"
            className="text-2xl font-medium leading-6 text-gray-900"
          >
            {isEditing ? 'Edit Customer' : 'Create Customer'}
          </Dialog.Title>

          {isSuccess ? (
            <div className="flex items-center justify-center mt-4">
              <CheckCircleIcon className="h-12 w-12 text-green-500" />
              <p className="text-lg text-green-500 ml-4">Customer added successfully</p>
            </div>
          ) : (
            <div className="mt-2">
              <form onSubmit={handleSubmit} className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                <div className='mb-2 mt-2'>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                <input name="firstName" required onChange={handleInputChange} value={formState.firstName} placeholder="First Name"  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>

                <div className='mb-2'>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                <input name="lastName" required onChange={handleInputChange} value={formState.lastName}  placeholder="Last Name"   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>

                <div className="mb-4">
                  <label className="mr-2">Customer Type:</label>
                  <input type="radio" name="customerType" value="Private"  required onChange={handleInputChange}/> Private
                  <input type="radio" name="customerType" value="Corporate"  required onChange={handleInputChange} className="ml-4"/> Corporate
                </div>

                <div className='mb-4'>
                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700"> Date of Birth  </label>
                <input type="date" name="dateOfBirth" value={formState.dateOfBirth}  required onChange={handleInputChange} 
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
                </div>

                <div className='mb-4'>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input name= "phoneNumber" required onChange={handleInputChange} value={formState.phoneNumber}  placeholder="Phone Number"  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>

                <div className="mb-2">
                  <label className="mr-2">Gender:</label>
                  <div className='sm:flex sm:items-center sm:space-x-10 sm:space-y-0'>
                  <input type="radio" name="gender" value="Male" required onChange={handleInputChange}  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2" /> Male
                  <input type="radio" name="gender" value="Female" required onChange={handleInputChange}  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"/> Female
                  <input type="radio" name="gender" value="Other" required onChange={handleInputChange}  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"/> Other
                </div>
                </div>

                <div className='mb-2'>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input name="email" type='email' required onChange={handleInputChange} value={formState.email} placeholder="Email" 
                 className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"   />
                </div>
                
                <div className='mb-2'>
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">Customer Address</label>
                <input name="address" required onChange={handleInputChange} value={formState.address} placeholder="Address"
                 className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                </div>
               
               <div className='mb-2'>
               <label htmlFor="specialNotes" className="block text-sm font-medium text-gray-700">Special Notes</label>
                <textarea
                name="specialNotes"
                onChange={handleInputChange}
                value={formState.specialNotes}
                placeholder="Special notes or requirements"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                rows="6" >
                </textarea>
                </div>
               


              </div>

              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                

                <div className='mb-4'>
                {Object.entries(preferenceOptions).map(([category, options]) => (
                  <div key={category}>
                    <h3 className="font-semibold">{toReadableFormat(category)}</h3>
                    {options.map((option) => (
                      <label key={option} className="block">
                        <input
                          type="checkbox"
                          name={option}
                          checked={formState.preferences ? formState.preferences.includes(option) : false}
                          onChange={handleCheckboxChange}
                          className="mr-2 text-xs"
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                ))}
                </div>
                
                <div className="mb-4">
                    <label className="mr-2">Referred Customer?</label>
                    <input
                      type="checkbox"
                      onChange={(e) => setIsReferred(e.target.checked)}
                      checked={isReferred}
                    />
                  </div>
                {isReferred && (
                      <select
                        name="agentId"
                        required
                        onChange={handleInputChange}
                        value={formState.agentId}
                        className="w-full p-2 mb-4 border-2 border-gray-300 rounded"
                      >
                        <option value="">Select an agent</option>
                        {agents.map((agent) => (
                          <option key={agent.id} value={agent.id}>
                            {agent.name}
                          </option>
                        ))}
                      </select>
                    )}

          </div>

                <button
                      type="submit"
                      className="mt-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent 
                      rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  mr-6 focus-visible:ring-blue-500"
                    >
                      {isEditing ? 'Edit Customer' : 'Create Customer'} 
                </button>

                <button
                  type="button"
                  className="mt-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent 
                      rounded-md hover:bg-amber-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  mr-6 focus-visible:ring-red-500"
                  
                  onClick={closeModal}
                >
                  Cancel
                </button>

              </form>
            </div>
          )}
        </div>
      </div>
        </Dialog>
    </Transition>
    );
};


