import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { OperationalAnalyticsChart } from './OperationalAnalyticsChart';

const OperationalAnalytics = () => {
    const [jobs, setJobs] = useState([]);
    const [technicianPerformance, setTechnicianPerformance] = useState([]);
    const [averageJobDuration, setAverageJobDuration] = useState([]);

    // Fetch jobs from Firestore
    const fetchData = async () => {
        const jobsCollectionRef = collection(db, 'jobs');
        const data = await getDocs(jobsCollectionRef);
        return data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    };

    // Function to prepare chart data
    const prepareChartData = (jobs) => {
        const statusCounts = jobs.reduce((acc, job) => {
            acc[job.status] = (acc[job.status] || 0) + 1;
            return acc;
        }, {});
        return Object.keys(statusCounts).map(status => ({
            name: status,
            count: statusCounts[status]
        }));
    };

    // Calculate job duration
    const calculateJobDuration = (tracker) => {
        if (tracker.length < 2) return 0;
        const startDate = new Date(tracker[0].Date);
        const endDate = new Date(tracker[tracker.length - 1].Date);
        return (endDate - startDate) / (1000 * 60 * 60); // Duration in hours
    };

    // Memoized function for calculating average job duration
    const prepareAverageJobDurationData = useCallback((jobs) => {
        const totalDuration = jobs.reduce((sum, job) => {
            return sum + calculateJobDuration(job.tracker);
        }, 0);

        const averageDuration = jobs.length > 0 ? totalDuration / jobs.length : 0;
        return [{ name: 'Average Duration', value: averageDuration }];
    }, []);

    // Prepare technician performance data
    const prepareTechnicianPerformanceData = (jobs) => {
        const technicianCounts = jobs.reduce((acc, job) => {
            const technicianName = job.technician; // Replace with actual field name
            acc[technicianName] = (acc[technicianName] || 0) + 1;
            return acc;
        }, {});

        return Object.keys(technicianCounts).map(name => ({
            name,
            jobs: technicianCounts[name]
        }));
    };

    // Fetch data and prepare chart
    useEffect(() => {
        fetchData().then(data => {
            setJobs(prepareChartData(data));
            setTechnicianPerformance(prepareTechnicianPerformanceData(data));
            setAverageJobDuration(prepareAverageJobDurationData(data));
        });
    }, [prepareAverageJobDurationData]); // Now we include the memoized function as a dependency

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Operational Analytics</h2>
            <OperationalAnalyticsChart title="Job Status" data={jobs} />
            <OperationalAnalyticsChart title="Technician Performance" data={technicianPerformance} />
            <div>
                <h3 className="text-lg font-bold">Average Job Duration</h3>
                <p>{averageJobDuration[0]?.value.toFixed(2)} hours</p>
            </div>
        </div>
    );
}

export default OperationalAnalytics;
