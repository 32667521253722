export const TermsAndConditions = () => (
    <div className="px-4 py-2 text-sm text-gray-700">
      <h2 className="font-bold text-lg text-gray-900 mb-2">Terms & Conditions</h2>
      <ul className="list-disc pl-5 space-y-1">
        <li>At least 80% Deposit required upon approval</li>
        <li>Hidden Fault not Covered</li>
        <li>Vehicle parked within AYDENS Garage without approval will attract a daily charge of N2,500.</li>
        <li>AYDENS garage shall not be held liable for all transportation and/or other incidental costs the customer may incur whilst the vehicle is being repaired or in any obligation to provide a courtesy vehicle during this period.</li>
      </ul>
    </div>
  );
  