// SearchBar.js
import React from 'react';

const SearchBar = ({ setSearchTerm }) => {
  return (
    <input
      type="text"
      placeholder="Search by name"
      className="w-1/5 px-2 py-1 border rounded focus:outline-none text-sm ml-10 focus:border-blue-500 mr-4"
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
};

export default SearchBar;
