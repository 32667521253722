import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = (e) => {
    e.preventDefault(); 
    e.stopPropagation(); 
    setIsOpen(!isOpen);
  };
  

  return (
    <div className="border rounded-lg overflow-hidden mb-4">
      <button  type="button"
        className="flex justify-between items-center w-full p-5 bg-blue-200 hover:bg-green-300 focus:outline-none"
        onClick={toggleAccordion} 
      >
        <span className="font-medium text-gray-900">{title}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-900" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-900" />
        )}
      </button>
      {isOpen && <div className="p-5 bg-white">{children}</div>}
    </div>
  );
};

export default Accordion;
