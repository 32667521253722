import React, { Fragment,useState, useEffect } from 'react';
import { db } from '../../firebase';
import { Dialog, Transition } from '@headlessui/react'
import { addDoc,setDoc,serverTimestamp,getDocs,collection,doc,deleteDoc , query, where} from 'firebase/firestore';

const ReferralAgent = () => {
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [commission, setCommission] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAgentCustomers, setCurrentAgentCustomers] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'agents'));
      setAgents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchData();
  }, []);

  const fetchAgentCustomers = async (agentId) => {
    const q = query(collection(db, 'customers'), where('agentId', '==', agentId));
    const querySnapshot = await getDocs(q);
    const customers = querySnapshot.docs.map((doc) => doc.data());
    setCurrentAgentCustomers(customers);
    setIsModalOpen(true);
  };

  const onCreate = async () => {
    if (name && email && phoneNumber && commission) {
      await addDoc(collection(db, 'agents'), { name, email, phoneNumber, commission: parseFloat(commission), dateRegistered: serverTimestamp() });
      window.alert('Agent added successfully!');
      setName('');
      setEmail('');
      setPhoneNumber('');
      setCommission('');
      // Refresh agents data
      const querySnapshot = await getDocs(collection(db, 'agents'));
      setAgents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    } else {
      window.alert('Please fill out all fields!');
    }
  };
  
  const onUpdate = async (id) => {
    if (selectedAgent && name && email && phoneNumber && commission) {
      await setDoc(doc(db, 'agents', id), { name, email, phoneNumber, commission: parseFloat(commission) });
      window.alert('Agent updated successfully!');
      // Refresh agents data
      const querySnapshot = await getDocs(collection(db, 'agents'));
      setAgents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      // Clear form fields and selected agent
      setName('');
      setEmail('');
      setPhoneNumber('');
      setCommission('');
      setSelectedAgent(null);
    } else {
      window.alert('Please select an agent and fill out all fields!');
    }
  };

  const onDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this agent?')) {
      await deleteDoc(doc(db, 'agents', id));
      // Refresh agents data
      const querySnapshot = await getDocs(collection(db, 'agents'));
      setAgents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    }
  };
  

  const onEditClick = (agent) => {
    setName(agent.name);
    setEmail(agent.email);
    setPhoneNumber(agent.phoneNumber);
    setCommission(agent.commission);
    setSelectedAgent(agent);
  };
  
  const onSubmit = () => {
    if (selectedAgent) {
      onUpdate(selectedAgent.id);
    } else {
      onCreate();
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
        {agents.map(agent => (
          <div key={agent.id} className="w-full max-w-xs m-4">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <h2 className="font-bold text-xl mb-2">{agent.name}</h2>
              <p className="text-gray-700 text-base text-sm">{agent.email}</p>
              <p className="text-gray-700 text-base text-sm">Phone: {" "}{agent.phoneNumber}</p>
              <p className="text-gray-700 text-base text-sm">Net Commission: {" "}{agent.commission}%</p>
              {agent.dateRegistered && <p className="text-gray-700 text-base">Date Registered: {" "}{agent.dateRegistered.toDate().toLocaleDateString()}</p>}
              <div className="inline-flex rounded-md mt-4 shadow-sm" role="group">
                <button type="button" onClick={() => onEditClick(agent)} className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                  Update
                </button>
                <button type="button" onClick={() => onDelete(agent.id)} className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                  Delete
                </button>
                <button type="button"   onClick={() => fetchAgentCustomers(agent.id)} className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-md hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                  Customers
                </button>
              </div>
            </div>
          </div>
        ))}
        </div>
        <div>

        <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
             Customers Referred
            </Dialog.Title>

            <div className="mt-2">
              {currentAgentCustomers.map((customer, index) => (
                <p key={index}>{customer.firstName} {customer.lastName}</p>
              ))}
            </div>

            <button
              type="button"
              className="mt-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
         </Transition>

                  



        <div className="w-full max-w-xs">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={name} required onChange={e => setName(e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" required value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" required value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Commission</label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" required value={commission} onChange={e => setCommission(e.target.value)} />
            </div>
            <div className="flex items-center justify-between">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={onSubmit}>
                {selectedAgent ? 'Update Agent' : 'Add Agent'}
                </button>

            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralAgent;














