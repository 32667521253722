import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';

import { Chart, BarController, CategoryScale, LinearScale, BarElement, PieController } from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale, BarElement, PieController);

const FeedBackStats = () => {
    const [feedbackData, setFeedbackData] = useState({
        open: 0,
        closed: 0
    });

    useEffect(() => {
        const fetchFeedback = async () => {
            const q = query(collection(db, "feedbacks"));
            const querySnapshot = await getDocs(q);
            let openCount = 0;
            let closedCount = 0;

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.status === 'Open') openCount++;
                if (data.status === 'Resolved') closedCount++;
            });

            setFeedbackData({ Open: openCount, Resolved: closedCount });
        };

        fetchFeedback();
    }, []);

    const data = {
        labels: ['Open', 'Closed'],
        datasets: [
            {
                label: '# of Votes',
                data: [feedbackData.Open, feedbackData.Resolved],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    
    const options = {
        plugins: {
            legend: {
                display: true, // Display the legend
                position: 'top', // Position of the legend
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed.y;
                        return label;
                    }
                }
            }
        },
    };
   
    

    return (
        <div>
            <h2 className='mb-8 uppercase text-sm font-semibold'>Feedback Status Distribution</h2>
            <Pie data={data} options={options} />
        </div>
    );
};

export default FeedBackStats;
