import { Link } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import VehicleBarChart from './VehicleBarChart'; 
import GenderPieChart from './GenderPieChart';
import BirthdayList from '../CRM/BirthdayList';
import WelcomeBanner from './WelcomeBanner';
import { useAuth } from '../Auth/Auth';


const Home = () => {
  
  const [customerCount, setCustomerCount] = useState("NIL");
  // const [jobCount, setJobCount] = useState("NIL");
  const [vehicleCount, setVehicleCount] = useState("NIL");
  const [openJobCount, setOpenJobCount] = useState("NIL");
  const [approvedJobCount, setApprovedJobCount] = useState("NIL");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("select");
  const [searchResults, setSearchResults] = useState(null);
  const [genderCount, setGenderCount] = useState({});
  const { user } = useAuth();
 

   // Count Customers
   useEffect(() => {
    const fetchCustomerCount = async () => {
      const customerCol = collection(db, 'customers');
      const customerSnapshot = await getDocs(customerCol);

      if (customerSnapshot.size > 0) {
        setCustomerCount(customerSnapshot.size);
      }
    };

    fetchCustomerCount();
  }, []);

  //  // Count Jobs
  //  useEffect(() => {
  //   const fetchJobCount = async () => {
  //     const JobCol = collection(db, 'jobs');
  //     const jobSnapshot = await getDocs(JobCol);

  //     if (jobSnapshot.size > 0) {
  //       setJobCount(jobSnapshot.size);
  //     }
  //   };

  //   fetchJobCount();
  // }, []);

  // Approved Jobs
  useEffect(() => {
    const fetchApprovedJobCount = async () => {
      const JobCol = collection(db, 'jobs');
      const openJobQuery = query(JobCol, where('approvalStatus', '==', 'Yes'));
      const jobSnapshot = await getDocs(openJobQuery);

      if (jobSnapshot.size > 0) {
        setApprovedJobCount(jobSnapshot.size);
      }
    };

    fetchApprovedJobCount();
  }, []);




    // Open Jobs
    useEffect(() => {
      const fetchOepnJobCount = async () => {
        const JobCol = collection(db, 'jobs');
        const openJobQuery = query(JobCol, where('status', '==', 'Open'));
        const jobSnapshot = await getDocs(openJobQuery);
  
        if (jobSnapshot.size > 0) {
          setOpenJobCount(jobSnapshot.size);
        }
      };
  
      fetchOepnJobCount();
    }, []);

   // Registered Vehicles
   useEffect(() => {
    const fetchVehicleCount = async () => {
      const VehicleCol = collection(db, 'vehicles');
      const vehicleSnapshot = await getDocs(VehicleCol);

      if (vehicleSnapshot.size > 0) {
        setVehicleCount(vehicleSnapshot.size);
      }
    };

    fetchVehicleCount();
  }, []);

  useEffect(() => {
    const fetchGenderCount = async () => {
      const customerCol = collection(db, 'customers');
      const customerSnapshot = await getDocs(customerCol);

      if (customerSnapshot.size > 0) {
        let maleCount = 0, femaleCount = 0;
        customerSnapshot.docs.forEach(doc => {
          const customer = doc.data();
          if (customer.gender === "Male") maleCount++;
          else if (customer.gender === "Female") femaleCount++;
        });

        setGenderCount({ Male: maleCount, Female: femaleCount });
      }
    };

    fetchGenderCount();
  }, []);

 
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };
  
  const handleSelect = event => {
    setSearchType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSearchResults(null);
    const upperCaseSearchTerm = searchTerm.toUpperCase();
  
    if (searchType === "jobCardNo") {
      const q = query(collection(db, "jobs"), where("jobCardNo", "==", upperCaseSearchTerm));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setSearchResults('No matching documents.');
      } else {
        setSearchResults(querySnapshot.docs.map(doc => doc.data()));
      }
  
    } else if(searchType === "invoiceNumber") {
      const q = query(collection(db, "invoices"), where("invoiceNumber", "==", upperCaseSearchTerm));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setSearchResults('No matching documents.');
      } else {
        setSearchResults(querySnapshot.docs.map(doc => ({
          approvalStatus: doc.data().approvalStatus,
          date: doc.data().date,
          invoiceStatus: doc.data().invoiceStatus,
          id: doc.id
        })));
      }
    } else if(searchType === "registrationNumber") {
      const q = query(collection(db, "vehicles"), where("registrationNumber", "==", upperCaseSearchTerm));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setSearchResults('No matching documents.');
      } else {
        setSearchResults(querySnapshot.docs.map(doc => ({
          vehicleMake: doc.data().vehicleMake,
          vehicleModel: doc.data().vehicleModel,
          id: doc.id
        })));
      }
    } else if(searchType === "GIN") {
      const q = query(collection(db, "vehicles"), where("GIN", "==", upperCaseSearchTerm));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setSearchResults('No matching documents.');
      } else {
        setSearchResults(querySnapshot.docs.map(doc => ({
          vehicleMake: doc.data().vehicleMake,
          vehicleModel: doc.data().vehicleModel,
          id: doc.id
        })));
      }
    }


  };
  

  return (

    <>

    <WelcomeBanner user={user} />
        <div className="grid grid-cols-1 gap-4 px-4 mt-2 sm:grid-cols-4 sm:px-8">
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
    <Link to="/customers" className="flex items-center p-4 bg-green-500 text-white">
          
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                </path>
            </svg>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider text-white">Customers</h3>
            <p className="text-3xl text-white">{customerCount}</p>
        </div>
        </Link>
    </div>
    
    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">

         <Link to="/vehicles/openjobs" className="flex items-center p-4 bg-amber-500 text-white">
          
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2">
                </path>
            </svg>

        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider text-white">Open Jobs</h3>
            <p className="text-3xl text-white">{openJobCount}</p>
        </div>
        </Link>
    </div>

    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
    <Link to="/vehicles/alljobs" className="flex items-center p-4 bg-indigo-500 text-white">
          
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z">
                </path>
            </svg>
            
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider text-white">Total Jobs</h3>
            <p className="text-3xl text-white">{approvedJobCount}</p>
        </div>
        </Link>
    </div>



    <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
        <div className="p-4 bg-red-400">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4">
                </path>
            </svg></div>
        <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">Registered Vehicles</h3>
            <p className="text-3xl">{vehicleCount}</p>
        </div>
    </div>
        </div>


          <div className="flex md:flex-row grid-gap-4 mt-4">

                      <div className="md:w-3/5 pr-4 pl-4 grid-margin stretch-card">
            <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 h-full">
              <div className="flex-auto p-6">
                <div className="clearfix">
                    <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right"></div>
                </div>

                      <form className="flex flex-col md:flex-row gap-3" onSubmit={handleSubmit}>
                        <div className="flex">
                          <input type="text" placeholder="Search ..."
                            className="w-full md:w-80 px-3 h-10 rounded-l border-2 border-sky-500 focus:outline-none focus:border-sky-500"
                            value={searchTerm} onChange={handleChange}
                          />
                          <button type="submit" className="bg-sky-500 text-white rounded-r px-2 md:px-3 py-0 md:py-1">Search</button>
                        </div>
                        <select className="w-full h-10 border-2 border-sky-500 focus:outline-none focus:border-sky-500 text-sky-500 rounded 
                        px-2 md:px-3 py-0 md:py-1 text-sm tracking-wider"
                          value={searchType} onChange={handleSelect}
                        >
                          <option value="select">Select</option>
                          <option value="jobCardNo">Job Card No</option>
                          <option value="registrationNumber">Vehicle Registration Number</option>
                          <option value="GIN">Garage Identification Number</option>
                          <option value="invoiceNumber">Invoice Number</option>
                        </select>
                      </form>

                      <p className='text-center pt-2 pb-2 color-grey-50 text-xs font-semibold'>Search using JobCard No, InvoiceNumber, GIN or Plate Number </p>
                  <div className='mt-10 pl-10'>
                
                    {searchResults === 'No matching documents.' ? (
                      <div className="text-red-500">{searchResults}</div>
                    ) : searchResults?.map((result, index) => {
                      if (searchType === "invoiceNumber") {
                        return (
                          <div key={index} className="mt-2 text-sm">
                            <p>Approval Status: {result.approvalStatus}</p>
                            <p>Date: {new Date(result.date.seconds * 1000).toLocaleDateString()}</p>
                            <p>Status:  {result.invoiceStatus}</p>
                            <Link to={`/finance/invoices/${result.id}`} className="text-blue-500">View More</Link>
                          </div>
                        );
                      } else if (searchType === "registrationNumber") {
                        return (
                          <div key={index} className="mt-2 text-sm">
                            <p>Vehicle Make: {result.vehicleMake}</p>
                            <p>Vehicle Model: {result.vehicleModel}</p>
                            <Link to={`/vehicles/vehicleHistory/${result.id}`} className="text-blue-500">Maintenance and Service History</Link>
                                  </div>
                        );
                      } else if (searchType === "GIN") {
                        return (
                          <div key={index} className="mt-2 text-sm">
                            <p>Vehicle Make: {result.vehicleMake}</p>
                            <p>Vehicle Model: {result.vehicleModel}</p>
                            <Link to={`/vehicles/vehicleHistory/${result.id}`} className="text-blue-500">Maintenance and Service History</Link>
                                  </div>
                        );
                     
                      } else {
                        return (
                          <div key={index} className="mt-2 text-sm">
                            <p>Registration Number: {result.registrationNumber}</p>
                            <p>Vehicle: {result.vehicleMake}{" "} {result.vehicleModel} </p>
                            <p>Job Created At: {new Date(result.createdAt?.seconds * 1000).toLocaleDateString()}</p>
                            <p>Job Status:  {result?.status}</p>
                            <Link to={`/vehicles/jobdetail/${result.jobCardNo}`} className="text-blue-500">View More</Link>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>    
              </div>  
                      </div>

                      <div className="md:w-2/5 pr-4 pl-4 grid-margin stretch-card text-black text-sm ">
                       
                        <BirthdayList />
                      </div>
           </div>

            
       
           <div className="flex md:flex-row grid-gap-4 mt-4 items-stretch">

                      <div className="md:w-2/5 pr-4 pl-4 grid-margin stretch-card">
                        <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 h-full">
                          <div className="flex-auto p-6">
                            <div className="clearfix">
                              <h4 className="mb-3 float-left">Customer Statistics</h4>
                              <div id="vehicle-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4">
                                <GenderPieChart data={genderCount} />
                            </div>
                            </div>
                          
                          </div>
                        </div>
                      </div>

                      <div className="md:w-3/5 pr-4 pl-4 grid-margin stretch-card">
                        <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 h-full">
                          <div className="flex-auto p-6">
                            <h4 className="mb-3">Vehicle Demographics</h4>
                            <VehicleBarChart />
                            <div id="vehicle-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4"></div>
                          </div>
                        </div>
                  </div>

                  </div> 

                 
    </>
  );
};

export default Home;
