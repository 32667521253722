import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import 'react-calendar/dist/Calendar.css';
import RescheduleAppointmentModal from './Modals/RescheduleAppointmentModal';
import EditNotesModal from './Modals/EditNotesModal';


const AppointmentCalendar = () => {
    const [date, setDate] = useState(new Date());
    const [appointments, setAppointments] = useState([]);
    const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isEditNotesModalOpen, setIsEditNotesModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAppointments = async () => {
            const q = query(collection(db, "appointments"), where("status", "==", "Scheduled"));
            const querySnapshot = await getDocs(q);
            const fetchedAppointments = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                // Convert the date string to a Date object for easy comparison
                data.date = new Date(data.date);
                fetchedAppointments.push({...data, id: doc.id});
            });
            setAppointments(fetchedAppointments);
        };
        fetchAppointments();
    }, []);

    const onChange = (newDate) => {
        setDate(newDate);
    };

   
    const handleDelete = async (appointmentId) => {
        try {
            const appointmentRef = doc(db, "appointments", appointmentId);
            await deleteDoc(appointmentRef);
            setAppointments(appointments.filter(appointment => appointment.id !== appointmentId));
            // Show a success message
            console.log("Appointment deleted successfully");
        } catch (error) {
            console.error("Error deleting appointment: ", error);
            // Handle the error accordingly, e.g., show an error message
        }
    };


    const handleOpenRescheduleModal = (appointment) => {
        setSelectedAppointment(appointment);
        setIsRescheduleModalOpen(true);
    };
  
    const handleOpenEditNotesModal = (appointment) => {
        setSelectedAppointment(appointment);
        setIsEditNotesModalOpen(true);
    };

    const handleJobCardCreate = (appointment) => {
        // Prepare the data required by JobCard
        const jobCardData = {
            vehicle: {
                id: appointment.vehicleId,
                vehicleMake: appointment.vehicleMake,
                vehicleModel: appointment.vehicleModel,
                year: appointment.vehicleYear,
                registrationNumber: appointment.vehicleRegistrationNumber,
                customerId: appointment.customerId // If needed
            },
            customer: {
                name: appointment.customerName,
                id: appointment.customerId // Assuming you have customer ID
                // Add other customer details if available and required
            }
        };
    
        // Navigate to JobCard with the data
        navigate('/vehicles/jobs', { state: { vehicle: jobCardData.vehicle, customer: jobCardData.customer } });
    };


    return (
        <>
          
        <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-100 p-4 rounded-lg shadow mt-4 ">
                <p className='text-sm text-gray-900 uppercase mb-2 font-semibold'>Click on a Date to View Appointment</p>
                <Calendar
                    onChange={onChange}
                    value={date}
                    className="mx-auto text-lg"
                />
            </div>
            <div className="mt-4 md:mt-0">
                <h2 className="font-semibold mb-2 mt-8 text-gray-600 uppercase">Appointments for {date.toDateString()}</h2>
                <ul>
                    {appointments.filter(appointment => 
                        appointment.date.toDateString() === date.toDateString()
                    ).map((appointment, index) => (
                        <li key={appointment.id} className="p-3 mb-2 bg-gray-50 rounded-lg">
                            <p><strong>Time:</strong> <span className='text-sm'> {appointment.time} </span> </p>
                            <p><strong>Customer:</strong> <span className='text-sm'>  {appointment.customerName} </span> </p>
                            <p><strong>Vehicle:</strong> <span className='text-sm'> {appointment.vehicleMake} {appointment.vehicleModel} ({appointment.vehicleYear})
                             - {appointment.vehicleRegistrationNumber} </span>  </p>
                            <p><strong>Notes:</strong> <span className='text-sm'>  {appointment.notes} </span> </p>

                            <div className="flex justify-items-center mt-2">
                                    <button onClick={() => handleDelete(appointment.id)} className="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Delete
                                    </button>
                                    <button onClick={() => handleOpenRescheduleModal(appointment)} className="rounded bg-amber-400 px-2 ml-4 py-1 text-xs font-semibold text-white shadow-sm hover:bg-amber-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Reschedule
                                    </button>
                                    <button onClick={() => handleOpenEditNotesModal(appointment)} className="rounded bg-indigo-400 px-2 ml-4 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Edit Notes
                                    </button>
                                    <button onClick={() => handleJobCardCreate(appointment)} className="rounded bg-green-400 px-2 ml-4 py-1 text-xs font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Create Job
                                    </button>
                                    </div>
                        


                        </li>
                    ))}
                </ul>
            </div>



            {isRescheduleModalOpen && selectedAppointment && (
            <RescheduleAppointmentModal
                isOpen={isRescheduleModalOpen}
                onClose={() => setIsRescheduleModalOpen(false)}
                appointmentId={selectedAppointment.id}
                currentAppointmentDate={selectedAppointment.date}
                currentAppointmentTime={selectedAppointment.time}
            />
            )}


{isEditNotesModalOpen && selectedAppointment && (
            <EditNotesModal
                isOpen={isEditNotesModalOpen}
                onClose={() => setIsEditNotesModalOpen(false)}
                appointmentId={selectedAppointment.id}
                currentNotes={selectedAppointment.notes}
            />
            )}




        </div>
        </>
    );
    
};

export default AppointmentCalendar;
