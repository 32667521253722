import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import { formatCurrency } from '../Help/helper';

const pages = [
 
  { name: 'Procurement', to: '/procurement', component: Link, current: false },
  { name: 'Procurement Status', to: '/procurement/manage', component: Link, current: true },
  
];

const ManageProcurements = () => {
  const [procurements, setProcurements] = useState([]);
  const [filteredProcurements, setFilteredProcurements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this value as needed

  useEffect(() => {
    const fetchProcurements = async () => {
      const querySnapshot = await getDocs(collection(db, 'procurements'));
      const items = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const statusUpdateDate = data.statusUpdateDate?.toDate
          ? data.statusUpdateDate.toDate()
          : data.statusUpdateDate;
        return { id: doc.id, ...data, statusUpdateDate };
      });
      setProcurements(items);
    };

    fetchProcurements();
  }, []);

  useEffect(() => {
    // Apply search and filters
    let tempProcurements = [...procurements];

    // Search
    if (searchTerm) {
      tempProcurements = tempProcurements.filter((item) => {
        const searchLower = searchTerm.toLowerCase();
        return (
          (item.procurementNumber && item.procurementNumber.toLowerCase().includes(searchLower)) ||
          (item.supplier && item.supplier.toLowerCase().includes(searchLower))
        );
      });
    }

    // Filter by status
    if (filterStatus) {
      tempProcurements = tempProcurements.filter(
        (item) => item.status === filterStatus
      );
    }

    setFilteredProcurements(tempProcurements);
    setCurrentPage(1); // Reset to first page when filters change
  }, [procurements, searchTerm, filterStatus]);

  const handleStatusChange = async (id, newStatus) => {
    const docRef = doc(db, 'procurements', id);
    await updateDoc(docRef, {
      status: newStatus,
      statusUpdateDate: new Date(),
    });

    setProcurements((prevProcurements) =>
      prevProcurements.map((item) =>
        item.id === id
          ? { ...item, status: newStatus, statusUpdateDate: new Date() }
          : item
      )
    );
  };

  // Pagination logic
  const indexOfLastProcurement = currentPage * itemsPerPage;
  const indexOfFirstProcurement = indexOfLastProcurement - itemsPerPage;
  const currentProcurements = filteredProcurements.slice(
    indexOfFirstProcurement,
    indexOfLastProcurement
  );
  const totalPages = Math.ceil(filteredProcurements.length / itemsPerPage);

  return (
    <>
       <BreadCrumb pages={pages} />
    
    <div className="p-2">
      <h2 className="text-2xl mb-2 font-semibold">Manage Procurements</h2>

      {/* Search and Filter Controls */}
      <div className="flex flex-wrap mb-2 -mx-2">
        <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
          <input
            type="text"
            placeholder="Search by Procurement No or Supplier"
            className="w-full px-3 py-1 border text-sm border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/3 px-2">
          <select
            className="w-full px-3 py-1 border text-sm border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="">All Statuses</option>
            <option value="Ordered">Ordered</option>
            <option value="Collected">Collected</option>
            <option value="Defective-Returned">Defective-Returned</option>
            <option value="Warranty-Replaced">Warranty-Replaced</option>
            <option value="Refund-Requested">Refund-Requested</option>
          </select>
        </div>
      </div>

      {/* Procurements Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead className="bg-gray-100 uppercase text-sm">
            <tr>
              <th className="py-1 px-4 border-b text-left">Proc No</th>
              <th className="py-1 px-4 border-b text-left">Supplier</th>
              <th className="py-1 px-4 border-b text-left">Status</th>
              <th className="py-1 px-4 border-b text-left">Cost</th>
              <th className="py-1 px-4 border-b text-left">Last Updated</th>
              <th className="py-1 px-4 border-b text-left">Change Status</th>
            </tr>
          </thead>
          <tbody>
            {currentProcurements.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="py-1 text-sm px-4 border-b">{item?.procurementNumber} </td>
                <td className="py-1 text-sm px-4 border-b">{item.supplier}</td>
                <td className="py-1 text-sm px-4 border-b">{item.status}</td>
                <td className="py-1 text-sm px-4 border-b">
                  {formatCurrency(item.grandTotal)}
                </td>
                <td className="py-1 text-sm px-4 border-b">
                  {item.statusUpdateDate
                    ? moment(item.statusUpdateDate).format('DD-MMM-YYYY HH:mm')
                    : 'N/A'}
                </td>
                <td className="py-1 px-4 border-b">
                  <select
                    className="border border-gray-300 rounded px-3 py-1 text-sm focus:outline-none focus:ring focus:border-blue-300"
                    value={item.status}
                    onChange={(e) =>
                      handleStatusChange(item.id, e.target.value)
                    }
                  >
                    <option value="Ordered">Ordered</option>
                    <option value="Collected">Collected</option>
                    <option value="Defective-Returned">
                      Defective-Returned
                    </option>
                    <option value="Warranty-Replaced">Warranty-Replaced</option>
                    <option value="Refund-Requested">Refund-Requested</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4 items-center">
        <button
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          disabled={currentPage === 1}
          className={`px-4 py-2 bg-gray-200 rounded ${
            currentPage === 1
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-300'
          }`}
        >
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prevPage) =>
              Math.min(prevPage + 1, totalPages || 1)
            )
          }
          disabled={currentPage === totalPages || totalPages === 0}
          className={`px-4 py-2 bg-gray-200 rounded ${
            currentPage === totalPages || totalPages === 0
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-300'
          }`}
        >
          Next
        </button>
      </div>
    </div>
    </>
  );
};

export default ManageProcurements;
