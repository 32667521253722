import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../Auth/Auth';
import { toast, ToastContainer } from 'react-toastify';

const MechanicTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const { user, role } = useAuth();
  const [activeTasks, setActiveTasks] = useState([]);
  const [historicalTasks, setHistoricalTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(5); // Number of tasks per page
  const [showModal, setShowModal] = useState(false);
  const [verificationComments, setVerificationComments] = useState('');


  useEffect(() => {
    const fetchTasks = async () => {
      if ((user && (role === 'Admin' || role === 'Service Advisor' || role === 'Mechanic'))) {
        const q = query(collection(db, "tasks"), where("assignedTo", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const tasks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setActiveTasks(tasks.filter(task => task.status === 'new' || task.status === 'in-progress'));
        setHistoricalTasks(tasks.filter(task => task.status === 'completed'));
      }
    };
    fetchTasks();
  }, [user, role]);
  
  const startTask = async (taskId) => {
    const taskRef = doc(db, "tasks", taskId);
    const taskSnapshot = await getDoc(taskRef);
    if (taskSnapshot.exists() && taskSnapshot.data().status === 'new') {
      try {
        await updateDoc(taskRef, {
          status: 'in-progress',
          startedAt: Timestamp.now() // Capture the start time
        });
        toast.success('Task started');
        // Update tasks in state to reflect this change
        setTasks(tasks.map(task => task.id === taskId ? { ...task, status: 'in-progress', startedAt: Timestamp.now() } : task));
        setSelectedTask({ ...taskSnapshot.data(), status: 'in-progress', startedAt: Timestamp.now() }); // Update selected task if it's being viewed
      } catch (error) {
        console.error('Error starting task:', error);
        toast.error('Failed to start task');
      }
    } else {
      toast.info('Task has already been started or completed');
    }
  };
  
  // const validateTask = async (taskId) => {
  //   const taskRef = doc(db, "tasks", taskId);
  //   try {
  //     await updateDoc(taskRef, {
  //       taskVerified: true,
  //       verifiedBy: user.email, // or user.firstName + ' ' + user.lastName
  //       verifiedAt: Timestamp.now()
  //     });
  //     toast.success('Task validated successfully');
  //     setTasks(tasks.map(task => task.id === taskId ? { ...task, taskVerified: true } : task));
  //     setSelectedTask({...selectedTask, taskVerified: true}); // Update selected task view
  //   } catch (error) {
  //     console.error('Error validating task:', error);
  //     toast.error('Failed to validate task');
  //   }
  // };


  const handleValidateTask = async (taskId) => {
    const taskRef = doc(db, "tasks", taskId);
    try {
      await updateDoc(taskRef, {
        taskVerified: true,
        verifiedBy: user.email,
        verifiedAt: Timestamp.now(),
        verificationComments
      });
      toast.success('Task validated successfully');
      setShowModal(false);
      setTasks(tasks.map(task => task.id === taskId ? { ...task, taskVerified: true, verificationComments } : task));
      setSelectedTask(null);  // Optionally refresh the selected task data
    } catch (error) {
      console.error('Error validating task:', error);
      toast.error('Failed to validate task');
    }
  };


  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  const formatTime = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleTimeString();
  };

  const completeTask = async (taskId) => {
    const taskRef = doc(db, "tasks", taskId);
    try {
      await updateDoc(taskRef, {
        status: 'completed',
        completedAt: Timestamp.now() // Capture the completion time
      });
      toast.success('Task completed successfully');
      setTasks(tasks.map(task => task.id === taskId ? { ...task, status: 'completed' } : task));
      setSelectedTask(null); // Optionally deselect the task
    } catch (error) {
      console.error('Error completing task:', error);
      toast.error('Failed to complete task');
    }
  };
  


  const updateSubtaskStatus = async (taskId, subtaskId, newStatus) => {
    const taskRef = doc(db, "tasks", taskId);
    try {
      const taskSnapshot = await getDoc(taskRef);
      if (taskSnapshot.exists()) {
        let taskData = taskSnapshot.data();
        const updatedSubtasks = taskData.subtasks.map(subtask =>
          subtask.id === subtaskId ? { ...subtask, status: newStatus } : subtask
        );
  
        const allCompleted = updatedSubtasks.every(subtask => subtask.status === 'completed');
  
        const updates = {
          subtasks: updatedSubtasks,
          updatedAt: Timestamp.now(),
          ...(allCompleted && { status: 'completed', completedAt: Timestamp.now() })
        };
  
        await updateDoc(taskRef, updates);
        toast.success('Subtask status updated successfully');
  
        setTasks(tasks.map(task => task.id === taskId ? { ...task, ...updates } : task));
        setSelectedTask(current => current && current.id === taskId ? { ...current, ...updates } : current);
  
        if (allCompleted) {
          toast.success('All subtasks completed, task marked as completed');
        }
      } else {
        toast.error("Task not found");
      }
    } catch (error) {
      console.error('Error updating subtask status:', error);
      toast.error('Failed to update subtask status');
    }
  };
  
    
 

// Get current tasks
const indexOfLastTask = currentPage * tasksPerPage;
const indexOfFirstTask = indexOfLastTask - tasksPerPage;
const currentTasks = historicalTasks.slice(indexOfFirstTask, indexOfLastTask);
const paginate = pageNumber => setCurrentPage(pageNumber);


  return (
    <div className="flex flex-col md:flex-row gap-4 p-4">
             <div className="md:w-2/5">
                    {/* Active Tasks */}
                    <div className="bg-white p-4 rounded-lg shadow mb-4">
                      <h2 className="text-lg font-semibold text-gray-600">ACTIVE TASKS</h2>                     
                        {activeTasks.map((task) => (
                          <div key={task.id} className="bg-white p-4 rounded-lg shadow mb-4 cursor-pointer">
                            <h3 className="text-md font-semibold"><span className='uppercase text-sm text-gray-700'>Task Title: </span> {task.taskTitle}</h3>
                            <h3 className="text-md font-semibold"><span className='uppercase text-sm text-gray-700'>Job Card No: </span> {task?.jobCardNo}</h3>
                            <p>Status: {task.status}</p>
                            <button onClick={() => setSelectedTask(task)} className="mt-2 inline-flex items-center px-3 py-1 bg-blue-500 text-white text-sm font-medium rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
                              View Details
                            </button>
                          </div>
                        ))}                     
                      </div>

                           {/* Historical Tasks Section */}
                           <div className="bg-white p-2 rounded-lg shadow">
                            <h2 className="text-lg font-semibold text-gray-900">Completed Tasks</h2>
                              <table className="min-w-full leading-normal">
                                <thead>
                                  <tr>
                                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Task Title
                                  </th>
                                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Deadline Date
                                  </th>
                                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Deadline Time
                                  </th>
                                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Completed On
                                  </th>
                                  <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Actions
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentTasks.map((task) => (
                                   <tr key={task.id}>
                                   <td className="px-1 py-2 border-b border-gray-200 bg-white text-sm">
                                     {task.taskTitle}
                                   </td>
                                   <td className="px-1 py-2 border-b border-gray-200 bg-white text-sm">
                                     {task.deadline ? formatDate(task.deadline) : ''}
                                   </td>
                                   <td className="px-1 py-2 border-b border-gray-200 bg-white text-sm">
                                     {task.deadline ? formatTime(task.deadline) : ''}
                                   </td>
                                   <td className="px-1 py-2 border-b border-gray-200 bg-white text-sm">
                                     {task.completedAt ? formatDate(task.completedAt) : ''}
                                   </td>
                                   <td className="px-1 py-2 border-b border-gray-200 bg-white text-sm">
                                     <button
                                       onClick={() => setSelectedTask(task)}
                                       className="text-indigo-600 hover:text-indigo-900"
                                     >
                                       View Details
                                     </button>
                                   </td>
                                 </tr>
                                  ))}
                                </tbody>
                              </table>
                              {/* Pagination */}            

                          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                          <div className="inline-flex mt-2 xs:mt-0">
                            {Array.from({ length: Math.ceil(historicalTasks.length / tasksPerPage) }, (_, i) => (
                              <button
                                key={i + 1}
                                onClick={() => paginate(i + 1)}
                                className={`text-xs text-gray-700 leading-none rounded border p-2 mx-1 ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
                              >
                                {i + 1}
                              </button>
                            ))}
                          </div>
                           </div>
                         </div>


                         </div>


              
                              {/* Task Details Section */}
                            <div className="md:w-3/5 bg-white p-6 rounded-lg shadow-lg">
                              {selectedTask ? (
                                <div className="space-y-4">
                                  <h3 className="text-lg font-semibold text-gray-800">Task: {selectedTask.taskTitle} Details</h3>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Start By Date:</span> {selectedTask.startDate ? formatDate(selectedTask.startDate) : ''}</p>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Start By Time:</span> {selectedTask.startDate ? formatTime(selectedTask.startDate) : ''}</p>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Deadline Date:</span> {selectedTask.deadline ? formatDate(selectedTask.deadline) : ''}</p>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Deadline Time:</span> {selectedTask.deadline ? formatTime(selectedTask.deadline) : ''}</p>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Assigned By:</span> {selectedTask.assignedBy}</p>
                                  <p className="text-sm text-gray-700"><span className="font-semibold">Status:</span> {selectedTask.status}</p>
                                  {selectedTask.status === 'completed' && selectedTask.completedAt && (
                                    <p className="text-sm text-gray-700"><span className="font-semibold">Completed At:</span> {selectedTask.completedAt.toDate().toLocaleString()}</p>
                                  )}
                                  <div className="inline-flex rounded-md shadow-sm space-x-2">
                                    {selectedTask && selectedTask.status === 'new' && (
                                      <button 
                                        onClick={() => startTask(selectedTask.id)} 
                                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                        Start Task
                                      </button>
                                    )}
                                    {selectedTask && selectedTask.status === 'in-progress' && (
                                      <button 
                                        onClick={() => completeTask(selectedTask.id)} 
                                        className="px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                                        End Task
                                      </button>
                                    )}
                                    {selectedTask.taskVerified ? (
                                      <p className="text-sm text-gray-700"><span className="font-semibold">Verified by:</span> {selectedTask.verifiedBy}</p>
                                    ) : (
                                      (role === 'Admin' || role === 'Service Advisor') && (
                                        <button 
                                          onClick={() => setShowModal(true)} 
                                          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                          Validate Task
                                        </button>
                                      )
                                    )}
                                  </div>
                                  <table className="min-w-full leading-normal mt-4 bg-gray-50 rounded-lg">
                                    <thead>
                                      <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                          Description
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedTask.subtasks.map((subtask, index) => (
                                        <tr key={index} className="bg-white">
                                          <td className="px-5 py-2 border-b border-gray-200 text-sm text-gray-700">{subtask.description}</td>
                                          <td className="px-5 py-2 border-b border-gray-200 text-sm text-gray-700">
                                            <select
                                              value={subtask.status}
                                              onChange={(e) => updateSubtaskStatus(selectedTask.id, subtask.id, e.target.value)}
                                              disabled={selectedTask.taskVerified}
                                              className="px-3 py-1 rounded bg-white border border-gray-300 shadow-inner focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50 text-gray-700"
                                            >
                                              <option value="pending">Pending</option>
                                              <option value="in-progress">In Progress</option>
                                              <option value="completed">Completed</option>
                                            </select>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <p className="text-gray-600">Select a task to view details.</p>
                              )}
                            </div>


                          {/* Modal for verification comments */}
                          {showModal && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
                                  <div className="bg-white p-4 rounded">
                                    <h2>Add Verification Comments</h2>
                                    <textarea
                                      value={verificationComments}
                                      onChange={(e) => setVerificationComments(e.target.value)}
                                      placeholder="Enter comments here..."
                                      className="w-full h-32 p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                      onClick={() => handleValidateTask(selectedTask.id)}
                                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                      Submit Verification
                                    </button>
                                    <button
                                      onClick={() => setShowModal(false)}
                                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}

                 
               <ToastContainer />
    
      
    </div>
  );
};

export default MechanicTasks;