import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Route, Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { MoonLoader } from 'react-spinners'; // Import MoonLoader from react-spinners

// Create a context
const AuthContext = createContext();

// Provider component
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        // Fetch user details from Firestore
        const userRef = doc(db, "employees", user.uid);
        try {
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            setUser({
              ...user,
              firstName: userData.firstName,
              lastName: userData.lastName,
              role: userData.role,
            });
            setRole(userData.role);
          } else {
            setRole(null);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      } else {
        setRole(null);
      }
      setLoading(false);
    });
  

      // Listen for the window unload event
      const unloadListener = () => {
        if (auth.currentUser) {
          signOut(auth);  // Sign out if a user is currently signed in
        }
      };
      
      window.addEventListener('unload', unloadListener);


    // Cleanup subscription
    return () => {
      unsubscribe();
      window.removeEventListener('unload', unloadListener);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, role }}>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
        <MoonLoader size={50} color="#4A90E2" />
      </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

// UseAuth hook for easy access to the auth state
export function useAuth() {
  return useContext(AuthContext);
}


// PrivateRoute component
export function PrivateRoute({ children = null, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Navigate to="/login" state={{ from: location }} />
        )
      }
    />
  );
}