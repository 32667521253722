import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';  // Import the MoonLoader

const EmployeePerformanceReview = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesList);
      } catch (error) {
        toast.error('Failed to fetch employees: ' + error.message);
      }
    };

    fetchEmployees();
  }, []);

 
  const fetchFeedbacks = async (employeeId) => {
    setLoading(true);
    try {
        const feedbacksQuery = query(
            collection(db, 'feedbacks'),
            where('employeeId', '==', employeeId)
        );
        const feedbacksSnapshot = await getDocs(feedbacksQuery);

        const feedbacksList = await Promise.all(
            feedbacksSnapshot.docs.map(async (feedbackDoc) => {
                const feedbackData = feedbackDoc.data();
                

                // Initialize empty values
                let customerName = 'Unknown';
                let vehicleMake = 'Unknown';
                let vehicleModel = 'Unknown';

                // Fetch customer information
                if (feedbackData.customerId) {
                    const customerDocRef = doc(db, 'customers', feedbackData.customerId);
                    const customerDocSnap = await getDoc(customerDocRef);
                    if (customerDocSnap.exists()) {
                        const customerData = customerDocSnap.data();
                        customerName = `${customerData.firstName || ''} ${customerData.lastName || ''}`;
                    }
                }

                // Fetch vehicle information
                if (feedbackData.vehicleId) {
                    const vehicleDocRef = doc(db, 'vehicles', feedbackData.vehicleId);
                    const vehicleDocSnap = await getDoc(vehicleDocRef);
                    if (vehicleDocSnap.exists()) {
                        const vehicleData = vehicleDocSnap.data();
                        vehicleMake = vehicleData.vehicleMake || 'Unknown';
                        vehicleModel = vehicleData.vehicleModel || 'Unknown';
                    }
                }

                return {
                    id: feedbackDoc.id,
                    ...feedbackData,
                    customerName,
                    vehicleMake,
                    vehicleModel,
                };
            })
        );

        setFeedbacks(feedbacksList);
        
    } catch (error) {
        toast.error('Failed to fetch feedbacks: ' + error.message);
    }
    setLoading(false);
};



  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
    fetchFeedbacks(selectedOption.value);
  };

  const selectedEmployeeName = employees.find(emp => emp.id === selectedEmployee)?.firstName + ' ' + employees.find(emp => emp.id === selectedEmployee)?.lastName;

  return (
    <div className="container mx-auto p-4 bg-white shadow rounded-lg">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">Review Employee Performance</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Employee</label>
          <Select
            options={employees.map(employee => ({
              label: `${employee.firstName} ${employee.lastName}`,
              value: employee.id,
            }))}
            onChange={handleEmployeeChange}
            className="block w-full text-gray-700 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            placeholder="Search and select an employee"
            isSearchable
          />
        </div>

        {loading ? (
          <div className="flex justify-center items-center col-span-1 md:col-span-2">
            <MoonLoader color="#4A90E2" loading={loading} size={50} />
          </div>
        ) : feedbacks.length > 0 ? (
          <div className="col-span-1 md:col-span-2">
            <h3 className="text-xl font-medium text-gray-900 mt-6">Feedback for {selectedEmployeeName}</h3>
            <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-6">
              {feedbacks.map(feedback => (
                <div key={feedback.id} className="p-4 bg-gray-100 rounded-md shadow">
                  <p className="font-semibold text-gray-700">Customer: <span className="font-normal">{feedback.customerName}</span></p>
                  <p className="font-semibold text-gray-700">Vehicle: <span className="font-normal">{feedback.vehicleMake} {feedback.vehicleModel}</span></p>
                  <p className="font-semibold text-gray-700">Type: <span className="font-normal">{feedback.feedbackType}</span></p>
                  <p className="font-semibold text-gray-700">Nature: <span className="font-normal">{feedback.complaintNature}</span></p>
                  <p className="font-semibold text-gray-700">Feedback: <span className="font-normal">{feedback.feedback}</span></p>
                  <p className="font-semibold text-gray-700">Company Response: <span className="font-normal">{feedback.companyFeedback}</span></p>
                  <p className="font-semibold text-gray-700">Date: <span className="font-normal">{new Date(feedback.date.seconds * 1000).toLocaleDateString()}</span></p>
                  <p className="font-semibold text-gray-700">Status: <span className="font-normal">{feedback.status}</span></p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="mt-4 text-gray-600">No feedback found for this employee.</p>
        )}
      </div>
    </div>
  );
};

export default EmployeePerformanceReview;
