import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query, where, updateDoc,addDoc } from '@firebase/firestore';
import { db } from '../../firebase'; 
import { formatCurrency } from '../Help/helper';

const CommissionPayable = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const invoiceQuery = query(
      collection(db, 'invoices'),
      where('AgentId', '!=', null),
      where('commissionPaid', '==', 'No')
    );
    const invoiceSnapshot = await getDocs(invoiceQuery);
    const fetchPromises = invoiceSnapshot.docs.map(async (invoiceDoc) => {
      const invoice = invoiceDoc.data();
      const agentDoc = doc(db, 'agents', invoice.AgentId);
      const agentSnapshot = await getDoc(agentDoc);
      const agent = agentSnapshot.data();
      return { ...invoice, agentName: agent?.name, id: invoiceDoc.id };
    });
    const fetchedData = await Promise.all(fetchPromises);
    setData(fetchedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const authorizePayment = async (id, invoiceNumber, commissionPayable) => {
    if(window.confirm("Are you sure you want to authorize this payment?")) {
      const invoiceDoc = doc(db, 'invoices', id);
      await updateDoc(invoiceDoc, {
        commissionPaid: 'Yes'
      });
      alert("Payment successfully authorized!");

      const transaction = {
        type: 'Expenditure',
        amount: commissionPayable,
        transactionDate: new Date().toISOString().split('T')[0],
        description: invoiceNumber,
        category: 'Commission',
        modeOfPayment: 'Bank Transfer'
      };
  
      const transactionsCollection = collection(db, 'transactions');
      await addDoc(transactionsCollection, transaction);
      fetchData(); // Refresh the data
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Agent Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Commission Payable
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Invoice Number
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Authorize</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{item.agentName}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{formatCurrency(item.commissionPayable)}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{item.invoiceNumber}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button 
                            className="text-indigo-600 hover:text-indigo-900 focus:outline-none" 
                            onClick={() => authorizePayment(item.id, item.invoiceNumber, item.commissionPayable)}
                            >
                            Authorize Payment
                            </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionPayable;
