import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';

const AddFeedback = ({ isOpen, onRequestClose }) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [companyFeedback, setCompanyFeedback] = useState('');
  const [escalationMode, setEscalationMode] = useState('');
  const [complaintNature, setComplaintNature] = useState('');
  const [customerVehicles, setCustomerVehicles] = useState([]);
  

  useEffect(() => {
    const fetchCustomers = async () => {
      const q = query(collection(db, "customers"));
      const querySnapshot = await getDocs(q);
      setCustomers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      const querySnapshot = await getDocs(collection(db, "employees"));
      setEmployees(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchCustomerVehicles = async () => {
      if (selectedCustomerId) {
        const q = query(collection(db, "vehicles"), where("customerId", "==", selectedCustomerId));
        const querySnapshot = await getDocs(q);
        setCustomerVehicles(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    fetchCustomerVehicles();
  }, [selectedCustomerId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCustomerId || !feedback || !escalationMode || !complaintNature) {
        toast.error("Please fill in all required fields.");
        return;
    }

    try {
        await addDoc(collection(db, "feedbacks"), {
            customerId: selectedCustomerId,
            vehicleId: selectedVehicleId,
            feedback,
            feedbackType,
            companyFeedback,
            status: "Open",
            escalationMode,
            complaintNature,
            employeeId: selectedEmployee || '', 
            date: new Date()
        });

        // Reset form state
        setSelectedCustomerId('');
        setSelectedVehicleId('');
        setFeedback('');
        setFeedbackType('');
        setCompanyFeedback('');       
        setEscalationMode('');
        setComplaintNature('');
        setSelectedEmployee('');
        toast.success("Feedback submitted successfully.");
        // setIsModalOpen(false);
    } catch (error) {
        console.error("Error adding feedback: ", error);
        toast.error("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gray-50 w-1/2">
      <h2 className="font-semibold uppercase mb-1">Log Customer Feedback</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-4 pb-8 mb-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">Select Customer</label>
            <div className="mt-2">
              <Select
                options={customers.map(customer => ({
                  label: `${customer.firstName} ${customer.lastName}`,
                  value: customer.id
                }))}
                onChange={(selectedOption) => setSelectedCustomerId(selectedOption.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">Select Vehicle</label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => setSelectedVehicleId(e.target.value)}
              >
                <option value="">Select a Vehicle</option>
                {customerVehicles.map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.vehicleMake} {vehicle.vehicleModel} ({vehicle.year})
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">Mode of Escalation</label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={escalationMode}
                onChange={(e) => setEscalationMode(e.target.value)}
              >
                <option value="">Select Mode</option>
                <option value="Phone call">Phone Call</option>
                <option value="SMS">SMS</option>
                <option value="Email">Email</option>
                <option value="WhatsApp">WhatsApp</option>
                <option value="Verbal">Verbal</option>
                <option value="Written note">Written Note</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">Complaint or Commendation</label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
              >
                <option value="">Select Type of Feedback</option>
                <option value="Complaint">Complaint</option>
                <option value="Commendation">Commendation</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">Nature of Complaint</label>
            <div className="mt-2">
              <select
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={complaintNature}
                onChange={(e) => setComplaintNature(e.target.value)}
              >
                <option value="">Select Nature</option>
                <option value="Billing Issue">Billing Issue</option>
                <option value="Inappropriate Employee Behaviour">Inappropriate Employee Behaviour</option>
                <option value="Late Delivery">Late Delivery</option>
                <option value="Poor Quality of Service">Poor Quality of Service</option>
                <option value="Exceptional Service">Exceptional Service</option>
                <option value="Repair/Maintenance">Repair/Maintenance</option>
                <option value="Damage/Theft">Damage/Theft</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            {(complaintNature === "Inappropriate Employee Behaviour" || complaintNature === "Exceptional Service") && (
              <div className="mb-4">
                <label className="block text-sm font-medium leading-6 text-gray-900">Select Employee</label>
                <div className="mt-2">
                  <select
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                  >
                    <option value="">Select Employee</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.firstName} {employee.lastName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-full mt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">Customer Feedback</label>
          <div className="mt-2">
            <textarea
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              rows={3}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter feedback"
            ></textarea>
          </div>
          <p className="mt-3 text-sm leading-6 text-gray-600">Describe in Detail the Feedback from the Customer.</p>
        </div>

        <div className="col-span-full mt-2">
          <label className="block text-sm font-medium leading-6 text-gray-900">Ayden Feedback to Customer</label>
          <div className="mt-2">
            <textarea
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              rows={3}
              value={companyFeedback}
              onChange={(e) => setCompanyFeedback(e.target.value)}
              placeholder="Company Response to Customer"
            ></textarea>
          </div>
          <p className="mt-3 text-sm leading-6 text-gray-600">What were your commitments to the customer on resolution type timeframe?</p>
        </div>

        <div className="flex items-center justify-between mt-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit Feedback
          </button>

          <button
            type='button'
            className="ml-4 bg-red-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
      </form>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default AddFeedback;
