import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import {formatCurrency} from '../Help/helper';
import PaymentModal from './PaymentModal';
import BreadCrumb from '../BreadCrumb';
import PrintInvoiceModal from './Utils/PrintInvoiceModal';

const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Invoices', to: '/finance/invoices', component: Link, current: false },  
  { name: 'Invoice Details', to: '/finance/invoices', component: Link, current: true },  
];

const InvoiceDetails = () => {
  const { id } = useParams();
  const [actualMargin, setActualMargin] = useState(0);
  const [invoice, setInvoice] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [printModalIsOpen, setPrintModalIsOpen] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [marginPercentage, setMarginPercentage] = useState(0);





  useEffect(() => {
    const fetchExpenses = async () => {
      const expensesRef = collection(db, "transactions");
      // Assuming transactions are stored with an 'invoiceNo' or 'jobCardNo' that matches the invoice
      const q = query(expensesRef, where("invoiceNo", "==", invoice.invoiceNumber), where("type", "==", "Expenditure"));
      const querySnapshot = await getDocs(q);
      const fetchedExpenses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setExpenses(fetchedExpenses);
    };
  
    if (invoice) {
      fetchExpenses();
    }
  }, [invoice]); 
  

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'newInvoices', id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const invoiceData = docSnap.data();
        setInvoice({ id: docSnap.id, ...invoiceData });
        const totalCost = invoiceData.totalCost;
        const totalSale = invoiceData.totalSale;
  
        if (totalCost > 0) {  // Ensure there is a cost to avoid division by zero
          const netMargin = ((totalSale - totalCost) / totalCost) * 100;
          setMarginPercentage(netMargin);
        }
      } else {
        console.log('No such document!');
      }
    };
  
    fetchData();
  }, [id]);
  

  useEffect(() => {
    const fetchRelatedTransactions = async () => {
      if (!invoice) return;  // Guard clause if invoice is not yet loaded
  
      const transactionsRef = collection(db, "transactions");
      const q = query(transactionsRef, where("invoiceNo", "==", invoice.invoiceNumber), where("type", "==", "Expenditure"));
      const querySnapshot = await getDocs(q);
      let totalExpenses = 0;
      querySnapshot.forEach(doc => {
        totalExpenses += doc.data().amount;
      });
      const actualMargin = invoice.totalSale - totalExpenses;      
      setActualMargin(actualMargin);
      
    };
  
    if (invoice) {
      fetchRelatedTransactions();
    }
  }, [invoice]);
  


  

  const handlePrint = async () => {
    setPrintModalIsOpen(true);

  }

  const updateInvoiceState = (updatedInvoice) => {
    setInvoice(updatedInvoice);
  };
  
  

  if (!invoice) return null; 







  return (
    <>
      <BreadCrumb pages={pages} />


    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Invoice #{invoice.invoiceNumber}

      <span> 
      <button 
          type="button" 
          onClick={() => setModalIsOpen(true)}
          className={`rounded px-2 py-1 text-sm font-bold ml-4 text-white shadow-sm ${invoice.receivable === 0 ? 'bg-gray-500 hover:bg-gray-500 cursor-not-allowed' : 'bg-red-500 hover:bg-green-900'}`}
          disabled={invoice.receivable === 0}
        >
          Post Payment
        </button>

      
      </span>


      <span>         <button type='button'              
               className="rounded bg-green-500 px-2 py-1 text-sm font-bold ml-4 text-white shadow-sm hover:bg-green-900"
              onClick={handlePrint}
            >
              Print Invoice
            </button>        </span>
      </h2>

     
     
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Customer:</strong> <span className='font-semibold text-sm'> {invoice.customerName} </span></p>
        </div>
        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Date:</strong> <span className='text-sm'> {new Date(invoice.invoiceDate?.seconds * 1000).toLocaleDateString()} </span></p>
        </div>
        <div className="col-span-2 md:col-span-1">
        <p className="text-gray-600 mb-2">
            <strong>Status: </strong> 
            <span className='text-sm'> 
               {invoice.receivable === 0 ? 'Fully Paid' : invoice.invoiceStatus} 
            </span>
          </p>        

        </div>

        <div className="col-span-2 md:col-span-1">
        <p className="text-gray-600 mb-2"><strong>Projected Margin:</strong> <span className='text-sm'>  {formatCurrency(invoice.totalSale - invoice.totalCost)} </span></p>
          </div>

          <div className="col-span-2 md:col-span-1">
        <p className="text-gray-600 mb-2"><strong>Profit:</strong> <span className='text-sm'>  {formatCurrency(actualMargin)} </span></p>
          </div>
         

        <div className="col-span-2 md:col-span-1">
          <p className="text-gray-600 mb-2"><strong>Amount Due:</strong> <span className='text-sm'> {formatCurrency(parseFloat(invoice?.receivable))} </span></p>
        </div>

        <div className="col-span-2 md:col-span-1">
  <p className="text-gray-600 mb-2"><strong>Net Margin (%):</strong> <span className='text-sm'>{marginPercentage.toFixed(2)}%</span></p>
</div>


      </div>


      <div className="grid grid-cols-2 gap-6 mt-6 text-sm">
        {/* Invoice Items */}
        <div className="overflow-x-auto">
          <h3 className="font-bold mb-2 uppercase">Invoice Items</h3>
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-left border-b-2 border-gray-300">
              <tr>
                <th className="px-4 py-3 text-sm font-semibold text-gray-900">Description</th>
                <th className="px-4 py-3 text-sm font-semibold text-gray-900">Price</th>
                <th className="px-4 py-3 text-sm font-semibold text-gray-900">Quantity</th>
                <th className="px-4 py-3 text-sm font-semibold text-gray-900">Total</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 bg-white">
              {invoice.cartItems.map((item, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="px-4 py-3">{item.description}</td>
                  <td className="px-4 py-3">{formatCurrency(item.price)}</td>
                  <td className="px-4 py-3">{item.quantity}</td>
                  <td className="px-4 py-3">{formatCurrency(item.quantity * item.price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

                  {/* Payments Received */}
                  {invoice.paymentsReceived && invoice.paymentsReceived.length > 0 && (
                    <div className="overflow-x-auto">
                      <h3 className="font-bold mb-2 uppercase">Payments Received</h3>
                      <table className="w-full">
                        <thead className="text-left border-b-2 border-gray-300">
                          <tr>
                            <th className="px-4 py-3">Date</th>
                            <th className="px-4 py-3">Mode</th>
                            <th className="px-4 py-3">Amount</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-300">
                          {invoice.paymentsReceived.map((payment, index) => (
                            <tr key={index}>
                              <td className="px-4 py-3">{payment.dateOfPayment.toDate().toLocaleString()}</td>
                              <td className="px-4 py-3">{payment.modeOfPayment}</td>
                              <td className="px-4 py-3">{formatCurrency(payment.amountReceived)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* Expenses Incurred */}
                {expenses.length > 0 && (
                  <div className="overflow-x-auto">
                    <h3 className="font-bold mb-2 uppercase">Expenses Incurred</h3>
                    <table className="w-full">
                      <thead className="text-left border-b-2 border-gray-300">
                        <tr>
                          <th className="px-4 py-3">Date</th>
                          <th className="px-4 py-3">Category</th>
                          <th className="px-4 py-3">Amount</th>
                          {/* Add more columns as needed */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {expenses.map((expense, index) => (
                          <tr key={index}>
                            <td className="px-4 py-3">{expense.transactionDate}</td>
                            <td className="px-4 py-3">{expense.description}</td>
                            <td className="px-4 py-3">{formatCurrency(expense.amount)}</td>
                            {/* Render more data as needed */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

      </div>

      <div className="mt-6">
        <h2 className="uppercase font-semibold mb-2">Invoice Total Excl VAT: {formatCurrency(invoice.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0))}</h2>
      </div>

      {modalIsOpen && ( 

          <PaymentModal 
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            invoice={invoice}
            updateInvoiceState={updateInvoiceState}
          />
        )}

      <PrintInvoiceModal 
        isOpen={printModalIsOpen} 
        onClose={() => setPrintModalIsOpen(false)} 
        invoice={invoice} 
      />

      
    </div>
    </>
  );
};

export default InvoiceDetails;
