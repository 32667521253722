import React from "react";
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, DoughnutController } from 'chart.js';

Chart.register(ArcElement, CategoryScale, DoughnutController);

const PieChartSummary = ({ data }) => (
    <div className="chart-container w-full flex justify-center mb-8">
      <Pie 
        data={data}
        height={null} // You can remove the height and width to allow full responsiveness
        width={null}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: true, // Show legend
              position: 'top', // Position of legend
            },
            tooltip: {
              enabled: true, // Enable tooltips
            },
            // If you want to add labels inside the pie pieces, you can uncomment below
            datalabels: {
              display: true,
              color: 'white',
              formatter: (value, context) => {
                return context.chart.data.labels[context.dataIndex];
              },
            },
          },
        }}
      />
    </div>
  );

  export default PieChartSummary;