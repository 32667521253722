import React from 'react';

const CustomerOrderForm = ({ formData, updateData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Get today's date in YYYY-MM-DD format for the max attribute
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight

    if (name === 'receptionDate') {
      const receptionDate = new Date(value);
      receptionDate.setHours(0, 0, 0, 0); // Reset time to midnight

      // Reception Date cannot be in the future
      if (receptionDate > today) {
        alert('Reception Date cannot be in the future.');
        return;
      }

      // If deliveryDate exists, ensure it is not earlier than receptionDate
      if (formData.deliveryDate) {
        const deliveryDate = new Date(formData.deliveryDate);
        deliveryDate.setHours(0, 0, 0, 0);

        if (deliveryDate < receptionDate) {
          alert('Delivery Date cannot be earlier than Reception Date.');
          return;
        }
      }
    }

    if (name === 'deliveryDate') {
      const deliveryDate = new Date(value);
      deliveryDate.setHours(0, 0, 0, 0);

      // Ensure receptionDate is set before comparing
      if (formData.receptionDate) {
        const receptionDate = new Date(formData.receptionDate);
        receptionDate.setHours(0, 0, 0, 0);

        if (deliveryDate < receptionDate) {
          alert('Delivery Date cannot be earlier than Reception Date.');
          return;
        }
      }
    }

    updateData({ ...formData, [name]: value });
  };

  // Get today's date in YYYY-MM-DD format for the max attribute
  const todayString = new Date().toISOString().split('T')[0];

  const renderOdometerInput = (name, value) => (
    <div className="flex space-x-1">
      {Array.from({ length: 6 }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={value[index] || ''}
          onChange={(e) => {
            const newValue = value.split('');
            newValue[index] = e.target.value;
            handleInputChange({ target: { name, value: newValue.join('') } });
          }}
          className="w-12 h-12 text-center border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-xl font-mono"
        />
      ))}
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="grid grid-cols-2 gap-4">
        {/* Reception Date Field */}
        <div>
          <label className="block text-sm font-medium">Reception Date</label>
          <input
            type="date"
            name="receptionDate"
            value={formData.receptionDate || ''}
            onChange={handleInputChange}
            max={todayString} 
            className="uppercase mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Date Due Field */}
        <div>
          <label className="block text-sm font-medium">Date Due</label>
          <input
            type="date"
            name="deliveryDate"
            value={formData.deliveryDate || ''}
            onChange={handleInputChange}
            min={formData.receptionDate || ''} 
            className="uppercase mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Entry Mileage Field */}
        <div className="flex flex-col items-center">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Entry Mileage
          </label>
          {renderOdometerInput('entryMileage', formData.entryMileage || '')}
        </div>

        {/* Customer's Requests/Complaints Field */}
        <div className="col-span-2">
          <label className="block text-sm font-medium">
            Customer's Requests/Complaints
          </label>
          <textarea
            name="ownerRequest"
            value={formData.ownerRequest || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            rows="4"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderForm;
