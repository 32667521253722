// CustomerProfile.js
import React, { useEffect, useState } from 'react';
import CustomerDetails from './Composite/CustomerDetails';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import MoonLoader from 'react-spinners/MoonLoader';

const pages = [
  { name: 'Customer Care', to: '/customers', component: Link, current: false },
  { name: 'Customer Profile', to: '/customers/profile', component: Link, current: true },
];

const CustomerProfile = () => {
  const { id } = useParams(); 
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const customerRef = doc(db, 'customers', id);
        const customerSnap = await getDoc(customerRef);

        if (customerSnap.exists()) {
          setCustomer({ id: customerSnap.id, ...customerSnap.data() });
        } else {
          console.log('No such customer!');
        }
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    };

    fetchCustomer();
  }, [id]);

  return (
    <>
      <BreadCrumb pages={pages} />

      <div>
        {customer ? (
          <CustomerDetails customer={customer} />
        ) : (
            <div className="flex justify-center items-center h-64">
              <MoonLoader size={50} color="#000" />
            </div>
        )}
      </div>
    </>
  );
};

export default CustomerProfile;
