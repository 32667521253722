// Suppliers.js
import React, { useState, useEffect } from 'react';
import {collection,getDocs,addDoc,deleteDoc,doc,updateDoc,Timestamp} from 'firebase/firestore';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import AddModal from './AddModal';
import Notification from './Notification';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';


const pages = [
 
  { name: 'Procurement', to: '/procurement', component: Link, current: false },
  { name: 'Supplier Details', to: '/procurement/suppliers', component: Link, current: true },
  
];

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [newSupplier, setNewSupplier] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phoneNumbers: '',
    productSold: '',
    dateRegistered: Timestamp.now(),
    bankDetails: [],
    paymentTerms: '',
    warrantyAvailable: false,
  });
  const [editSupplier, setEditSupplier] = useState(null);
  const [supplierToDelete, setSupplierToDelete] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  // Search and Filter States
  const [searchTerm, setSearchTerm] = useState('');
  const [filterWarranty, setFilterWarranty] = useState('');

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Adjust as needed

  const fetchSuppliers = async () => {
    const data = await getDocs(collection(db, 'suppliers'));
    setSuppliers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  // Apply search and filters
  useEffect(() => {
    let filtered = suppliers;

    // Search
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter((supplier) => {
        return (
          (supplier.companyName &&
            supplier.companyName.toLowerCase().includes(term)) ||
          (supplier.firstName && supplier.firstName.toLowerCase().includes(term)) ||
          (supplier.lastName && supplier.lastName.toLowerCase().includes(term))
        );
      });
    }

    // Filter by warranty
    if (filterWarranty !== '') {
      const warrantyBool = filterWarranty === 'Yes';
      filtered = filtered.filter(
        (supplier) => supplier.warrantyAvailable === warrantyBool
      );
    }

    setFilteredSuppliers(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  }, [suppliers, searchTerm, filterWarranty]);

  // Pagination logic
  const totalPages = Math.ceil(filteredSuppliers.length / itemsPerPage);
  const indexOfLastSupplier = currentPage * itemsPerPage;
  const indexOfFirstSupplier = indexOfLastSupplier - itemsPerPage;
  const currentSuppliers = filteredSuppliers.slice(
    indexOfFirstSupplier,
    indexOfLastSupplier
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSupplier({
      ...newSupplier,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditSupplier({
      ...editSupplier,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const addSupplierAction = async () => {
    await addDoc(collection(db, 'suppliers'), newSupplier);
    setNewSupplier({
      companyName: '',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phoneNumbers: '',
      productSold: '',
      dateRegistered: Timestamp.now(),
      bankDetails: [],
      paymentTerms: '',
      warrantyAvailable: false,
    });
    setShowNotification(true);
    setShowAddModal(false); // Close the AddModal after adding the supplier
    fetchSuppliers(); // Fetch the updated list
  };

  const closeNotification = async () => {
    setShowNotification(false);
    fetchSuppliers();
  };

  const handleEdit = (supplier) => {
    setEditSupplier(supplier);
  };

  const handleDelete = (supplier) => {
    setSupplierToDelete(supplier);
  };

  const deleteSupplierAction = async () => {
    await deleteDoc(doc(db, 'suppliers', supplierToDelete.id));
    setSupplierToDelete(null);
    fetchSuppliers();
  };

  const editSupplierAction = async () => {
    await updateDoc(doc(db, 'suppliers', editSupplier.id), editSupplier);
    setEditSupplier(null);
    fetchSuppliers();
  };

  return (
    <>
       <BreadCrumb pages={pages} />

    <div className="p-2">
      <h2 className="text-2xl font-semibold leading-7 text-gray-900 mb-2">
        Supplier Information
      </h2>

      {/* Search and Filter Controls */}
      <div className="flex flex-wrap mb-2">
        <div className="w-full md:w-1/2 lg:w-1/3 pr-2 mb-2">
          <input
            type="text"
            placeholder="Search by Name"
            className="w-full px-3 py-1 border text-sm border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 pr-2 mb-2">
          <select
            className="w-full px-3 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            value={filterWarranty}
            onChange={(e) => setFilterWarranty(e.target.value)}
          >
            <option value="">All Warranty Options</option>
            <option value="Yes">Warranty Available</option>
            <option value="No">No Warranty</option>
          </select>
        </div>
      </div>

      <div className="mb-1">
        <button
          className="ml-2 px-4 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={() => setShowAddModal(true)}
        >
          Add Supplier
        </button>
      </div>

      {showAddModal && (
        <AddModal
          supplier={newSupplier}
          onChange={handleInputChange}
          onSubmit={addSupplierAction}
          onClose={() => setShowAddModal(false)}
        />
      )}

      {editSupplier && (
        <EditModal
          supplier={editSupplier}
          onChange={handleEditChange}
          onSubmit={editSupplierAction}
          onClose={() => setEditSupplier(null)}
        />
      )}
      {supplierToDelete && (
        <DeleteModal
          supplier={supplierToDelete}
          onSubmit={deleteSupplierAction}
          onClose={() => setSupplierToDelete(null)}
        />
      )}

      {showNotification && (
        <Notification message="Supplier added successfully!" onClose={closeNotification} />
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {currentSuppliers.map((supplier) => (
          <div
            key={supplier.id}
            className="p-2 border border-gray-300 rounded-lg shadow hover:shadow-md transition duration-200"
          >
            <h2 className="text-lg font-bold mb-2">{supplier.companyName}</h2>
            <p className="text-gray-700 mb-1">
              <strong>First Name:</strong> {supplier.firstName}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Last Name:</strong> {supplier.lastName}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Address:</strong> {supplier.address}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Email:</strong> {supplier.email}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Phone Numbers:</strong> {supplier.phoneNumbers}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Product Sold:</strong> {supplier.productSold}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Payment Terms:</strong> {supplier.paymentTerms}
            </p>
            <p className="text-gray-700 mb-1">
              <strong>Warranty Available:</strong>{' '}
              {supplier.warrantyAvailable ? 'Yes' : 'No'}
            </p>
            {supplier.bankDetails.map((bankDetail, index) => (
              <div key={index}>
                <p className="text-gray-700 mb-1">
                  <strong>Bank Name:</strong> {bankDetail.bankName}
                </p>
                <p className="text-gray-700 mb-1">
                  <strong>Account Name:</strong> {bankDetail.accountName}
                </p>
                <p className="text-gray-700 mb-1">
                  <strong>Account Number:</strong> {bankDetail.accountNumber}
                </p>
              </div>
            ))}
            <div className="mt-2 flex justify-between">
              <button
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                onClick={() => handleEdit(supplier)}
              >
                Edit
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={() => handleDelete(supplier)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-6">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`px-4 py-2 bg-gray-200 rounded ${
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'
          }`}
        >
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages || totalPages === 0}
          className={`px-4 py-2 bg-gray-200 rounded ${
            currentPage === totalPages || totalPages === 0
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-300'
          }`}
        >
          Next
        </button>
      </div>
    </div>
    </>
  );
};

export default Suppliers;
