import { Dialog } from '@headlessui/react';

export const LogoutModal = ({ isOpen, onCancel, onConfirm }) => {

   
  return (
    <Dialog open={isOpen} onClose={onCancel} className="fixed inset-0 z-30 overflow-y-auto mt-14">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75" /> */}

        {/* The modal container */}
        <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          
          {/* Modal content */}
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Confirm Logout
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to log out?
              </p>
            </div>
          </div>
          
          {/* Modal actions */}
          <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              type="button"
              className="w-full px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto"
              onClick={onConfirm}
            >
              Logout
            </button>
            <button 
              type="button"
              className="mt-3 w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

