import React, {  Fragment, useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { collection, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import { db } from '../../firebase';


const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Vehicle Administration', to: '/vehicles/manage', component: Link, current: false }, 
  { name: 'Vehicle History', to: '/vehicles/manage', component: Link, current: true },  
];


const VehicleHistory = () => {
  const [jobs, setJobs] = useState([]);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    const unsubscribeFromJobs = onSnapshot(collection(db, 'jobs'), (snapshot) => {
      const jobList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).filter(job => job.vehicleId === id);
      setJobs(jobList);
    });

    return () => {
      unsubscribeFromJobs();
    };
  }, [id]);

  const data = useMemo(() => jobs, [jobs]);

  const columns = useMemo(() => [
    {
      Header: 'Job Number',
      accessor: 'jobCardNo',
    },
    {
      Header: 'Job Date',
      accessor: 'createdAt',
      Cell: ({ value }) => value.toDate().toLocaleDateString(),
    },

    {
      Header: 'Technician',
      accessor: 'contractor',
    },

    {
      Header: 'Status',
      accessor: 'status',
    },


    {
      Header: 'Details',
      accessor: 'id',
      Cell: ({ value }) => (
        <button type='button'     className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        
   onClick={() => {
          setSelectedJob(jobs.find(job => job.id === value));
          setIsModalOpen(true);
        }}>
          View Details
        </button>
      ),
    },
  ], [jobs]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });


  let vehicleTitle = "Vehicle Details"; 
if (jobs.length > 0) {
  vehicleTitle = `${jobs[0].vehicleMake} ${jobs[0].vehicleModel} ${jobs[0]?.registrationNumber}  `; 
}


  return (
    <>
    <BreadCrumb pages={pages} />
    
    <div className="flex flex-col items-center w-full">

    <h2 className="text-2xl font-semibold text-gray-800 my-4">{vehicleTitle}</h2>
      
      
      <div className="w-full max-w-7xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 overflow-x-auto">
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
  <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={() => setIsModalOpen(false)}>
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    

      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
                Job Details 
              </Dialog.Title>
              <div className="mt-2">
                {selectedJob && (
                  <>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Escalation:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedJob.ownerRequest}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Fault Detected:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedJob.technicianObservation}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Parts Replaced:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedJob.partsRequired.map(part => `${part.partName} (quantity: ${part.quantity})`).join(', ')}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Work Done:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedJob.workRequired}
                      </dd>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setIsModalOpen(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  </Dialog>
</Transition>

    </div>
    </>
  );
};

export default VehicleHistory;
