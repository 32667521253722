import React, { useState, useCallback } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import { collection, addDoc, doc,getDoc,Timestamp } from 'firebase/firestore';
import BreadCrumb from '../../BreadCrumb';
import CustomerOrderForm from './CustomerOrderForm';
import InspectionChecklist from './InspectionChecklist';
import { generateVisitGIN } from '../../helpers/GIN';
import DQReport from './DQReport';
import PartsAndServicesForm from './PartsAndServicesForm';
import { generateJobCardNo , checkJobCardNoExists} from '../generateJobCardNo';  

const pages = [
    { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
    { name: 'Manage Vehicle', to: '/vehicles/manage', component: Link, current: false },
    { name: 'New Diagnostics Questionnaire', to: '/vehicles/NewDQ', component: Link, current: true },
];

const initialSteps = [
    { id: '01', name: 'Customer Order', status: 'complete' },
    { id: '02', name: 'Inspection Checklist', status: 'current' },
    { id: '03', name: 'Diagnostic Report', status: 'upcoming' },
    { id: '04', name: 'Parts & Services', status: 'upcoming' },
];

const DQContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const vehicle = location.state?.vehicle;
    const vehicleId = location.state?.vehicleId;
    const [currentStep, setCurrentStep] = useState(1);
    const [steps, setSteps] = useState(initialSteps);
    const [formData, setFormData] = useState({
        jobCardNo: '',
        vehicleDetails: vehicle
        ? {
            ...vehicle,
            vehicleId: vehicleId || vehicle.id,
          }
        : {},
        customerOrder: {
            receptionDate: '',
            deliveryDate: '',
            entryMileage: '',
            exitMileage: '',
            customerRequest: '',
        },
        inspectionChecklist: {
            exteriorItems: {},
            underHoodItems: {},
            trunkItems: {},
            interiorItems: {},
        },
        diagReport: {
            technicianObservation: '',
            workRequired: '',
            markedItemComments: {},
        },
        partsAndServices: {
            partsRequired: [],
            servicesRequired: []
        }
    });

    const handleNavigate = (direction) => {
        const newStep = currentStep + direction;
        if (newStep > 0 && newStep <= steps.length) {
            setCurrentStep(newStep);
            const newSteps = steps.map((step, index) => ({
                ...step,
                status: index + 1 < newStep ? 'complete' : index + 1 === newStep ? 'current' : 'upcoming'
            }));
            setSteps(newSteps);
        }
    };

    const updateData = useCallback((key, data) => {
        setFormData((prevState) => ({
          ...prevState,
          [key]: data,
        }));
      }, []);
    

      const saveAndExit = async () => {
        try {
            const { customerId, vehicleId: formVehicleId } = formData.vehicleDetails;
    
            if (!customerId) {
                throw new Error('Customer ID is undefined');
            }
    
            if (!formVehicleId) {
                throw new Error('Vehicle ID is undefined');
            }
    
          // Ensure repairTypeAbbreviation exists and is passed as an array
            const repairTypeAbbreviation = formData.partsAndServices?.repairTypeAbbreviation || '';
            const jobTypes = Array.isArray(repairTypeAbbreviation) ? repairTypeAbbreviation : [repairTypeAbbreviation];
    
            const customerRef = doc(db, "customers", formData.vehicleDetails.customerId);
            const customerSnap = await getDoc(customerRef);
            const customerData = customerSnap.exists() ? customerSnap.data() : {};    
            const customerName = `${customerData.firstName} ${customerData.lastName}`;
            const customerEmail = customerData.email;
            const customerAddress = customerData.address; 
    
            // Get vehicle GIN and increment visit number
            const vehicleRef = doc(db, "vehicles", formData.vehicleDetails.vehicleId);
            const vehicleSnap = await getDoc(vehicleRef);
            const visitCount = (vehicleSnap.data().serviceHistory || []).length + 1;
            const visitGIN = generateVisitGIN(vehicleSnap.data().GIN, visitCount);
            let jobCardNo;
        let isUnique = false;
        while (!isUnique) {
            jobCardNo = await generateJobCardNo(customerData.customerNumber, jobTypes);
            isUnique = !(await checkJobCardNoExists(jobCardNo)); // Check if the jobCardNo exists
        }
    
            const jobData = {
                jobCardNo,
                visitGIN,
                customerName,
                customerEmail,
                customerAddress,
                vehicleMake: formData.vehicleDetails.vehicleMake || '', 
                vehicleModel: formData.vehicleDetails.vehicleModel || '',
                vehicleYear: formData.vehicleDetails.year || '',
                VIN: formData.vehicleDetails.VIN || '',
                registrationNumber: formData.vehicleDetails.registrationNumber || '',
                vehicleId: vehicleId,
                ownerRequest: formData.customerOrder.ownerRequest,
                estimatedDuration: `${formData.customerOrder.estimatedDuration || ''} ${formData.customerOrder.durationUnit || ''}`,
                createdAt: Timestamp.fromDate(new Date()),
                status: "Open",
                entryMileage: formData.customerOrder.entryMileage || '',
                exitMileage: formData.customerOrder.exitMileage || '',
                procurementPricing: "Not Set",
                contractors: formData.customerOrder.contractors || [], 
                agentId: formData.vehicleDetails?.agentId || null,
                tracker: [],
                approvalStatus: 'No',
                approvalDate: null,
                dueDeliveryDate: formData.customerOrder.deliveryDate || '',
                repairTypes: formData.partsAndServices.repairTypes || {},
                partsRequired: formData.partsAndServices.partsRequired || [],
                servicesRequired: formData.partsAndServices.servicesRequired || [],
                technicianObservation: formData.diagReport.technicianObservation || '',
                workRequired: formData.diagReport.workRequired || '',
                inspectionChecklist: formData.inspectionChecklist || {},
                markedItemComments: formData.diagReport.markedItemComments || {}
            };
    
            // Remove unnecessary fields
            delete jobData.customerOrder?.durationUnit;
    
            // Save jobData to Firestore
            await addDoc(collection(db, "jobs"), jobData);
    
            toast.success(`Diagnostic Questionnaire with Job Card No ${jobCardNo} saved successfully!`);
            navigate('/vehicles/openjobs');
        } catch (error) {
            console.error("Error saving diagnostic questionnaire:", error);
            toast.error("Failed to save diagnostic questionnaire.");
        }
    };
    
      

    const getProgressBarWidth = () => {
        return `${(currentStep / steps.length) * 100}%`;
    };

    const updateChecklist = (updatedChecklist) => {
        updateData('inspectionChecklist', updatedChecklist);
    };
   

    return (
        <>
            <ToastContainer />
            <BreadCrumb pages={pages} />
            <div className="max-w-4xl mx-auto bg-white p-2 shadow-lg rounded-lg">
                <h2 className="text-xl font-semibold mb-4">Diagnostic Questionnaire for {formData.vehicleDetails.vehicleMake} {formData.vehicleDetails.vehicleModel} - {formData.vehicleDetails.registrationNumber}</h2>
                <p className='text-sm uppercase font-medium text-red-600'>DQ No: {formData?.jobCardNo}</p>

                <div className="mt-4">
                    <h4 className="sr-only">Progress</h4>
                    <div aria-hidden="true" className="mt-6">
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div style={{ width: getProgressBarWidth() }} className="h-2 rounded-full bg-indigo-600" />
                        </div>
                        <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                            {steps.map((step, index) => (
                                <div key={index} className={`text-center ${index + 1 === currentStep ? 'text-indigo-600' : (index + 1 < currentStep ? 'text-indigo-600' : '')}`}>
                                    {step.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {currentStep === 1 && <CustomerOrderForm formData={formData.customerOrder} updateData={(data) => updateData('customerOrder', data)} />}
                {currentStep === 2 && <InspectionChecklist checklistData={formData.inspectionChecklist} updateChecklist={updateChecklist} />}
                {currentStep === 3 && (
                    
                    <DQReport
                    formData={formData.diagReport}
                    checklistData={formData.inspectionChecklist}
                    updateData={(data) => updateData('diagReport', data)}
                  />
                            
                            )}

                {currentStep === 4 && <PartsAndServicesForm saveAndExit={saveAndExit} formData={formData.partsAndServices} updateData={(data) => updateData('partsAndServices', data)} />}

                <div className="flex justify-between mt-4">
                    {currentStep > 1 && <button onClick={() => handleNavigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded">Previous</button>}
                    {currentStep < steps.length && <button onClick={() => handleNavigate(1)} className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded">Next</button>}
                    {/* <button onClick={saveAndExit} className="bg-red-500 hover:bg-red-700 text-sm text-white font-bold py-2 px-4 rounded">Save and Exit</button> */}
                </div>
            </div>
        </>
    );
};

export default DQContainer;
